interface InitialState {
  email: string;
  isAuthenticated: boolean;
  account_id?: string;
  primary_account?: string;
  plaid_access_token?: string;
  current_balance?: number;
  available_balance?: number;
  plaid_item_id?: string;
  plaid_account_name?: string;
  plaid_account_official_name? : string;
  plaid_account_type?: string;
  terms_conditions_accepted?: boolean;
  set_up_completed?: boolean;
  profiled_created_at?: Date
}
const UpdateUserAction: string = "updateuser";

export interface UserAction {
  type: String;
  payload: {
    email: string;
    isAuthenticated: boolean;
    account_id?: string;
    primary_account?: string;
    plaid_access_token?: string;
    current_balance?: number;
    available_balance?: number;
    plaid_item_id?: string;
    plaid_account_name?: string;
    plaid_account_official_name? : string;
    plaid_account_type?: string;
    terms_conditions_accepted?: boolean;
    set_up_completed?: boolean;
    profiled_created_at?: Date
  };
}
export default InitialState;

export { UpdateUserAction };
