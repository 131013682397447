import { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

// import * as response from "./output_recurr_csv.json";
import { logError } from "../ErrorHandler/logErrorService";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { Edit, Link } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedProgressBars from "../CustomerProgress";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingLeft: "50px",
    paddingBottom: "20px"
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  secondCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  progressBar: {
    marginLeft: '15%',
    marginRight: '15%',
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
      marginLeft: "0",
    },
  },
  
}));


const SetUpBudgetExpense = () => {
const classes = useStyles();
const location = useLocation()
const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: "100%",
          marginTop: "10rem",
          minWidth: 275,
          boxShadow: 0,
          borderStyle: 0,
          borderRadius: 0,
        }}
      >
        <CardContent className={classes.centerCardContent}>
          <Typography sx={{ marginBottom: 2 }} component="div" variant="h2">
            Set your income & expense plan
          </Typography>
        </CardContent>
        <CardContent className={classes.progressBar}>
          <CustomizedProgressBars route={location.pathname} />
        </CardContent>
      </Card>
      <div className={classes.secondCardContent}>
        <Card
          sx={{ minWidth: 275, boxShadow: 0, borderStyle: 0, borderRadius: 0 }}
        >
          <CardContent
            sx={{
              // padding: "50px",
              // paddingTop: "20px",
              paddingBottom: "20px",
              alignItems: "left",
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: 2, marginTop: -1 }}>
              Set your income and expense plan to reflect what you expect to
              earn and spend this month.
            </Typography>
            <Typography sx={{ marginBottom: 4, marginTop: 1 }} variant="h3">
              What to know:
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <Link color="grey" style={{ marginRight: "10px" }} />
              Next to each category, you'll find some Aver insights to help
              guide you.
            </Typography>

            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <Edit color="grey" style={{ marginRight: "10px" }} />
              You can always edit your plan later, or even change it for next
              month.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          sx={{
            backgroundColor: "#007AFF",
            borderRadius: "100px",
            width: "300px",
            height: "62px",
            fontSize: "14px",
            fontFamily: "Poppins",
          }}
          size="large"
          variant="contained"
          onClick={() => {
            //  openModal(e);
            navigate(`/setbudget`);
          }}
        >
          Set my plan
        </Button>
      </div>
    </div>
  );
};

export default SetUpBudgetExpense;
