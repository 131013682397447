
import { createSlice } from "@reduxjs/toolkit";

import ProgressRoute, { UpdateRoute, UpdateRouteAction } from "../../types/redux/progressroute";

const initialState: ProgressRoute = {
    routesarray: ['/gettingstarted', '/accountsummary', '/reviewbudget', '/settingupbudget', '/setbudget', '/viewyourforecast', '/cashflow']
};


export const progressrouteSlice = createSlice({
  name: UpdateRoute,
  initialState: initialState,
  reducers: {
    updateRoute: (state, action: UpdateRouteAction) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.routesarray = action.payload.routesarray;
   
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateRoute } =
progressrouteSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default progressrouteSlice.reducer;
