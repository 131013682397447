import {
  Card,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from "../../redux-functionality";
import { logError } from "../ErrorHandler/logErrorService";
import initialUserDefinedData from "../../types/redux/userDefinedData";
import Spinner from "../Spinner";
import UserConfig, { IncomeExpense, Level1GroupDetails } from "../../types/redux/userConfig";
import { format } from "date-fns";
import { CustomIcons } from "../../icon";
import React from "react";
import { customColors } from "../../theme";
import { groupLeve1Details } from "../../redux-functionality/slices/groupLevel1Slice";
import { useNavigate } from "react-router-dom";
import { getUserConfig } from "../../backend/transaction";
import { updateUserConfig } from "../../redux-functionality/slices/userConfigSlice";



const BurnDown = () => {
  const useStyles = makeStyles((theme) => ({
    title: {
      paddingTop: "100px"
    },
    cateogryIcon: {
      width: '3%',    
      float: 'left',
      paddingRight: '3rem'
    },
    icon: {
      top: "-20px",
      position: "relative",
      float: "right",
      color: customColors.base.brightBlue,
      display: 'content'
    },
    arrow: {
      backgroundColor: customColors.base.brightBlue
    },
    
  }));

  const UnderBudgetProgressbar = styled(LinearProgress)(() => ({
    height: 10,
    width: "100%",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "lightgrey"
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#308fe8",
    },
  }));
  const OverBudgetProgressbar = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: "100%",
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: customColors.graphColor.spend
    },
  }));

  //FIX ME:
  const UnderBudgetProgressbarIncome = styled(LinearProgress)(() => ({
    height: 10,
    width: "100%",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "lightgrey"
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: customColors.graphColor.income,
    },
  }));
  const OverBudgetProgressbarIncome = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: "100%",
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: customColors.graphColor.income
    },
  }));

  const name: string = useSelector((state: RootState) => state.user.email);
  const userDefinedData: initialUserDefinedData = useSelector(
    (state: RootState) => state.userDefinedData
  );
  const classes = useStyles();
  const userConfig: UserConfig = useSelector(
    (state: RootState) => state.userConfig
  );
  const userDefinedDate: any = useSelector(
    (state: RootState) => state.userDefinedData
  );
  const [showSpinner, setshowSpinner] = useState(true);
  const [aggPaycheck, setaggPaycheck] = useState(0);
  const [month, setMonth] = useState("");

  const [aggDeposit, setaggDeposit] = useState(0);
  const [paycheck, setpaycheck] = useState<Array< Level1GroupDetails | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [otherDeposit, setotherDeposit] = useState<Array< IncomeExpense | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const [expense, setExpense] = useState<Array< Level1GroupDetails | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const [clientIncome, setClientIncome] = useState([{
    category: "",
    monthly_spent: 0.00,
    budget: 0.00,
    user_defined_budget: 0.00 
  }]);
  const Icon = props => {
    const { iconName, size, color } = props;
    let  object = CustomIcons.Default;
    if(iconName.length > 0) {
    const string = iconName;
    object = CustomIcons[string] === undefined ? CustomIcons.Default : CustomIcons[string];
    }
    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  };

  const getMainLevelDetails = async (mainCategory: Level1GroupDetails, flow) => {
    dispatch(
      groupLeve1Details({
        level_1_group: mainCategory.level_1_group || "",
        level_2_group: "",
        transaction_merchant_name: "",
        computed_monthly_budget: mainCategory.computed_monthly_budget || 0,
        computed_monthly_spend: mainCategory.computed_monthly_spend || 0,
        user_defined_values: mainCategory.user_defined_values || 
        [{  
          "user_defined_budget": 0,
          "timestamp": ""
        }],
        modified_by_user: mainCategory.modified_by_user,
        grouping_value: mainCategory.grouping_value || "",
        frequency: mainCategory.frequency || "",
        flow: ""
      })
    );
    console.log('mainCategory', mainCategory);
    navigate({
    pathname:  `/d2dx/grouplevel1detail`,
    search: `?flow=${flow}`
    });
  }
  useEffect(() => {
    async function fetchData() {
      try {
          const currentMonth = format(
        new Date(
          userDefinedDate.ao_year,
          userDefinedDate.ao_month - 1,
          userDefinedDate.ao_day
        ),
        "MMMM"
      );
      setMonth(currentMonth);
      
      const userconfig = await getUserConfig(name);
      const latestUserconfig = userconfig.userConfigResult[0];
      const currentUserconfig = userconfig.defaultUserConfigResult[0]; 
        const default_paycheck_deposits_array = currentUserconfig.userconfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome?.paycheck?.category_summary.level_1_group_details
        });

        const latest_paycheck_deposits_array = latestUserconfig.userconfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome?.paycheck?.category_summary.level_1_group_details
        });

        const default_expense_array = currentUserconfig.userconfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome.expenses?.category_summary.level_1_group_details;
        });
        
        const latest_expense_array = latestUserconfig.userconfig['income_expense'].map((eachIncome: IncomeExpense) => {
          return eachIncome.expenses?.category_summary.level_1_group_details;
        });
        
        const temp_latest_expense_array = latest_expense_array[0].map((eachlatestexpense: Level1GroupDetails) => {
          
          const matching_default_level1 = default_expense_array[0].filter((eachdefaultexpense: Level1GroupDetails) => {
            
            return (eachdefaultexpense.level_1_group === eachlatestexpense.level_1_group)
          });
          if(matching_default_level1.length !== 0) {
            
            if(matching_default_level1[0].modified_by_user && matching_default_level1[0].user_defined_values){
              return {
                "level_1_group": matching_default_level1[0].level_1_group,
                "computed_monthly_budget": matching_default_level1[0]?.user_defined_values[matching_default_level1[0]?.user_defined_values?.length -1]?.user_defined_budget,
                "computed_monthly_spend": eachlatestexpense?.computed_monthly_spend||matching_default_level1[0].computed_monthly_spend || 0,
                "month_summary_graph": eachlatestexpense["month_summary_graph"],
                "month_average_graph": eachlatestexpense["month_average_graph"],
                "computed_level_1_group_budget": matching_default_level1[0].computed_level_1_group_budget,
                "user_defined_values": matching_default_level1[0].user_defined_values,
                "modified_by_user": matching_default_level1[0].modified_by_user,
                "level_1_transactions_breakdown": eachlatestexpense.level_1_transactions_breakdown,
                "level_2_group_details": eachlatestexpense.level_2_group_details
              } 
            } else {
              return {
                "level_1_group": matching_default_level1[0].level_1_group,
                "computed_monthly_budget":matching_default_level1[0].computed_monthly_budget,
                "computed_monthly_spend": eachlatestexpense.computed_monthly_spend||matching_default_level1[0].computed_monthly_spend || 0,
                "month_summary_graph": eachlatestexpense["month_summary_graph"],
                "month_average_graph": eachlatestexpense["month_average_graph"],
                "computed_level_1_group_budget": matching_default_level1[0].computed_level_1_group_budget,
                "user_defined_values": matching_default_level1[0].user_defined_values,
                "modified_by_user": matching_default_level1[0].modified_by_user,
                "level_1_transactions_breakdown": eachlatestexpense.level_1_transactions_breakdown,
                "level_2_group_details": eachlatestexpense.level_2_group_details
                }
            }
          } else {
            
            return eachlatestexpense
          }
        });
        const temp_latest_income_array = latest_paycheck_deposits_array[0].map((eachlatestincome: Level1GroupDetails) => {
          const matching_default_level1 = default_paycheck_deposits_array[0].filter((eachdefaultincome: Level1GroupDetails) => {
            return (eachdefaultincome.level_1_group === (eachlatestincome.level_1_group))
          });
          if(matching_default_level1.length !== 0) {
            if(matching_default_level1[0].modified_by_user && matching_default_level1[0].user_defined_values){
              return {
                "level_1_group": matching_default_level1[0].level_1_group,
                "computed_monthly_budget": matching_default_level1[0].modified_by_user ? matching_default_level1[0]?.user_defined_values[matching_default_level1[0]?.user_defined_values?.length -1]?.user_defined_budget : matching_default_level1[0].computed_monthly_budget,
                "computed_monthly_spend": eachlatestincome?.computed_monthly_spend||matching_default_level1[0].computed_monthly_spend || 0,
                "month_summary_graph": eachlatestincome["month_summary_graph"],
                "month_average_graph": eachlatestincome["month_average_graph"],
                "computed_level_1_group_budget": matching_default_level1[0].computed_level_1_group_budget,
                "user_defined_values": matching_default_level1[0].user_defined_values,
                "modified_by_user": matching_default_level1[0].modified_by_user,
                "level_1_transactions_breakdown": eachlatestincome.level_1_transactions_breakdown,
                "level_2_group_details": eachlatestincome.level_2_group_details
              } 
            } else {
              
              return {
                "level_1_group": matching_default_level1[0].level_1_group,
                "computed_monthly_budget":matching_default_level1[0].computed_monthly_budget,
                "computed_monthly_spend": eachlatestincome.computed_monthly_spend||matching_default_level1[0].computed_monthly_spend || 0,
                "month_summary_graph": eachlatestincome["month_summary_graph"],
                "month_average_graph": eachlatestincome["month_average_graph"],
                "computed_level_1_group_budget": matching_default_level1[0].computed_level_1_group_budget,
                "user_defined_values": matching_default_level1[0].user_defined_values,
                "modified_by_user": matching_default_level1[0].modified_by_user,
                "level_1_transactions_breakdown": eachlatestincome.level_1_transactions_breakdown,
                "level_2_group_details": eachlatestincome.level_2_group_details
                }
            }
          } else {
          
            return eachlatestincome;
          }
        });

        
        const merged_income_expense = [{
          timestamp: latestUserconfig.userconfig["income_expense"].timestamp,
          paycheck: {
            category_summary: {
              "6_month_summary_graph": latestUserconfig.userconfig['income_expense'][0].paycheck.category_summary["6_month_summary_graph"],
              "6_month_average_graph": latestUserconfig.userconfig['income_expense'][0].paycheck.category_summary["6_month_average_graph"],
              level_1_group_details: temp_latest_income_array
            }
          },
          expenses: {
            category_summary: {
              "6_month_summary_graph": latestUserconfig.userconfig['income_expense'][0].expenses.category_summary["6_month_summary_graph"],
              "6_month_average_graph": latestUserconfig.userconfig['income_expense'][0].expenses.category_summary["6_month_average_graph"],
              level_1_group_details: temp_latest_expense_array
            }
          }
        }]
        const merged_userconfig = {
          "12_month_transaction_history": latestUserconfig.userconfig["12_month_transaction_history"],
          cashflow: latestUserconfig.userconfig["cashflow"],
          income_expense: merged_income_expense,
          "other_transactions": latestUserconfig.userconfig["other_transactions"]
        }
        dispatch(updateUserConfig(merged_userconfig));
        const sum_of_paycheck = temp_latest_income_array?.reduce((acc, obj: any) => {
         let sum = obj.modified_by_user ? obj.user_defined_values[obj.user_defined_values.length -1].user_defined_budget : obj?.computed_monthly_budget
          return acc + sum;
        }, 0)
        setaggPaycheck(sum_of_paycheck || 0);
        // setaggDeposit(parseFloat(sum_of_other_deposits.toFixed(2)));
        setshowSpinner(false);
        setExpense(temp_latest_expense_array || []);
        setpaycheck(temp_latest_income_array|| []);
        setotherDeposit([]);
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, [
  ]);

  return (
    <div>
      {showSpinner ? (
        <Spinner />
      ) : (
        <div>
          <Typography className={classes.title} variant="h2">
            {month} plan
          </Typography>
          <Typography
            sx={{ marginTop: 2, marginBottom: 2 }}
            variant="h3"
            component="div"
          >
            Expense
          </Typography>
          {expense.map((mainCategory: Level1GroupDetails, index) => {
            return (
              <div key={index}>
                <Card
                  sx={{
                    minWidth: 275,
                    marginBottom: 1.25,
                    boxShadow: 0,
                    borderRadius: 0,
                    border: 1,
                    borderColor: "#DDD8D1",
                  }}
                  onClick={() => {
                    getMainLevelDetails(mainCategory, "expense");
                  }}
                >
                  <CardContent>
                    <section className={styles.container}>
                      <div className={classes.cateogryIcon}>
                        <Typography variant="button">
                          <Icon
                            iconName={mainCategory.level_1_group}
                            color={customColors.base.brightBlue}
                          />
                        </Typography>
                      </div>
                      <div className={styles.typography}>
                        <Typography
                          sx={{ paddingTop: 0.5, fontWeight: 500 }}
                          variant="h4"
                        >
                          {mainCategory.level_1_group}
                        </Typography>
                      </div>
                    </section>
                    <div className={classes.icon}>
                      {mainCategory?.computed_monthly_budget && (
                        <>
                          <Typography
                            sx={{ paddingRight: 3 }}
                            variant="h4"
                            color={
                              mainCategory.computed_monthly_budget >
                              (mainCategory?.computed_monthly_spend || 0)
                                ? "text.secondary"
                                : customColors.secondary.orange
                            }
                          >
                            {Math.abs(
                              (mainCategory?.computed_monthly_spend || 0) -
                                mainCategory.computed_monthly_budget
                            ).toFixed(2)}{" "}
                            {mainCategory.computed_monthly_budget >
                            (mainCategory.computed_monthly_spend || 0)
                              ? "Left"
                              : "Over"}
                          </Typography>
                          <ArrowForwardIosIcon
                            style={{ marginTop: "3px" }}
                            className={classes.icon}
                          />
                        </>
                      )}
                    </div>
                    {mainCategory?.computed_monthly_budget && (
                      <>
                        <div>
                          {(mainCategory.computed_monthly_spend || 0) >
                          mainCategory.computed_monthly_budget ? (
                            <OverBudgetProgressbar
                              style={{
                                backgroundColor: customColors.secondary.orange,
                              }}
                              variant="determinate"
                              value={
                                (Math.abs(
                                  mainCategory.computed_monthly_budget
                                ) /
                                  (mainCategory.computed_monthly_spend || 0)) *
                                100
                              }
                            />
                          ) : (
                            <>
                              
                              {mainCategory.computed_monthly_spend === 0 ? (
                                <>
                                <UnderBudgetProgressbar
                                  style={{ color: "lightgrey" }}
                                  variant="determinate"
                                  value={0}
                                />
                                </>
                              ) : (
                                <>
                                
                                <UnderBudgetProgressbar
                                  style={{ color: "lightgrey" }}
                                  variant="determinate"
                                  value={
                                    (Math.abs(
                                      (mainCategory.computed_monthly_spend ||
                                        0) -
                                        mainCategory.computed_monthly_budget
                                    ) /
                                      mainCategory.computed_monthly_budget) *
                                    100
                                  }
                                />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>
            );
          })}
          <Typography
            sx={{ marginTop: 2, marginBottom: 2 }}
            variant="h3"
            component="div"
          >
            Income
          </Typography>
          {paycheck.map((eachPaycheck: Level1GroupDetails, index) => {
            return (
              <div key={index}>
                <Card
                  sx={{
                    minWidth: 275,
                    marginBottom: 1.25,
                    boxShadow: 0,
                    borderRadius: 0,
                    border: 1,
                    borderColor: "#DDD8D1",
                  }}
                  onClick={() => {
                    getMainLevelDetails(eachPaycheck, "paycheck");
                  }}
                >
                  <CardContent>
                    <section className={styles.container}>
                      <div className={classes.cateogryIcon}>
                        <Typography variant="button">
                          <Icon
                            iconName={eachPaycheck.level_1_group}
                            color={customColors.graphColor.income}
                          />
                        </Typography>
                      </div>
                      <div className={styles.typography}>
                        <Typography
                          sx={{ paddingTop: 0.5, fontWeight: 500 }}
                          variant="h4"
                        >
                          {eachPaycheck.level_1_group}
                        </Typography>
                      </div>
                    </section>
                    <div className={classes.icon}>
                      {(eachPaycheck.computed_monthly_budget) && <>
                        <Typography
                        sx={{ paddingRight: 3 }}
                        variant="h4"
                        color={(eachPaycheck.computed_monthly_budget) >
                          (eachPaycheck.computed_monthly_spend || 0)?"text.secondary" : customColors.secondary.orange }
                      >
                        {Math.abs(
                          (Math.abs(eachPaycheck.computed_monthly_spend || 0)) -
                          (Math.abs(eachPaycheck.computed_monthly_budget))
                        ).toFixed(2)}{" "}
                        {Math.abs(eachPaycheck.computed_monthly_budget) >
                        Math.abs(eachPaycheck.computed_monthly_spend || 0)
                          ? "Left"
                          : "Over"}
                      </Typography>
                      <ArrowForwardIosIcon style={{marginTop: "3px"}}className={classes.icon} />
                      </>}
                    </div>
                    {(eachPaycheck.computed_monthly_budget) && <>
                      <div>
                      {Math.abs(eachPaycheck.computed_monthly_spend || 0) >
                      Math.abs(eachPaycheck.computed_monthly_budget) ? (
                        <OverBudgetProgressbarIncome                        
                        style={{ backgroundColor: customColors.secondary.orange }}
                        variant="determinate"
                        value={
                          (Math.abs(
                            Math.abs(eachPaycheck.computed_monthly_budget)
                          )/Math.abs(eachPaycheck.computed_monthly_spend || 0 )) *100
                        }/>
                      ) : (
                        <>
                        <UnderBudgetProgressbarIncome
                          variant="determinate"
                          value={
                            (Math.abs(
                              Math.abs(eachPaycheck.computed_monthly_spend || 0)
                            )/Math.abs(eachPaycheck.computed_monthly_budget)) *100
                          }
                        />
                        </>
                      )}
                    </div>
                    </>}

                  </CardContent>
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BurnDown;
