import { createTheme } from "@material-ui/core/styles";

// const muiPalette: PaletteOptions = {
//   // primary: {
//   //   main: "#",
//   //   dark: "#",
//   // },
// };

const defaultTheme = createTheme();
const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 14,
    h3: {
      fontFamily: "Poppins",
      fontSize: 21,
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Poppins",
      fontSize: 14,
      textTransform: "none",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: 14,
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 100,
    },
  },

  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Poppins",
        fontSize: 22.5,
        fontWeight: 700,
      },
      h5: {
        "MuiTypography-h5": {
          fontSize: 22.5,
          fontWeight: 700,
        },
      },
      paragraph: {
        fontSize: 14,
      },
      h6: {
        "MuiTypography-h6": {
          fontSize: 14,
          fontFamily: "Poppins",
          fontWeight: 500,
        },
      },
      h4: {
        "MuiTypography-h4": {
          fontFamily: "Poppins",
          fontSize: 16,
          fontWeight: 500
        },
      },
      body1: {
        fontSize: 14,
        fontFamily: "Poppins",
      },
      body2: {
        "MuiTypography-body2": {
          fontSize: 14,
          fontFamily: "Poppins",
        },
      },
    },
    // MuiCard: {
    //   'root': {
    //     color: 'red',
    //     marginBottom: '12rem'
    //   }
    // }
  },
});

export const customColors = {
  base: {
    yellow: "#F1B92C",
    brightBlue: "#007AFF",
    black: "#00000",
    white: "#FFFFF",
  },
  secondary: {
    navy: "#104F93",
    orange: "#FF7D2E",
    redOrange: "#F14D34",
    blush: "#F98F7F",
    red: "#B40000",
    green: "#184914",
    beige: "#F9F7F3"
  },
  graphColor: {
    spend: "#007AFF",
    income: "#8ACD8F",
    forcast: "#F1B92C"
  }
};

export default theme;
