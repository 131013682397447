interface initialUserDefinedData {
  ao_day: 0;
  ao_month: 0;
  ao_year: 0;
  as_of_amount: 0;
  sd_day: 0;
  sd_month: 0;
  sd_year: 0;
  starting_amount: 0;
}
const UserSetDataAction: string = "userSetDataAction";

export interface SetDateAction {
  type: String;
  payload: {
    ao_day: number | any;
    ao_month: number | any;
    ao_year: number | any;
    as_of_amount: number | any;
    sd_day: number | any;
    sd_month: number | any;
    sd_year: number | any;
    starting_amount: number | any;
  };
}
export default initialUserDefinedData;

export { UserSetDataAction };
