import InitialState, { UpdateUserAction, UserAction } from "../../types/redux/user";
import { createSlice } from "@reduxjs/toolkit";

const initialState: InitialState = {
  email: "",
  isAuthenticated: false,
};


export const userSlice = createSlice({
  name: UpdateUserAction,
  initialState: initialState,
  reducers: {
    setUserEmailAndAuth: (state, action: UserAction) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.email = action.payload.email;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.account_id = action.payload.account_id;
      state.primary_account = action.payload.primary_account;
      state.plaid_access_token=  action.payload.plaid_access_token;
      state.current_balance=  action.payload.current_balance;
      state.available_balance=  action.payload.available_balance;
      state.plaid_item_id=  action.payload.plaid_item_id;
      state.plaid_account_name=  action.payload.plaid_account_name;
      state.plaid_account_official_name = action.payload.plaid_account_official_name;
      state.plaid_account_type=  action.payload.plaid_account_type;
      state.terms_conditions_accepted=  action.payload.terms_conditions_accepted;
      state.set_up_completed=  action.payload.set_up_completed;
      state.profiled_created_at=  action.payload.profiled_created_at
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUserEmailAndAuth } =
  userSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default userSlice.reducer;
