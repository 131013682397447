import React, { useEffect, useContext } from "react";
import { usePlaidLink } from "react-plaid-link";
import Button from "plaid-threads/Button";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";

// import styles from "plaid-threads/Button";
import Context from "../../Context";

import { API_URL } from "../../env";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux-functionality";
import InitialState from "../../types/redux/user";
import { setUserEmailAndAuth } from "../../redux-functionality/slices/userSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#007AFF",
    borderRadius: "100px",
    width: "300px",
    height: "62px",
    fontSize: "14px",
    fontFamily: "Poppins",
    "& span": {
      "& i": {
        display: "none",
      },
    },
  },
  link: {
    backgroundColor: "white",
    width: "fit-content",
    padding: 0,
    margin: "0 auto",
    height: "fit-content",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  linkText: {
    color: "#007AFF",
    textDecoration: "underline",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
  buttonText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "58%",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
}));

const PlaidLink = (props) => {
  const name: InitialState = useSelector((state: RootState) => state.user);
  const { linkToken, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  // const [displaylink, setdisplaylink] = useState(false);
  const onSuccess = React.useCallback(
    (public_token: string) => {
      // send public_token to server
      const setToken = async () => {
        const response = await fetch(`${API_URL}/api/set_access_token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: `public_token=${public_token}&userprofile=${JSON.stringify(name)}`,
        });
        if (!response.ok) {
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
          
          navigate(`/error`);
        }
        const data = await response.json() as any;
        dispatchRedux(setUserEmailAndAuth({
          email: data?.result_query?.email,
          isAuthenticated: true,
          account_id: data?.result_query?.emaccount_idail,
          plaid_account_name: data?.result_query?.plaid_account_name,
          plaid_account_official_name: data?.result_query?.plaid_account_official_name,
          plaid_account_type: data?.result_query?.plaid_account_type
        }));
        dispatch({
          type: "SET_STATE",
          state: {
            itemId: data.item_id,
            accessToken: data.access_token,
            isItemAccess: true,
          },
        });
        
      };
      setToken();
      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      navigate('/accountsummary');
      // if(props.displayLink === "false"){
      //   console.log('IM NAVIGATE props.displayLink ===');
      // navigate('/accountsummary');
      // } else {
      //   console.log('IM NAVIGATE');
      // window.history.pushState("", "", "/accountsummary");
      // }
    },
    [dispatch]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    const {displayLink} = props;
    console.log('displayLink', displayLink, typeof(displayLink));
    // displayLink === "false" ? setdisplaylink(false):setdisplaylink(true);
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth, props]);

  return (
    <>
    {props.displayLink === "false" ?    
      <Button animatedCaret={false} className={classes.button} type="button" large onClick={() => open()} disabled={!ready}>
    <span className={classes.buttonText}>Connect your account</span>
   </Button>: <Button animatedCaret={false} className={classes.link} type="button" large onClick={() => open()} disabled={!ready}>
    <span className={classes.linkText}>Add another account</span> </Button>}
   </>

  );
};

PlaidLink.displayName = "Link";

export default PlaidLink;
