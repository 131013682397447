import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Spinner from "../Spinner";

import { logError } from "../ErrorHandler/logErrorService";
import { Button, Card, CardContent, Divider, Grid, Link, Typography } from "@mui/material";
import { customColors } from "../../theme";
import React from "react";
import { CustomIcons } from "../../icon";
import { IncomeExpense, Income_ExpenseDetails } from "../../types/redux/userConfig";

const security = require('../../Assets/security.svg').default

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  root: {
    minWidth: 200,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  progressBar: {
    marginLeft: '15%',
    marginRight: '15%',
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
      marginLeft: "0",
    },
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cateogryIcon: {
    width: "10%",
    // float: "left",
    marginTop: "2%",
    [theme.breakpoints.down("md")]: {
      width: "0%",
      float: "left",
      display: "flex",
      justifyContent: "center",
    },
  },
  icon: {
    top: "-18px",
    position: "relative",
    float: "right",
  },
  backgroundColor: {
    backgroundColor: customColors.secondary.beige,
  },
  paddingTop: {
    paddingTop: "10rem",
  },
  gridClass: {
    marginTop: "4rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "2rem"
    }
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  buttonStyle: {
    "MuiButton-root": {
      backgroundColor: "red",
    },
  },
  link: {
    display: 'flex',
    justifyContent: "center",
    marginTop: "1rem"
  }
}));


const AccountDetails = () => {

  let navigate = useNavigate();
  const classes = useStyles();

  const Icon = props => {
    const { iconName, size, color } = props;
    let  object = CustomIcons.Default;
    if(iconName.length > 0) {
    const string = iconName;
    object = CustomIcons[string] === undefined ? CustomIcons.Default : CustomIcons[string];
    }
    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  };
  
  const [expense, setExpense] = useState<Array< IncomeExpense | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const [showSpinner, setshowSpinner] = useState(true);
  

  const getMainLevelDetails = async (mainCat: Income_ExpenseDetails) => {
    navigate({
      pathname: `/grouplevel1Details`,
    });
  };
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      try {
        setshowSpinner(false);
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <Card
            sx={{
              width: "100%",
              marginTop: "60px",
              boxShadow: 0,
              borderRadius: 0,
            }}
          >
            <CardContent className={classes.centerCardContent}>
              <div className={classes.cateogryIcon}>
                <Typography variant="button">
                  <img
                    src={security}
                    alt="success"
                    height={100}
                    width={100}
                    color="green"
                  />
                </Typography>
              </div>
            </CardContent>
            <CardContent className={classes.centerCardContent}>
              <Typography component="div" variant="h3">
                Connected accounts
              </Typography>
            </CardContent>
          </Card>
          <div className={classes.gridClass}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              justifyItems="center"
            >
              <Grid item xs={12} sm={6} md={4}>
              <Typography
                  variant="h3"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Checking Accounts
                </Typography>
                <Card sx={{ minWidth: 275 }}></Card>
                {/* {expense.map((eachExpense, index) => {
                  return ( */}
                <div>
                  <Card
                    sx={{
                      minWidth: 275,
                      marginBottom: 1.25,
                      boxShadow: 0,
                      borderRadius: 0,
                      border: 1,
                      borderColor: "#DDD8D1",
                    }}
                  >
                    <CardContent>
                      {/* <section className={styles.container}>
                            <div className={styles.typography}> */}
                      <Typography variant="h4" sx={{ fontWeight: "600", width: '60%' }}
                        color="black">
                        Chase Checking ****4578
                      </Typography>
                      {/* </div>
                          </section> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "600" }}
                        color="black"
                        className={classes.icon}
                      >
                        $ 000.00
                      </Typography>
                      <Divider sx={{ marginTop: 2.5 }}/>
                      <Typography variant="button">
                        <Link sx={{marginTop: 1}}className={classes.link} href="#">View Transactions</Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                {/* );
                })} */}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Savings Accounts
                </Typography>
                <Card sx={{ minWidth: 275 }}></Card>
                {/* {expense.map((eachExpense, index) => {
                  return ( */}
                <div>
                  <Card
                    sx={{
                      minWidth: 275,
                      marginBottom: 1.25,
                      boxShadow: 0,
                      borderRadius: 0,
                      border: 1,
                      borderColor: "#DDD8D1",
                    }}
                  >
                    <CardContent>
                      {/* <section className={styles.container}>
                            <div className={styles.typography}> */}
                      <Typography variant="h4" sx={{ fontWeight: "600", width: '60%' }}
                        color="black">
                        Chase Savings ****9827
                      </Typography>
                      {/* </div>
                          </section> */}
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "600" }}
                        color="black"
                        className={classes.icon}
                      >
                        $ 000.00
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
                {/* );
                })} */}
              </Grid>
            </Grid>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              sx={{
                backgroundColor: "#007AFF",
                borderRadius: "100px",
                width: "300px",
                height: "62px",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
              size="large"
              variant="contained"
              onClick={(e) => {
                // openModal(e);
                navigate(`/setbudget`);
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AccountDetails;
