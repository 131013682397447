import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../Account";

const Status = () => {
    const linkStyle = {
        backgroundColor: "white",
        color: "#3498db",
        padding: "0rem",
        width: "auto",
        fontSize: "18px",
        right: 0,
        position: "absolute",
        margin: "2.5rem"
    } as React.CSSProperties;
    let navigate = useNavigate();

    const [status, setStatus] = useState(false);
    const { getSession, logout } = useContext(AccountContext);
    useEffect(() => {
        getSession().then((session: any) => {
            console.log("Session ", session);
            console.log("Status ", status);
            setStatus(true);
        }).catch((err: Error) => {
            navigate(`/`)
            console.log('error: ',err);
        });
    })

    return (
        <div>{status ? <button style={linkStyle} className="btn btn-link" onClick={logout}>Logout</button> : ""}</div>
    )
}
export default Status;