import { useEffect, useContext, useCallback } from "react";

import Header from "./Components/Headers";
import Context from "./Context";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./form.css";

import { API_URL } from "./env";
import Register from "./Components/Register";
import Login from "./Components/Login";
import { Account } from "./Components/Account";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Forgot from "./Components/Forgot";
import TransactionDetails from "./Components/TransactionsDetails";
import Cashflow from "./Components/Cashflow";
import Welcome from "./Components/Welcome";
import WhatToExpect from "./Components/WhatToExpect";
import ReviewYourIncome from "./Components/ReviewYourIncome";
import SelectUser from "./Components/SelectUser";
import ErrorHandler from "./Components/ErrorHandler";
import ReviewBudget from "./Components/ReviewBudget";
import GroupLevel1Details from "./Components/GroupLevel1Details";
import IncomeDetails from "./Components/IncomeDetails";
import BurnDown from "./Components/Burndown";
import SetBudget from "./Components/SetBudget";
import Editbudget from "./Components/Editbudget";
import AppHeader from "./Components/AppHeader";
import theme from "./theme";
import styles from "./App.module.scss";
import AppFooter from "./Components/AppFooter";
import Help from "./Components/Help";
import GettingStarted from "./Components/GettingStarted";
import SetUpBudgetExpense from "./Components/SettingUpBudget";
import ViewYourForecast from "./Components/ViewYourForecast";
import AllSet from "./Components/AllSet";
import AccountSummary from "./Components/AccountSummary";
import { CssBaseline } from "@mui/material";
import ScrollToTop from "./Wrapper/ScrollToTop";
import GroupLevel1DetailsD2DX from "./Components/GroupLevel1Details_D2DX";
import AccountDetails from "./Components/AccountDetails";
import Agreement from "./Components/Agreement";
import SelectAccount from "./Components/SelectAccount";

const App = () => {
  // const { linkSuccess, isItemAccess, dispatch } = useContext(Context);
  const { dispatch } = useContext(Context);
  const getInfo = useCallback(async () => {
    const response = await fetch(`${API_URL}/api/info`, { method: "POST" });
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { backend: false } });
      return { paymentInitiation: false };
    }
    const data = await response.json();
    const paymentInitiation: boolean =
      data.products.includes("payment_initiation");
    dispatch({
      type: "SET_STATE",
      state: {
        products: data.products,
      },
    });
    return { paymentInitiation };
  }, [dispatch]);

  const generateToken = useCallback(
    async (paymentInitiation) => {
      const path = paymentInitiation
        ? `${API_URL}/api/create_link_token_for_payment`
        : `${API_URL}/api/create_link_token`;
      const response = await fetch(path, {
        method: "POST",
      });
      if (!response.ok) {
        dispatch({ type: "SET_STATE", state: { linkToken: null } });
        return;
      }
      const data = await response.json();
      if (data) {
        if (data.error != null) {
          dispatch({
            type: "SET_STATE",
            state: {
              linkToken: null,
              linkTokenError: data.error,
            },
          });
          return;
        }
        dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
      }
      localStorage.setItem("link_token", data.link_token); //to use later for Oauth
    },
    [dispatch]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation } = await getInfo(); // used to determine which path to take when generating token
      // do not generate a new token for OAuth redirect; instead
      // setLinkToken from localStorage
      if (window.location.href.includes("?oauth_state_id=")) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: localStorage.getItem("link_token"),
          },
        });
        return;
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, getInfo]);

  return (
    <BrowserRouter>
      <Account>
        <div id="mainDiv" className={styles.mainDiv}>
          <StyledEngineProvider>
            <ThemeProvider theme={theme}>
              <AppHeader></AppHeader>
              <CssBaseline />
              {/* <Status></Status> */}
              <ScrollToTop>
                <Routes>
                  {/* <div className={styles.App}>
            <div className={styles.container}> */}

                  <Route path="/login" element={<Login></Login>} />
                  <Route path="/agreement" element={<Agreement />} />

                  <Route path="register" element={<Register></Register>} />
                  <Route path="forgot" element={<Forgot></Forgot>} />
                  {/* <Register></Register>
              <Login></Login>
              <Profile></Profile> */}
                  <Route path="dashboard" element={<Header />} />
                  <Route
                    path="/transactiondetails"
                    element={<TransactionDetails />}
                  />
                <Route path="welcome" element={<Welcome />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/cashflow" element={<Cashflow />} />
                  <Route path="/what-to-expect" element={<WhatToExpect />} />
                  <Route path="/reviewexpense" element={<ReviewYourIncome />} />
                  <Route path="/reviewbudget" element={<ReviewBudget />} />
                  <Route path="/selectuser" element={<SelectUser />} />
                  <Route path="/gettingstarted" element={<GettingStarted />} />
                  <Route
                    path="/settingupbudget"
                    element={<SetUpBudgetExpense />}
                  />
                  <Route
                    path="/viewyourforecast"
                    element={<ViewYourForecast />}
                  />
                  <Route path="/allset" element={<AllSet />} />
                  <Route path="/accountsummary" element={<AccountSummary />} />
                  <Route path="/selectaccount" element={<SelectAccount />} />
                  <Route
                    path="/grouplevel1Details"
                    element={<GroupLevel1Details />}
                  />
                  <Route path="/incomedetails" element={<IncomeDetails />} />
                  <Route path="/error" element={<ErrorHandler />} />
                  <Route path="/burndown" element={<BurnDown />} />
                  <Route path="/setbudget" element={<SetBudget />} />
                  <Route path="/editbudget" element={<Editbudget />} />
                  <Route path="/d2dx/grouplevel1detail" element={<GroupLevel1DetailsD2DX />} />
                  <Route path="/d2dx/accountdetails" element={<AccountDetails />} />
                  {/* {linkSuccess && isItemAccess && (
                <>
                </>
              )}
            </div>
          </div> */}
                </Routes>
              </ScrollToTop>
              <AppFooter></AppFooter>
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
      </Account>
    </BrowserRouter>
  );
};

export default App;
