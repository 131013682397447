import { Button, List, ListItem } from "@mui/material";
import { useEffect } from "react";
import styles from "./index.module.scss";

const WhatToExpect = () => {
  useEffect(() => {}, []);

  return (
    <div className={styles.grid}>
      <div>
        <h3 className={styles.title}>
          What to expect reviewing your cashflow:
        </h3>
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          <ListItem>Confirm your recurring income</ListItem>
          <ListItem>Confirm your recurring expenses</ListItem>
          <ListItem>Review your remaining cashflow</ListItem>
          <ListItem>Set your future cashflow</ListItem>
        </List>
        <Button variant="contained" size="large">
          Continue
        </Button>
      </div>
    </div>
  );
};

export default WhatToExpect;
