import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  getTransactionsDetails,
  getTransactionsDetailsHistory,
} from "../../backend/transaction";

import { RootState } from "../../redux-functionality";

import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import styles from "./index.module.scss";
import { Button, List, ListItem, Typography } from "@mui/material";
// import styles from "./index.module.scss";

const TransactionDetails = () => {
  interface GraphData {
    monthly_avg: number;
    month_year: string;
  }
  const [, setgraphData] = useState<Array<GraphData>>([]);
  const [data] = useState([]);
  const [showSpinner, setshowSpinner] = useState(true);
  const [, setLegend] = useState<string>('Monthly Average');
  const [details, setDetails] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const reqObject = useSelector(
    (state: RootState) => state.trnasactionDetailsReq
  );

  const continueButton = () => {
    // setStartDate(event);
    navigate(`/reviewbudget`);
  };

  useEffect(() => {
    async function fetchData() {
      const queryParams = new URLSearchParams(location.search);
      const paramValue = queryParams.get("q");
      console.log(reqObject);
      const response = (await getTransactionsDetails(
        reqObject.accountId,
        reqObject.lvl1_grp,
        reqObject.lvl2_grp,
        reqObject.merchant_name,
        reqObject.grouping_value
      )) as any;

      if(paramValue === 'income') {
        response.map(eachIncome => eachIncome.transaction_amount *=-1)
      }
      const responseGraphData: Array<{
        monthly_avg: string;
        month_year: string;
      }> = await getTransactionsDetailsHistory(
        reqObject.accountId,
        reqObject.lvl1_grp,
        reqObject.lvl2_grp,
        reqObject.merchant_name,
        reqObject.grouping_value
      );
      const settingGraphData: Array<GraphData> = responseGraphData.map(
        (eachData) => {
          return {
            monthly_avg: Number(eachData.monthly_avg),
            month_year: eachData.month_year,
          };
        }
      );
      if(paramValue === 'income') {
        settingGraphData.map(eachIncome => eachIncome.monthly_avg *=-1)
      }
      console.log("GRAPH DATA", settingGraphData);
      setgraphData(settingGraphData);
      setLegend(`Monthly Average for ${reqObject.merchant_name}`);
      setDetails(response);
      setshowSpinner(false);
      console.log("RESPONSE", data);
    }
    fetchData();
  }, [data, reqObject]);

  return (
    <div id="mainDiv" className={styles.grid}>
      <h3 className={styles.title}>Showing details for {reqObject.merchant_name}</h3>

      {showSpinner ? (
        <Spinner></Spinner>
      ) : (
        <span>
          {/* <div style={{ marginLeft: "-5rem" }}>
          <h6 style={{textAlign: 'center'}}>Monthly Average for {reqObject.merchant_name}</h6>
            <BarChart
              width={1500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month_year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name={legend} dataKey="monthly_avg" fill="#8884d8" />
            </BarChart>
          </div> */}
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {details.map((eachTransaction: any, index) => {
              return (
                <ListItem key={index} style={{ display: "block" }}>
                  <Typography component="div" variant="h4">
                    {eachTransaction?.transaction_merchant_name}
                  </Typography>

                  <Typography component="div" variant="h5">
                    {eachTransaction?.transaction_date}
                  </Typography>

                  <Typography component="div" variant="h5">
                    {eachTransaction?.transaction_amount < 0 ? eachTransaction?.transaction_amount *-1 : eachTransaction?.transaction_amount}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
          <div className={styles.paddingTop}>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                continueButton();
              }}
            >
              Go Back to confirm expense
            </Button>
          </div>
        </span>
      )}

      {/* <MUIDataTable title={"Transaction Details"} data={data} columns={columns} />
      <Button onClick={() => {navigateToCashflow()}} variant="text">Calling Python function</Button> */}
    </div>
  );
};

export default TransactionDetails;
