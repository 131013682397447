import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../redux-functionality";
import { makeStyles } from '@material-ui/core/styles';

import initialUserDefinedData from "../../types/redux/userDefinedData";
import { customColors } from "../../theme";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CustomIcons } from "../../icon";
import React from "react";
import styles from "./index.module.scss";
import UserConfig, { CategoryDetails, IncomeExpense, Level1GroupDetails } from "../../types/redux/userConfig";
import Editbudget from "../../types/redux/editbudget";
import { updateUserConfig } from "../../redux-functionality/slices/userConfigSlice";
import InitialState from "../../types/redux/user";
import { updateUserConfigdb } from "../../backend/transaction";
import { logError } from "../ErrorHandler/logErrorService";

const EditBudget = () => {
    const useStyles = makeStyles((theme) => ({
    centerCardContent: {
      display: "flex",
      justifyContent: "center",
    },
    secondCardContent: {
      backgroundColor: customColors.secondary.beige,
    },
    floatContainer: {
      // marginBottom: '10px',
      paddingBottom: "20px",
    },
    floatChild1: {
      // width: '50%',
      float: "left",
      // padding: '10px',
    },
    floatChild2: {
      // width: '50%',
      float: "right",
    },
    buttonCard: {
      width: "80%",
      textAlign: "left",
      marginLeft: "18%",
    },
    buttonContainer: {
      padding: "24px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center"
    }
  }));

  const dispatch = useDispatch();
  const groupLevel1: any = useSelector((state: RootState) => state.groupLevel1);
  const [graphData, setgraphData] = useState<Array<any>>([]);
  const [monthly_average, setmonthly_average] = useState(0)
  const [alwaysShow, ] = useState<boolean>(true)
  const [legend, setLegend] = useState<string>("Monthly Average");
  const user: InitialState = useSelector((state: RootState) => state.user);
  const userDefinedData: initialUserDefinedData = useSelector(
    (state: RootState) => state.userDefinedData
  );
  const editBudget: Editbudget = useSelector(
    (state: RootState) => state.editBudget
  );

  let userConfig: UserConfig = useSelector(
    (state: RootState) => state.userConfig
  );
  const [chartColors,] = useState<any>([{name: "Total Spend", color: customColors.graphColor.income}, {name: "average", color: 'red'}, {name: "forecast", color: customColors.graphColor.forcast}])
  const classes = useStyles();
    const renderLegend = () => {
    // const { payload } = chartColors;
    // console.log(chartColors)
    return (
      <div style={{marginLeft: '80px'}}>
        {
          chartColors.map((entry, index) => (
            <span style={{marginRight: '10px'}} key={`item-${index}`}><div style={{display:'inline-block',width:'12px',height:'12px',backgroundColor:entry.color, marginRight: '5px'}}></div>{entry.name}</span>
          ))
        }
      </div>
    );
  }  
  const editbudget: Editbudget = useSelector(
    (state: RootState) => state.editBudget
  );
  const Icon = (props) => {
    const { iconName, size, color } = props;
    let object = CustomIcons.Default;
    // console.log("IconName", iconName, "Object", object);
    if (iconName?.length > 0) {
      const string = iconName;
      object =
        CustomIcons[string] === undefined
          ? CustomIcons.Default
          : CustomIcons[string];
    }
    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color}}>{icon}</div>;
    // return <embed src={icon} aria-label="category Image" type="image/svg+xml"></embed>;
  };
  const data = [
    {
      name: 'Aug `22',
      pred_amount: 0,
      hist_spend: 2400,
      amt: 2400,
    },
    {
      name: 'Sept `22',
      pred_amount: 0,
      hist_spend: 1398,
      amt: 2210,
    },
    {
      name: 'Oct `22',
      pred_amount: 0,
      hist_spend: 9800,
      amt: 2290,
    },
    {
      name: 'Nov `22',
      pred_amount: 0,
      hist_spend: 3908,
      amt: 2000,
    },
    {
      name: 'Dec `22',
      pred_amount: 0,
      hist_spend: 4800,
      amt: 2181,
    },
    {
      name: 'Jan `22',
      pred_amount: 0,
      hist_spend: 3800,
      amt: 2500,
    },
    {
      name: 'Feb `22',
      pred_amount: 3490,
      hist_spend: 4300,
      amt: 2100,
    },
  ];
  const [searchParams, _] = useSearchParams();
  const [amount, setamount] = useState("0.00");
  const [title, setTitle] = useState('');
  const [d2dxUserconfig, setd2dxUserconfig] = useState({})
  const [error, seterror] = useState(false);
  const [experience, setExperience] = useState('');
  console.log('flow', editbudget.stream_type, 'lvl1', editbudget.lvl1, 'lvl2', editbudget.lvl2);
  let temp_d2dx_userconfig;
  // const filterValue = userConfig.income_expense.map((eachIncome))
  const userdefinedBudget = async () => {
  let updated_userConfig: UserConfig = {};
    if(amount === "" ){
      seterror(true);
    } else {
      const exp = searchParams.get("exp") || "";
      setExperience(exp);

      if (editBudget.stream_type === "expense") {
        console.log("expense-------");
        // FIXME : Try updating it via spread operator try: http://docs.jsonata.org/overview.html
        if (parseFloat(amount) !== editBudget.budget) {
          if (exp === "d2dx") {
            const expense_array = userConfig["income_expense"]?.map(
              (eachIncome: IncomeExpense) => {
                return eachIncome.expenses?.category_summary;
              }
            );
            const filtered_expenseArray = expense_array?.map(
              (eachExpense: CategoryDetails | any) => {
                return eachExpense?.level_1_group_details.map(
                  (level1: Level1GroupDetails) => {
                    if (level1.level_1_group === groupLevel1.level_1_group) {
                      const user_defined_array = level1.user_defined_values
                        ? [
                            ...level1.user_defined_values,
                            {
                              timestamp: Date.now().toString(),
                              user_defined_budget: parseFloat(amount),
                            },
                          ]
                        : [
                            {
                              timestamp: Date.now().toString(),
                              user_defined_budget: parseFloat(amount),
                            },
                          ];
                      const new_level1 = Object.assign(
                        {},
                        level1,
                        { modified_by_user: true },
                        { user_defined_values: user_defined_array }
                      );
                      return new_level1;
                    } else {
                      return level1;
                    }
                  }
                );
              }
            );
            console.log("filtered_expenseArray", filtered_expenseArray);
            temp_d2dx_userconfig = {
              "12_month_transaction_history":
                userConfig["12_month_transaction_history"],
              income_expense: [
                {
                  paycheck: userConfig?.["income_expense"]?.[0].paycheck,
                  expenses: {
                    timestamp: Date.now(),
                    category_summary: {
                      "6_month_summary_graph":
                        expense_array?.[0]?.["6_month_summary_graph"],
                      "6_month_average_graph":
                        expense_array?.[0]?.["6_month_average_graph"],
                      level_1_group_details: filtered_expenseArray?.[0],
                    },
                  },
                },
              ],
            };
            setd2dxUserconfig(temp_d2dx_userconfig);
            updateUserConfigdb(temp_d2dx_userconfig, user.email).then((result: any) => {
              if(result === 'failure') {
                navigate('/error');
                return;
              } else {
                navigate('/burndown');
                return;
              }
            }).catch((err) => {
              logError(err, 'error from edit budget')
              navigate('/error');
            });
          } else {
            let temp_userConfig = {};
            temp_userConfig["12_month_transaction_history"] =
              userConfig["12_month_transaction_history"];
            temp_userConfig["income_expense"] = [{}];
            let temp2 = userConfig["income_expense"]?.map(
              (eachIncomeExp: IncomeExpense) => {
                // temp_userConfig['cashflow'] = eachIncomeExp.cashflow;
                temp_userConfig["income_expense"][0]["timestamp"] =
                  eachIncomeExp.timestamp;
                // temp_userConfig['other_transactions'] = eachIncomeExp.other_transactions;
                temp_userConfig["income_expense"][0]["paycheck"] =
                  eachIncomeExp["paycheck"];
                // temp_userConfig['recurrence_output']= eachIncomeExp.recurrence_output;
                temp_userConfig["income_expense"][0]["expenses"] = {};

                temp_userConfig["income_expense"][0]["expenses"][
                  "category_summary"
                ] = {};
                temp_userConfig["income_expense"][0]["expenses"][
                  "category_summary"
                ]["6_month_average_graph"] =
                  eachIncomeExp.expenses.category_summary[
                    "6_month_average_graph"
                  ];
                temp_userConfig["income_expense"][0]["expenses"][
                  "category_summary"
                ]["6_month_summary_graph"] =
                  eachIncomeExp.expenses.category_summary[
                    "6_month_summary_graph"
                  ];
                let groupLevel1Details =
                  eachIncomeExp.expenses.category_summary.level_1_group_details.map(
                    (val) => {
                      let temp_val = { ...val };
                      if (temp_val.level_1_group === editBudget.lvl1) {
                        temp_val.modified_by_user = true;

                        temp_val.user_defined_values = val.user_defined_values;
                        if (val.user_defined_values) {
                          temp_val["user_defined_values"] = [
                            ...val.user_defined_values,
                            {
                              timestamp: Date.now().toString(),
                              user_defined_budget: parseFloat(amount),
                            },
                          ];
                        } else {
                          temp_val["user_defined_values"] = [];
                          temp_val["user_defined_values"].push({
                            timestamp: Date.now().toString(),
                            user_defined_budget: parseFloat(amount),
                          });
                        }
                      }
                      return temp_val;
                    }
                  );
                temp_userConfig["income_expense"][0]["expenses"][
                  "category_summary"
                ]["level_1_group_details"] = groupLevel1Details;

                return temp_userConfig;
              }
            );
            let updated_userConfig: UserConfig = {
              "12_month_transaction_history":
                temp_userConfig["12_month_transaction_history"],
              income_expense: temp_userConfig["income_expense"],
            };
            console.log("temp_userConfig", temp_userConfig);
            console.log("OG USERCONFIG", userConfig);

            dispatch(updateUserConfig(updated_userConfig));
            console.log("Updated", updated_userConfig);
          }
        }
      }
      if (editBudget.stream_type === "paycheck") {
        console.log("paycheck-------");

          // FIXME : Try updating it via spread operator
          if (parseFloat(amount) !== editBudget.budget) {
            if (exp === "d2dx") {
              const expense_array = userConfig["income_expense"]?.map(
                (eachIncome: IncomeExpense) => {
                  return eachIncome.expenses?.category_summary;
                }
              );
              const filtered_expenseArray = expense_array?.map(
                (eachExpense: CategoryDetails | any) => {
                  return eachExpense?.level_1_group_details.map(
                    (level1: Level1GroupDetails) => {
                      if (level1.level_1_group === groupLevel1.level_1_group) {
                        const user_defined_array = level1.user_defined_values
                          ? [
                              ...level1.user_defined_values,
                              {
                                timestamp: Date.now().toString(),
                                user_defined_budget: parseFloat(amount),
                              },
                            ]
                          : [
                              {
                                timestamp: Date.now().toString(),
                                user_defined_budget: parseFloat(amount),
                              },
                            ];
                        const new_level1 = Object.assign(
                          {},
                          level1,
                          { modifield_by_user: true },
                          { user_defined_values: user_defined_array }
                        );
                        return new_level1;
                      } else {
                        return level1;
                      }
                    }
                  );
                }
              );
              console.log("filtered_expenseArray", filtered_expenseArray);
              temp_d2dx_userconfig = {
                "12_month_transaction_history":
                  userConfig["12_month_transaction_history"],
                income_expense: [
                  {
                    expenses: userConfig?.["income_expense"]?.[0].expenses,
                    paycheck: {
                      timestamp: Date.now(),
                      category_summary: {
                        "6_month_summary_graph":
                          expense_array?.[0]?.["6_month_summary_graph"],
                        "6_month_average_graph":
                          expense_array?.[0]?.["6_month_average_graph"],
                        level_1_group_details: filtered_expenseArray?.[0],
                      },
                    },
                  },
                ],
              };
              setd2dxUserconfig(temp_d2dx_userconfig);
              updateUserConfigdb(temp_d2dx_userconfig, user.email).then((result: any) => {
                if(result === 'failure') {
                  navigate('/error');
                  return;
                } else {
                  navigate('/burndown');
                  return;
                }
              }).catch((err) => {
                logError(err, 'error from edit budget')
                navigate('/error');
              });
            } else {
              let temp_userConfig = {};
              temp_userConfig["12_month_transaction_history"] =
                userConfig["12_month_transaction_history"];
              temp_userConfig["income_expense"] = [{}];
              let temp2 = userConfig["income_expense"]?.map(
                (eachIncomeExp: IncomeExpense) => {
                  // temp_userConfig['cashflow'] = eachIncomeExp.cashflow;

                  temp_userConfig["income_expense"][0]["timestamp"] =
                    eachIncomeExp.timestamp;
                  // temp_userConfig['other_transactions'] = eachIncomeExp.other_transactions;

                  // temp_userConfig['recurrence_output']= eachIncomeExp.recurrence_output;
                  temp_userConfig["income_expense"][0] = {};
                  temp_userConfig["income_expense"][0]["paycheck"] = {};
                  temp_userConfig["income_expense"][0]["paycheck"][
                    "category_summary"
                  ] = {};
                  temp_userConfig["income_expense"][0]["paycheck"][
                    "category_summary"
                  ]["6_month_average_graph"] =
                    eachIncomeExp?.paycheck?.category_summary[
                      "6_month_average_graph"
                    ];
                  temp_userConfig["income_expense"][0]["paycheck"][
                    "category_summary"
                  ]["6_month_summary_graph"] =
                    eachIncomeExp?.paycheck?.category_summary[
                      "6_month_summary_graph"
                    ];
                  let groupLevel1Details =
                    eachIncomeExp?.paycheck?.category_summary.level_1_group_details.map(
                      (val) => {
                        let temp_val = { ...val };

                        if (temp_val.level_1_group === editBudget.lvl1) {
                          temp_val.modified_by_user = true;
                          temp_val.user_defined_values =
                            val.user_defined_values;
                          if (val.user_defined_values) {
                            temp_val["user_defined_values"] = [
                              ...val.user_defined_values,
                              {
                                timestamp: Date.now().toString(),
                                user_defined_budget: parseFloat(amount),
                              },
                            ];
                          } else {
                            temp_val["user_defined_values"] = [];
                            temp_val["user_defined_values"].push({
                              timestamp: Date.now().toString(),
                              user_defined_budget: parseFloat(amount),
                            });
                          }
                        }
                        return temp_val;
                      }
                    );

                  temp_userConfig["income_expense"][0]["paycheck"][
                    "category_summary"
                  ]["level_1_group_details"] = groupLevel1Details;
                  // temp_userConfig["income_expense"][0]["paycheck_deposits"]['other_deposits'] = eachIncomeExp.paycheck_deposits?.other_deposits;
                  temp_userConfig["income_expense"][0]["expenses"] =
                    eachIncomeExp.expenses;
                  return temp_userConfig;
                }
              );
              updated_userConfig = {
                "12_month_transaction_history":
                  temp_userConfig["12_month_transaction_history"],
                income_expense: temp_userConfig["income_expense"],
              };
              console.log("temp_userConfig", temp_userConfig);
              console.log("OG USERCONFIG", userConfig);
              dispatch(updateUserConfig(updated_userConfig));
              console.log("Updated", updated_userConfig);
            }
          }
      }
    }
    console.log('exp', experience);
    
    const exp = searchParams.get('exp') || '';
    setExperience(exp);
    
    if(exp === 'd2dx') {
      // updateUserConfig(userConfig);
      console.log(d2dxUserconfig);
      // updateUserConfigdb(d2dxUserconfig, user.email).then((result: any) => {
      //   if(result === 'failure') {
      //     navigate('/error');
      //     return;
      //   } else {
      //     navigate('/burndown');
      //     return;
      //   }
      // }).catch((err) => {
      //   logError(err, 'error from edit budget')
      //   navigate('/error');
      // });

      // navigate(`/d2dx/grouplevel1detail?flow=${editBudget.stream_type}`)
    } else {
      navigate(`/setbudget`);
    }
  }
  const setAmountValue = (value: string) => {
    if(isNaN(parseFloat(value))) {
      setamount("")
    } else {
    setamount((value));
    seterror(false)
    }
  }
  
  useEffect(() => {
    async function fetchData() {
      console.log('Edit budget', editBudget);
      const filtered_graph_array = userConfig['income_expense']?.map((eachIncome: IncomeExpense) => {
        if(editbudget.stream_type === "paycheck"){
  
          return eachIncome.paycheck?.category_summary;  
        }
        return eachIncome.expenses?.category_summary;
      });
      const expense_flattedArray = filtered_graph_array?.flat();
      const filtered_graph_expense = (expense_flattedArray?.map(((eachExpense:CategoryDetails| any) => {
        return eachExpense?.level_1_group_details.filter((level1: Level1GroupDetails)  => {
          return level1.level_1_group === groupLevel1.level_1_group
        })
      })));
      
      const month_summary_graph = filtered_graph_expense?.[0][0]["month_summary_graph"];
      setgraphData(month_summary_graph);
      setmonthly_average(filtered_graph_expense?.[0][0]["month_average_graph"] || 0)
      setamount((editBudget.budget).toFixed(2).toString())
    }
    fetchData();
  }, []);
  let navigate = useNavigate();
  return (
    <>
      <Card sx={{ width: "100%", marginTop: "6rem", boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1' }}>
        <CardContent className={classes.centerCardContent}>
          <span>
            <Icon
              iconName={groupLevel1.mainCategory}
              color={customColors.base.brightBlue}
            />
          </span>
        </CardContent>
        <CardContent className={classes.centerCardContent}>
          <Typography component="div" variant="h3">
            {groupLevel1.mainCategory}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{marginBottom: 1.25, boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1'}} className={classes.secondCardContent}>
        <CardContent sx={{ backgroundColor: "#F9F7F3" }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            justifyItems="center"
            sx={{ marginTop: "5rem" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3" sx={{ mb: 1.5 }}>
                Set your budget
              </Typography>
              <Card sx={{ minWidth: 275, marginBottom: 1.25, boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1' }}>
                <CardContent>
                  <div className={classes.floatContainer}>

                    <TextField
                      inputProps={{ style: { fontSize: 20 } }}
                      FormHelperTextProps={{
                        className: styles.helperText,
                      }}
                      id="filled-basic"
                      size="small"
                      autoFocus
                      value={amount}
                      variant="filled"
                      onChange={(e) => setAmountValue(e.target.value)}
                      helperText={error ? "Budget must be greater than 0" : ""}
                      error={error}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3" sx={{ mb: 1.5 }}>
                Expense history & forecast
              </Typography>
              <Card sx={{boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1'}}>
                <CardContent sx={{ minWidth: 275 }}>
                <ResponsiveContainer width="99%" aspect={1.2}>
                    <BarChart width={500} height={300} data={graphData}>
                      <ReferenceLine
                      y={monthly_average}
                        stroke="red"
                        strokeDasharray="3 3"
                        alwaysShow={alwaysShow}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month_year" />
                      <YAxis />

                      <Legend content={renderLegend} />
                      <Bar
                        dataKey="monthly_avg_amount"
                        stackId="a"
                        fill={customColors.graphColor.spend}
                      />
                      <Bar
                        dataKey="predicted_budget"
                        stackId="a"
                        fill={customColors.graphColor.forcast}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card sx={{boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1'}}>
                <CardContent sx={{ minHeight: "50px" }}>
                  <Typography variant="h4">
                    *Based on your linked accounts
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <div className={classes.buttonContainer}>
              <Button
                sx={{
                  backgroundColor: "#007AFF",
                  borderRadius: "100px",
                  width: "300px",
                  height: "62px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
                size="large"
                variant="contained"
                onClick={(e) => {
                  // openModal(e);
                  userdefinedBudget();
                }}
              >
                Save
              </Button>
            </div>
      </Card>
    </>
  );
};

export default EditBudget;
