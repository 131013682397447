import InitialState, { UpdateIncomeAction, TransactionsAction } from "../../types/redux/transactions";
import { createSlice } from "@reduxjs/toolkit";

const initialIncomeState: InitialState = {
    transactionsDetails: null
};


export const incomeSlice = createSlice({
  name: UpdateIncomeAction,
  initialState: initialIncomeState,
  reducers: {
    updateIncomes: (state, action: TransactionsAction) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.transactionsDetails = action.payload.transactionsDetails;
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateIncomes } =
incomeSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default incomeSlice.reducer;
