import { createSlice } from "@reduxjs/toolkit";
import initialGroupLeve1DetailsState, { GroupLevel1Action, UpdateGroupLevel1Details } from "../../types/redux/groupLeve1";

const initialState: initialGroupLeve1DetailsState = {
  "level_1_group": "",
  "level_2_group": "",
  "transaction_merchant_name": "",
  "computed_monthly_budget": 0,
  "computed_monthly_spend": 0,
  "user_defined_values": [{
    "user_defined_budget": 0,
    "timestamp": ""
  }],
  "modified_by_user": false,
  "grouping_value": "",
  "frequency": "",
  "flow": ""
};


export const groupLevel1Slice = createSlice({
  name: UpdateGroupLevel1Details,
  initialState: initialState,
  reducers: {
    groupLeve1Details: (state, action: GroupLevel1Action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.level_1_group = action.payload.level_1_group;
      state.level_2_group = action.payload.level_2_group;
      state.transaction_merchant_name = action.payload.transaction_merchant_name;
      state.computed_monthly_budget = action.payload.computed_monthly_budget;
      state.computed_monthly_spend = action.payload.computed_monthly_spend;
      state.user_defined_values = action.payload.user_defined_values;
      state.modified_by_user = action.payload.modified_by_user;
      state.grouping_value = action.payload.grouping_value;
      state.frequency = action.payload.frequency;
      state.flow = action.payload.flow;
    }
  },
});

// Action creators are generated for each case reducer function
export const { groupLeve1Details } =
groupLevel1Slice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default groupLevel1Slice.reducer;
