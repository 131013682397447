import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// import myData from "./test.json";
import { getWizardPrediction } from "../../backend/python";
import { RootState } from "../../redux-functionality";
import { logError } from "../ErrorHandler/logErrorService";
import Spinner from "../Spinner";
import styles from "./index.module.scss";
import initialUserDefinedData from "../../types/redux/userDefinedData";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { persistentlocalStorage } from "../../PersistStorage/persistlocalstorage";

const TransactionDetails = () => {
  let navigate = useNavigate();
  const name: string = useSelector((state: RootState) => state.user.email);
  const userDefinedData: initialUserDefinedData = useSelector(
    (state: RootState) => state.userDefinedData
  );
  const [, setpredictedData] = useState([]);
  const [, setActualData] = useState([]);
  const [showSpinner, setshowSpinner] = useState(true);
  // const [data, setData] = useState([]);
  const [graphData, setgraphData] = useState([null]);

  // let data: any = {};
  useEffect(() => {
    async function fetchData() {
      try {
        let valueFromStorage_cashflow = persistentlocalStorage.getItem("cashflow");
        let jsonResponse: any;
        let lastDateOfMonth: any = [];
        
        let predicted: any = [];
        let actualArray: any = [];

        if (valueFromStorage_cashflow === undefined) {
          jsonResponse = await getWizardPrediction(
            name,
            userDefinedData.as_of_amount,
            userDefinedData.starting_amount,
            userDefinedData.ao_day,
            userDefinedData.ao_month,
            userDefinedData.ao_year,
            userDefinedData.sd_day,
            userDefinedData.sd_month + 1,
            userDefinedData.sd_year,
            3
          );
          persistentlocalStorage.setItem('cashflow', jsonResponse);
        } else {
          jsonResponse = persistentlocalStorage.getItem('cashflow');
        }

        for (let i = 0; i < jsonResponse.output.length; i++) {
          const current = jsonResponse.output[i];

          let lastDate: any;
          let next = jsonResponse.output[i + 1];
          if (next) {
            let currDate = new Date(current.date);
            let nextDate = new Date(next.date);
            if (currDate.getMonth() === nextDate.getMonth()) {
              lastDate = next;
              console.log("lastDate", lastDate);
            } else {
              lastDateOfMonth.push(next);
            }
          } else {
            lastDateOfMonth.push(current);
          }
        }
        setActualData(actualArray);
        setgraphData(lastDateOfMonth);
        lastDateOfMonth.map(
          (e: any) =>
            (e.date =
              new Date(e.date).toLocaleDateString(undefined, {
                month: "short",
              }) +
              new Date(e.date).toLocaleDateString("en", { year: "2-digit" }))
        );
        setpredictedData(predicted);
        setshowSpinner(false);
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, [name,
    userDefinedData.ao_day,
    userDefinedData.ao_month,
    userDefinedData.ao_year, userDefinedData.as_of_amount, userDefinedData.sd_day, userDefinedData.sd_month, userDefinedData.sd_year,  userDefinedData.starting_amount]);

  return (
    <div className={styles.grid}>
      <h3 className={styles.title}>CASHFLOW</h3>
      {showSpinner ? (
        <Spinner />
      ) : (
        <div>
          <ResponsiveContainer width="100%" aspect={3}>
            <LineChart data={graphData} margin={{ right: 300 }}>
              <CartesianGrid />
              <XAxis dataKey="date" interval={"preserveStartEnd"} />
              <Legend />
              <Tooltip />
              <Line dataKey="actual" stroke="black" activeDot={{ r: 8 }} />
              <Line dataKey="predicted" stroke="red" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate(`/reviewbudget`);
            }}
          >
            Go back to confirm expense
          </Button>
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
