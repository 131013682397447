interface ProgressRoute {
    routesarray: Array<String>
  }
  const UpdateRoute: string = "updateRoute";
  
  export interface UpdateRouteAction {
    type: String;
    payload: {
    routesarray: Array<String>
    };
  }
  export default ProgressRoute;
  
  export { UpdateRoute };
  