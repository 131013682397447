import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const Welcome = () => {
  useEffect(() => {}, []);
  let navigate = useNavigate();
  return (
    <div className={styles.grid}>
      <div>
        <h3 className={styles.title}>
          Welcome Kyle to your dscout Live Mission!
        </h3>
        <Button variant="contained" size="large" onClick={() => {navigate(`/reviewexpense`)}}>
          Let's Dive In
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
