import { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

// import * as response from "./output_recurr_csv.json";
import { logError } from "../ErrorHandler/logErrorService";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux-functionality";
import UserConfig from "../../types/redux/userConfig";
import InitialState from "../../types/redux/user";
import { setOnboarding, updateUserConfigdb } from "../../backend/transaction";
import { useNavigate } from "react-router-dom";

const success = require('../../Assets/success.svg').default



const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingLeft: "50px",
    paddingBottom: "20px"
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  secondCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  cateogryIcon: {
    // width: '8%',    
    // float: 'left',
    // [theme.breakpoints.down("md")]: {
    //   marginRight: "10px",
    // },
  },
  
}));




const AllSet = () => {
const userConfig: UserConfig = useSelector(
    (state: RootState) => state.userConfig
  );

const userinfo: InitialState = useSelector((state: RootState) => state.user);
const classes = useStyles();
const navigate = useNavigate();
const callrecurrence = async() => {
  console.log(userConfig);
  console.log(userinfo);
  const result = await updateUserConfigdb(userConfig, userinfo.email);
  if(result !== 'failure'){
  const onboardingCompelete = await setOnboarding(userinfo.email);

  if(onboardingCompelete !== 'failure') {
    navigate('/burndown')
  } else {
    navigate('/error')
  }
}  else {
  navigate("/error");
}
}

  useEffect(() => {
    async function fetchData() {
      try {
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: "100%",
          marginTop: "6rem",
          minWidth: 275,
          boxShadow: 0,
          borderStyle: 0,
          borderRadius: 0,
        }}
      >
        <CardContent className={classes.centerCardContent}>
          <div className={classes.cateogryIcon}>
            <Typography variant="button">
              <img
                src={success}
                alt="success"
                height={100}
                width={100}
                color="green"
              />
            </Typography>
          </div>
        </CardContent>
        <CardContent className={classes.centerCardContent}>
          <Typography component="div" variant="h2">
            You're all set!
          </Typography>
        </CardContent>
        
      </Card>
      <div className={classes.secondCardContent}>
        <Card
          sx={{ minWidth: 275, boxShadow: 0, borderStyle: 0, borderRadius: 0 }}
        >
          <CardContent
            sx={{
              padding: "50px",
              paddingTop: "20px",
              paddingBottom: "20px",
              alignItems: "left",
            }}
          >
            <Typography component="div" variant="body1">
            Now that your cashflow plan is set up, you can track your progress and see how you’re doing at any time.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          sx={{
            backgroundColor: "#007AFF",
            borderRadius: "100px",
            width: "300px",
            height: "62px",
            fontSize: "14px",
            fontFamily: "Poppins",
          }}
          size="large"
          variant="contained"
          onClick={(e) => {
            // openModal(e);
            callrecurrence()
          }}
        >
          Go to dashboard
        </Button>
      </div>
    </div>
  );
};

export default AllSet;
