import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useContext, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../Account';
import { useSelector } from 'react-redux';
import InitialState from '../../types/redux/user';
import { RootState } from '../../redux-functionality';
import { getCashFlowSetUpStatus } from '../../backend/transaction';
import { reject } from 'lodash';

const theme = createTheme();
const useStyles = makeStyles({
    font: {
        fontSize: '14px',
        [theme.breakpoints.down['sm']]: {
            fontSize: '14px'
        }
    }
  });

const AppHeader = () => {
    const classes = useStyles();
    let navigate = useNavigate();
    const location = useLocation();
    const userinfo: InitialState = useSelector((state: RootState) => state.user);
    const [navItems, setnavItems] = useState([{name: 'Logout', link: 'welcome'}])
    const { getSession, logout } = useContext(AccountContext);
    const drawerWidth = 240;
    const [mobileOpen, setMobileOpen] = useState(false);
  const handleClick = (linkName: string) => {
    if(linkName === 'welcome') {
      logout();
      navigate(`/login`);
    } else {
    navigate(`/${linkName}`);
    }
  };
    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };
    useEffect(() => {
      const { pathname } = location;
      console.log('***', userinfo.email);
      if(userinfo.email !== ''){
      getCashFlowSetUpStatus(userinfo.email)
        .then((response) => {
          console.log("Navigating from Account", response);
          if (response.filter((x) => x.set_up_completed === true).length > 0) {
            setnavItems([{name: 'Income & Expense Plan', link: 'burndown'}, {name: 'Logout', link: 'welcome'}])
            console.log(`*** Logged in ***`);
          } else {
            console.log(`*** Not logged in ***`);
          }
        })
        .catch((err) => {
          console.error(`Error in authenticate ${err}`);
          reject(err);
        });
      }
    }, [location.pathname])
    const container = window !== undefined ? () => window.document.body : undefined;
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#104F93' }}>
          <Divider />
          <List>
            {navItems.map((item, index) => (
              <ListItem key={index} onClick = {() => {handleClick(item.link)}} sx={{ color: '#fff', fontSize: '14px'}} disablePadding>
                <ListItemButton sx={{ color: '#fff', fontSize: '14px', textAlign: 'center' }}>
                  <ListItemText disableTypography primary={item.name} className={classes.font}/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      );
      

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar sx={{ backgroundColor: "#104F93",  margin: "0 auto", width: "80%", right: "inherit" }} component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "block", sm: "block" },
                fontSize: "22px",
                fontWeight: "700",
                textAlign: { xs: "center", sm: "inherit", lg: "inherit" },
              }}
            >
              Aver Money
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, index) => (
                <Button onClick = {() => {handleClick(item.link)}} key={index} sx={{ color: "#fff", fontSize: "14px" }}>
                  {item.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        
      </Box>
    );
}
export default AppHeader;