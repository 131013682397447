// import { API_URL } from './../env';

export async function getWizardCalculation(name = '', day = '', month = '', year = '') {
  try {
    const response = await fetch(`https://vsg8wp6v86.execute-api.us-east-1.amazonaws.com/default/python_script_poc?user=${name}&day=${day}&month=${month}&year=${year}`, {
      // const response = await fetch(`https://vyehyvhhb8.execute-api.us-east-1.amazonaws.com/default/python_poc_v2?user=${name}&day=${day}&month=${month}&year=${year}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json'
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata);
    console.log('Recurrence Data', responsedata)
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function getWizardPrediction(name = '', as_of_balance = 0, actual_start_balance = 0, ao_day = 0, ao_month = 0, ao_year = 0, sd_day = 0, sd_month = 0, sd_year = 0, horizon = 3) {
  try {
    // 👇️ const response: Response
    //   email = 'somethingwron@testme.com'
    // const response = await fetch(`https://kmw51nzc9a.execute-api.us-east-1.amazonaws.com/Test/python`, {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //       'Access-Control-Allow-Origin': '*',
    //       "Access-Control-Allow-Credentials": 'true'
    //     },
    //   })

    const response = await fetch(`https://mro70bdwre.execute-api.us-east-1.amazonaws.com/default/python_script_poc_prediction?user=${name}&as_of_balance=${as_of_balance}&actual_start_balance=${actual_start_balance}&ao_day=${ao_day}&ao_month=${ao_month}&ao_year=${ao_year}&sd_day=${sd_day}&sd_month=${sd_month}&sd_year=${sd_year}&horizon=3`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}
