interface initialTransactionState {
    transactionsDetails: null
  }
  const UpdateIncomeAction: string = "updateIncomeTransactions";
  const UpdateExpenseAction: string = "updateExpenseTransactions";
  
  export interface TransactionsAction {
    type: String;
    payload: {
        transactionsDetails: any;
    };
  }
  export default initialTransactionState;
  
  export { UpdateIncomeAction, UpdateExpenseAction };
  