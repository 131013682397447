import { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

// import * as response from "./output_recurr_csv.json";
import { logError } from "../ErrorHandler/logErrorService";
import { Card, CardContent, Link, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingLeft: "50px",
    paddingBottom: "20px"
  },
}));


const Help = () => {
const classes = useStyles();
  useEffect(() => {
    async function fetchData() {
      try {
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <>
        <Typography className={classes.title} variant="h2">
          Help
        </Typography>
        <Card sx={{ minWidth: 275,  boxShadow: 0,
              borderStyle: 0, borderRadius: 0 }}>
          <CardContent
            sx={{
              background: '#EDF0FF',
              padding: "50px",
              paddingTop: "20px",
              paddingBottom: "20px",
              alignItems: "left"
            }}
          >
            <Typography variant="h3" >Contact Us</Typography>
            <Typography variant="body1">
            For questions or to get help, contact us at <Link href={`mailto:beta@avermoney.com`} variant="body1">beta@avermoney.com</Link>.
            </Typography>
          </CardContent>
        </Card>
      </>
    </div>
  );
};

export default Help;
