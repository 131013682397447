import UserConfig from '../types/redux/userConfig';
import { API_URL } from './../env';
// import fetch from 'node-fetch';

export async function getRecurringTransactions(email: string) {
  try {
    // FIXME
    email = 'somethingwron@testme.com'
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function updateUserConfigdb(userconfig: UserConfig, email) {
  try{
    const response = await fetch(`${API_URL}/set/userconfig`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        userconfig: userconfig,
        email
      }),    
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }   
  }
}

export async function getAccountDetails(email= '') {
  try {
    console.dir('getAccountDetails', email)
    const response = await fetch(`${API_URL}/get/useraccountdetails`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        email
      }),    
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    console.log('responsedata', responsedata)
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function getCashFlowSetUpStatus(email= '') {
  try {
    console.dir('getAccountDetails', email)
    const response = await fetch(`${API_URL}/get/statuscashflow`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        email
      }),    
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    console.log('responsedata', responsedata)
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function deleteAccount(email, accountid) {
  try {
    const response = await fetch(`${API_URL}/delete/user`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        email,
        accountid
      }),    
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    console.log('responsedata', responsedata)
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function getIncomeDetails(incomeArray = {accountIds:[''],lvl1_grps: [''],lvl2_grps: [''], merchants: [''] }) {
  try {
    console.log('INCOME ARRAY',incomeArray )
    // const reuqestBody = { income: incomeArray}
    const response = await fetch(`${API_URL}/get/incomedetails`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        incomeArray
      }),
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function setOnboarding(email:string) {
  try {
    console.log('username', email);
    const response = await fetch(`${API_URL}/set/onboardingcomplete`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email
      }),  
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata);
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
  
}


export async function getTransactionHistory(username:string = '') {
  try {
    const response = await fetch(`${API_URL}/get/transactionshistory/${username}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
  
}

export async function getUsers() {
  try {
    const response = await fetch(`${API_URL}/get/users`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}

export async function getGroupLeve1Details(account_id = '', lvl1_grp ='') {
  try {
    console.log(account_id, lvl1_grp)
    const response = await fetch(`${API_URL}/get/grouplevel1Details/${account_id}/${lvl1_grp}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('GROUP LEVEL1 retrieval successfully', jsonResponsedata);
    console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
    return jsonResponsedata;
  } catch (error) {
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function getlvl1BudgetHistory(account_id = '', lvl1_grp ='') {
  try {
    
    const response = await fetch(`${API_URL}/get/lvl1/transactionshistory/${account_id}/${lvl1_grp}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('GROUP LEVEL1 retrieval successfully', jsonResponsedata);
    console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
    return jsonResponsedata;
  } catch (error) {
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function getMonthlyTransactions(username='', startDay, endDay, month, year){
  try {
    console.log(username);
    const response = await fetch(`${API_URL}/get/monthlytransactions/${username}/${startDay}/${endDay}/${month}/${year}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Monthly Response', jsonResponsedata);
    return jsonResponsedata;
  }catch(error){
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function getMonthlyIncome(username='', startDay, endDay, month, year){
  try {
    console.log(username);
    const response = await fetch(`${API_URL}/get/monthlyIncome/${username}/${startDay}/${endDay}/${month}/${year}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Monthly Response', jsonResponsedata);
    return jsonResponsedata;
  }catch(error){
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function getrecurrence(username='', dateString = ''){
  try {
    console.log(username);
    const response = await fetch(`${API_URL}/get/recurrence/${username}/${dateString}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Recurrence Response', jsonResponsedata);
    return jsonResponsedata;
  }catch(error){
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function getTransactionsDetailsHistory(account_id = '', lvl1_grp ='', lvl2_grp= '', merchant_name='', grouping_value='') {
  try {
    console.log(account_id, lvl1_grp, lvl2_grp, merchant_name, grouping_value)
    const response = await fetch(`${API_URL}/get/transactionDetails/history/${account_id}/${lvl1_grp}/${lvl2_grp}/${merchant_name}/${grouping_value}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Transaction retrieval successfully', jsonResponsedata);
    console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
    return jsonResponsedata;
  } catch (error) {
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}


export async function getTransactionsDetails(account_id = '', lvl1_grp ='', lvl2_grp= '', merchant_name='', grouping_value='') {
  try {
    console.log(account_id, lvl1_grp, lvl2_grp, merchant_name, grouping_value)
    const response = await fetch(`${API_URL}/get/transactionDetails/${account_id}/${lvl1_grp}/${lvl2_grp}/${merchant_name}/${grouping_value}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Transaction retrieval successfully', jsonResponsedata);
    console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
    return jsonResponsedata;
  } catch (error) {
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function setPrimaryAccount(item_id, account_id, email) {
  try{
    const response = await fetch(`${API_URL}/set/primary`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        email, account_id, item_id
      }),    
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata);
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }   
  }  
}

export async function refreshTransactions(item_id='', webhook= 'APP_CALL') {
  try{
    const response =  await fetch(`${API_URL}/refresh/${item_id}/${webhook}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }

  });
  if (!response.ok) {
    throw new Error(`Error! status: ${response.status}`);
  }
  const responsedata = await response.text();
  const jsonResponsedata = JSON.parse(responsedata)
  console.log('Transaction retrieval successfully', jsonResponsedata);
  console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
  return jsonResponsedata;
} catch (error) {
  console.log('unexpected error: ', error);
  return ['An unexpected error occurred'];
}
}

export async function getItemId(username = '') {
  try{
    const response = await fetch(`${API_URL}/get/itemid/${username}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Transaction retrieval successfully', jsonResponsedata);
    console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }   
  }
}

export async function getUserConfig(username = '') {
  try{
    const response = await fetch(`${API_URL}/get/userconfig`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        email: username
      }),    
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata);
    return jsonResponsedata;
  } catch(error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }   
  }
}
export async function setUserDefinedBudget(accountid: string, lvl1_grp: string, username: string, userDefinedAmount: number, dateString: string, type: string) {
  try {
    
    const response = await fetch(`${API_URL}/set/userdefinedbudget`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        accountid,
        lvl1_grp,
        username,
        userDefinedAmount,
        dateString,
        type
      }),
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    console.log('Transaction retrieval successfully', jsonResponsedata);
    console.log(`RESPONSE FROM SERVER`, jsonResponsedata);
    return jsonResponsedata;
  }catch (error){
    console.log('unexpected error: ', error);
    return ['An unexpected error occurred'];
  }
}

export async function getTransactions(email: string) {
  try {
    // 👇️ const response: Response
    email = 'somethingwron@testme.com'
    // fetch(`${API_URL}/get/transactions/${email}`, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //   },
    // }).then((response) => {
    //   return response.text()
    // }).then((data) => {
    //   console.log(data);
    // });
    const response = await fetch(`${API_URL}/get/transactions/${email}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const responsedata = await response.text();
    const jsonResponsedata = JSON.parse(responsedata)
    // console.log('Transaction retrieval successfully', jsonResponsedata);
    //   console.log(`RESPONSE FROM SERVER`, JSON.parse(response.body));
    return jsonResponsedata;
  } catch (error) {
    if (error instanceof Error) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}
