import { useNavigate } from "react-router-dom";
import { createContext } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from "amazon-cognito-identity-js";
import Pool from "../../UserPool";
import { API_URL } from "../../env";
import { getCashFlowSetUpStatus } from "../../backend/transaction";

const AccountContext = createContext(undefined) as any;
const Account = (props: any) => {
    const navigate = useNavigate();

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession(async (err: Error, session: any) => {
                    if (err) {
                        reject();
                    } else {
                        const attributes: any = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err)
                                } else {
                                    const results = {} as any;

                                    for (let attribute of attributes as any) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value
                                    }
                                    resolve(results);
                                }
                            });
                        });

                        resolve({ user, ...session, ...attributes });
                    }
                });
            } else {
                reject();
            }
        });
    };

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
        }
        navigate(`/login`);
    }
    const authenticate = async (Username: string, Password?: string) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            });

            const authDetails = new AuthenticationDetails({
                Username,
                Password
            });

            user.authenticateUser(authDetails, {
                onSuccess: (data: CognitoUserSession) => {
                    console.log("OnSuccess: ", Username);
                    fetch(`${API_URL}/api/set_cookies`, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ username: Username })
                        
                    }).then((response) => {
                        resolve(response);
                        getCashFlowSetUpStatus(Username).then((response) => {
                            console.log('Navigating from Account', response);
                            if(response.filter((x) => x.set_up_completed === true).length  > 0) {
                                navigate("/burndown", {replace: true})
                            } else {
                            navigate("/agreement", { replace: true });
                            }
                        }).catch((err) => {
                            console.error(`Error in authenticate ${err}`);
                            reject(err);
                        });
                    }).catch((err) => {
                        console.error(`Error in authenticate ${err}`);
                        reject(err);
                    });


                },
                onFailure: (err) => {
                    console.error("OnFailure: ", err);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired ", data);
                    resolve(data);
                }
            })
        });
    };

    const forgotPassword = async (Username: string) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            });
            user.forgotPassword({
                onSuccess: (data) => {
                    console.log("Password Reset ", data);
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error("OnFailure: ", err);
                    reject(err);
                }
            })
        }
        )
    }

    const resetPassword = async (Username: string, verificationCode: string, newPassword: string) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            });

            user.confirmPassword(verificationCode, newPassword, {
                onSuccess: (data) => {
                    console.log(`Password sucesfully changed: ${data}`);
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error("OnFailure on changing password: ", err);
                    reject(err);
                }
            })
        }
        )
    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout, forgotPassword, resetPassword }}>
            {props.children}
        </AccountContext.Provider>
    )
};

export { Account, AccountContext };