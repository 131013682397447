import { useCallback, useEffect, useReducer, useState } from "react";
import UserPool from "../../UserPool";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert, Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";

const Register = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [proceedToAws, setproceedToAws] = useState(false);
  const [awsError, setawsError] = useState("");
  const [secondPassword, setConfirmPassword] = useState("");
  const [match, setMatch] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [allFieldsRequiredError, setAllFieldsRequiredError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [validEmail, setvalidEmail] = useState(true);
  const [validpassword, setvalidpassword] = useState(true);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const callAws = useCallback((email, password) => {
        UserPool.signUp(email, password, [], null as any, (err, data) => {
          if (err) {
            setSpinner(false);
            setawsError(err.message);
            setproceedToAws(false);
            forceUpdate();
            console.error(`AWS error ${err.message}`);
          } else {
            setSpinner(false);
            forceUpdate();
            console.log(`Navigate to Dashboard from Register ${data}`);
            navigate(`/login`);
          }
        });
      }, [email, navigate, password]);

      const checkRequired = (formData) => {
        return (formData.get('email').toString === 0 || formData.get('password').toString === 0 || formData.get('comfirm-password').toString === 0 ? true: false)
      }
const theme = createTheme();

  useEffect(() => {
    // this hook will get called everytime when allFieldsRequiredError has changed
    // perform some action which will get fired everytime when allFieldsRequiredError gets updated
    console.log(`Checking......`);
    if (
      proceedToAws &&
      !allFieldsRequiredError &&
      match &&
      awsError.length === 0
    ) {
      // callAws();
    }
  }, [
    allFieldsRequiredError,
    match,
    proceedToAws,
    callAws,
    awsError,
    spinner,
    forceUpdate,
  ]);

  const checkReset = () => {
    console.log(`Check reset`);
    setMatch(true);
    setAllFieldsRequiredError(false);
    setawsError("");
    // setproceedToAws(false);
  };
  const checkemail = (email) => {
    const emailstring = email?.toString() || "test";
    setvalidEmail(!!emailstring.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ));
    return !!emailstring.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }
  const onSubmit1 = async (event: React.FormEvent<HTMLFormElement>) => { 
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    checkReset();
    setAllFieldsRequiredError(checkRequired(formData));
    setMatch(formData.get('password') === formData.get('comfirm-password'))
    setvalidEmail(!!formData.get('email')?.toString().match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ));
    setvalidpassword(!!!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/));
    console.log(`Email ${!!formData.get('email')?.toString().match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )}`);
    
    console.log(`Password ${!!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)}`);
      if(!checkRequired(formData) && formData.get('password') === formData.get('comfirm-password') && (!!formData.get('email')?.toString().match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) && (!!!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/))){
        callAws(formData.get('email')?.toString(), formData.get('password')?.toString())
        console.log('EVERYTHING CORRECT')
      }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
            sx={{
              marginTop: 13,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontFamily: "Poppins", marginTop: 2 }}
              component="div"
              variant="h4"
            >
              Create your account to get started.
            </Typography>
            <Box component="form" onSubmit={onSubmit1} noValidate sx={{ mt: 1 }}>
              {allFieldsRequiredError && (
                <Alert severity="error">All Fields are required.</Alert>
              )}
              {!match && (
                <Alert severity="error">
                  Password and confirm password must match
                </Alert>
              )}
              {passwordError && (
                <Alert severity="error">
                  <div className="alert alert-danger" role="alert">
                    Password cannot be blank.
                    <ul>
                      <li>Password length must be atleast 8 characters</li>
                      <li>
                        Password must contain atleast one special characters,
                        one uppercase letter and one lowercase letter
                      </li>
                    </ul>
                  </div>
                </Alert>
              )}
              {!validEmail && (
                <Alert severity="error">
                  Please enter a valid email address
                </Alert>
              )}
              {!validpassword && (
                <Alert severity="error">
                  <ul>
                    <li>Password length must be atleast 8 characters</li>
                    <li>
                      Password must contain atleast one special characters, one
                      uppercase letter and one lowercase letter
                    </li>
                  </ul>
                </Alert>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                variant="filled"
                FormHelperTextProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    color: "red",
                  },
                }}
                inputProps={{ style: { fontFamily: "Poppins", fontSize: 14 } }} // font size of input text
                InputLabelProps={{
                  style: { fontFamily: "Poppins", fontSize: 14 },
                }} // font size of input label
                // helperText={usernameError ? "Please enter a valid username" : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                variant="filled"
                autoComplete="current-password"
                FormHelperTextProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    color: "red",
                  },
                }}
                inputProps={{ style: { fontFamily: "Poppins", fontSize: 14 } }} // font size of input text
                InputLabelProps={{
                  style: { fontFamily: "Poppins", fontSize: 14 },
                }} // font size of input label
                helperText={
                  passwordError ? "Please enter a valid password" : ""
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="comfirm-password"
                label="Confrim Password"
                type="password"
                id="confirm-password"
                variant="filled"
                autoComplete="comfirm-passwor"
                FormHelperTextProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "1.2rem",
                    color: "red",
                  },
                }}
                inputProps={{ style: { fontFamily: "Poppins", fontSize: 14 } }} // font size of input text
                InputLabelProps={{
                  style: { fontFamily: "Poppins", fontSize: 14 },
                }} // font size of input label
                helperText={
                  passwordError ? "Please enter a valid password" : ""
                }
              />
              <Button
                sx={{
                  backgroundColor: "#007AFF",
                  borderRadius: "100px",
                  // width: "300px",
                  height: "62px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  marginTop: 2,
                }}
                size="large"
                variant="contained"
                type="submit"
                fullWidth
              >
                Register
              </Button>

              <Grid sx={{ marginTop: 3 }} container>
                <Grid item xs>
                  <Link
                    sx={{ fontFamily: "Poppins", fontSize: 14 }}
                    component={RouterLink}
                    to="/forgot"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    sx={{ fontFamily: "Poppins", fontSize: 14 }}
                    component={RouterLink}
                    to="/login"
                    variant="body2"
                  >
                    {"Already have an account? Log In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Register;
