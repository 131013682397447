import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

// import * as response from "./output_recurr_csv.json";
import { logError } from "../ErrorHandler/logErrorService";
import { Card, CardContent, Typography } from "@mui/material";
import { BarChart2, Edit, Link, TrendingUp } from "react-feather";
import CustomizedProgressBars from "../CustomerProgress";
import { useLocation } from "react-router-dom";
import PlaidLink from "../Link";
import { useSelector } from "react-redux";
import InitialState from "../../types/redux/user";
import { RootState } from "../../redux-functionality";

const star = require('../../Assets/star_1.svg').default

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingLeft: "50px",
    paddingBottom: "20px"
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  secondCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  cateogryIcon: {
    // width: '8%',    
    // float: 'left',
    // [theme.breakpoints.down("md")]: {
    //   marginRight: "10px",
    // },
  },
  
}));


const GettingStarted = () => {
const classes = useStyles();
const location = useLocation();
const [path, setpath] = useState('')
const userprofile: InitialState = useSelector(
  (state: RootState) => state.user
);
  useEffect(() => {
    
    async function fetchData() {
      try {
        const currentPath = location.pathname;
        setpath(currentPath);
        console.log('currentPath', currentPath, 'path', path);
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: "100%",
          marginTop: "6rem",
          minWidth: 275,
          boxShadow: 0,
          borderStyle: 0,
          borderRadius: 0,
        }}
      >
        <CardContent className={classes.centerCardContent}>
          <div className={classes.cateogryIcon}>
            <Typography variant="button">
              <img
                src={star}
                alt="star"
                height={100}
                width={100}
                color="green"
              />
            </Typography>
          </div>
        </CardContent>
        <CardContent className={classes.centerCardContent}>
          <Typography component="div" variant="h3">
            Let's get started
          </Typography>
        </CardContent>
      </Card>
      <div className={classes.secondCardContent}>
        <Card
          sx={{ minWidth: 275, boxShadow: 0, borderStyle: 0, borderRadius: 0 }}
        >
          <CardContent
            sx={{
              padding: "50px",
              paddingTop: "20px",
              paddingBottom: "20px",
              alignItems: "left",
            }}
          >
            <CustomizedProgressBars route={location.pathname} />
            <Typography variant="h3" sx={{ marginBottom: 2, marginTop: 4 }}>
              What happens next:
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <Link color="grey" style={{ marginRight: "10px" }} />
              Connect your checking, savings, and credit cards accounts.
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <BarChart2 color="grey" style={{ marginRight: "10px" }} />
              Review your income and expenses for the last 12 months.
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <Edit color="grey" style={{ marginRight: "10px" }} />
              Set your desired income and expense plan (you can change this
              later).
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <TrendingUp color="grey" style={{ marginRight: "10px" }} />
              Finally, take a look at how your plan plays out into the future.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div className={classes.buttonContainer}>
        {/* <Button
          sx={{
            backgroundColor: "#007AFF",
            borderRadius: "100px",
            width: "300px",
            height: "62px",
            fontSize: "14px",
            fontFamily: "Poppins",
          }}
          size="large"
          variant="contained"
          onClick={(e) => {
            // openModal(e);
            // navigate(`/`);
          }}
        > */}
          <PlaidLink displayLink="false"/>
          
        {/* </Button> */}
      </div>
      
    </div>
  );
};

export default GettingStarted;
