import {
    BookOpen,
    Coffee,
    DollarSign,
    Edit,
    Heart,
    Home,
    Music,
    Package,
    ShoppingBag,
    Users,
} from 'react-feather';

const deposits = require('./Assets/deposits.svg').default as string;
export const CustomIcons = {
    Default: DollarSign,
    Edit: Edit,
    Paycheck: DollarSign,
    'Entertainment & Recreation': Music,
    'Housing & Utilities': Home,
    'Peer to Peer Payment': Users,
    Shopping: ShoppingBag,
    'Food & Dining': Coffee,
    'Community & Education': BookOpen,
    'Health & Personal Care': Heart,
    'Goods & Service': Package,
    OtherDesposits: deposits
}