import { useEffect } from "react";
import Lottie from "react-lottie-player";
import * as wizardAnimtation from "./wizard-animation-character.json";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  center: {
    margin: "auto",
    width: '50%',
    padding: '10px',
    [theme.breakpoints.down("md")]: {
      width: '100%',
      paddingTop: '5rem'
    },
    // paddingTop: 5rem;
}
}))
const Spinner = () => {
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {

    }
    fetchData();
  }, []);

  return (
    <div className={classes.center}>
      <Lottie
        loop={true}
        play={true}
        animationData={wizardAnimtation}
      />

    </div>
  );
};

export default Spinner;
