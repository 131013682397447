import { configureStore } from "@reduxjs/toolkit";
// This is how you import a reducer, based on the prior export.
import userReducer from "./slices/userSlice";
import transactionsReducer from "./slices/incomeSlice";
import userDefinedDataReducer from "./slices/userDataSlice";
import tranasctionDetailsReqReducer from "./slices/transactionDetailsSlice";
import groupLevel1SliceReducer from "./slices/groupLevel1Slice";
import userConfigReducer from "./slices/userConfigSlice";
import editBudgetReducer from "./slices/editbudgetSlice";
import progressrouteReducer from "./slices/progressrouteSlice";

const store = configureStore({
  reducer: {
    // You are free to call the LHS what you like, but it must have a reducer on the RHS.
    user: userReducer,
    transactions: transactionsReducer,
    userDefinedData: userDefinedDataReducer,
    trnasactionDetailsReq: tranasctionDetailsReqReducer,
    groupLevel1: groupLevel1SliceReducer,
    userConfig: userConfigReducer,
    editBudget: editBudgetReducer,
    progressroute: progressrouteReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
