import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const ReviewYourIncome = () => {
  let navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <div className={styles.grid}>
      <div>
        <h3 className={styles.title}>
          First up, let's review your recurring income.
        </h3>
        <p>
          Lorem ipsum dolor sit amet consectetur. Faucibus quis eget tempor non.
          Non at non pharetra in amet vulputate mollis. Turpis rhoncus
          sollicitudin egestas in nibh posuere ullamcorper dolor.
        </p>
        <Button variant="contained" size="large" onClick={() => {navigate(`/reviewbudget`)}}>
            Review my income
        </Button>
      </div>
    </div>
  );
};

export default ReviewYourIncome;
