import React from "react";
import { logError } from "./logErrorService";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";


class ErrorHandler extends React.Component<
  {},
  { hasError: boolean; error: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: string) {
    // Update state so the next render will show the fallback UI.
  
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service like AppSignal
    logError(error, errorInfo);
  }

  render() {
    // You can render any custom fallback UI
    return (
    <div style={{marginTop: '10rem', display:'flex', justifyContent:'center'}}>
      <Card sx={{ maxWidth: 600, boxShadow: 0, borderRadius: 0, }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="auto"
          width="600"
          image="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif"
          alt="cave_man error"
        />
        <CardContent>
          <Typography sx={{marginTop: -8}} gutterBottom variant="h3" component="div">
            OOPS!!
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Looks like we are experiecing technical difficulties.The page you are looking for not avaible!
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
    );
  }
}

export default ErrorHandler;
