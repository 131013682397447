import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Spinner from "../Spinner";

import { logError } from "../ErrorHandler/logErrorService";
import { customColors } from "../../theme";
import React from "react";
import { CustomIcons } from "../../icon";
import { deleteAccount, getAccountDetails } from "../../backend/transaction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux-functionality";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import CustomizedProgressBars from "../CustomerProgress";
import { MinusCircle } from "react-feather";
import PlaidLink from "../Link";
import styles from './index.module.scss';
import InitialState from "../../types/redux/user";

const security = require('../../Assets/security.svg').default

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  root: {
    minWidth: 200,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  progressBar: {
    marginLeft: '15%',
    marginRight: '15%',
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
      marginLeft: "0",
    },
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cateogryIcon: {
    width: "10%",
    // float: "left",
    marginTop: "2%",
    [theme.breakpoints.down("md")]: {
      width: "0%",
      float: "left",
      display: "flex",
      justifyContent: "center",
    },
  },
  icon: {
    top: "-18px",
    position: "relative",
    float: "right",
  },
  backgroundColor: {
    backgroundColor: customColors.secondary.beige,
  },
  paddingTop: {
    paddingTop: "10rem",
  },
  gridClass: {
    marginTop: "4rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "2rem"
    }
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  buttonStyle: {
    "MuiButton-root": {
      backgroundColor: "red",
    },
  },
}));

const AccountSummary = () => {

  let navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Icon = props => {
    const { iconName, size, color } = props;
    let  object = CustomIcons.Default;
    if(iconName.length > 0) {
    const string = iconName;
    object = CustomIcons[string] === undefined ? CustomIcons.Default : CustomIcons[string];
    }
    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  };
  
  const [accountDetails, setAccountDetails] = useState<Array<any>>([{
    "email": "",
    "plaid_item_id": "",
    "plaid_access_token": "",
    "plaid_account_id": "",
    "is_primary": false,
    "id": "151",
    "account_name": "Chase checking account",
    "official_name": null,
    "account_type": "",
    "available_balance": null,
    "current_balance": ""
  }]);
  const [showSpinner, setshowSpinner] = useState(true);
  const name: string = useSelector((state: RootState) => state.user.email);
  const userinfo: InitialState = useSelector((state: RootState) => state.user);

  const location = useLocation();
  const [selectAccount, setSelectedAccount] = useState({
  "email": "",
  "plaid_item_id": "",
  "plaid_access_token": "",
  "plaid_account_id": "",
  "is_primary": false,
  "account_name": "Loading....",
  "official_name": null,
  "account_type": "",
  "available_balance": null,
  "current_balance": ""
});
const deleteUIAccount = async(selectAccount) => {
  console.log('setSelectedAccount', selectAccount);
  setSelectedAccount(selectAccount);
  const result = await deleteAccount(selectAccount.email, selectAccount.plaid_account_id);
  console.log('result', result);
  if(result === 'Success'){
    const accountDetails = await getAccountDetails(selectAccount.email);
    console.log('result', result);
    setAccountDetails(accountDetails);
  }
}
const handleContinue = () => {
  // dispatch(updateUserConfig(userData));
  // setshowSpinner(true);

  navigate('/selectaccount')
};
  useEffect(() => {
        
    async function fetchData() {
      try {
        console.log('CALLING ACCOUNT DETAILS', name);
        setshowSpinner(true);
          const result1 = await getAccountDetails(name);
          const result2 = await getAccountDetails(name);
          console.log('result1', result1, 'result2', result2);
          setAccountDetails(result2);
          setshowSpinner(false);
      } catch (err: any) {
        setshowSpinner(false);
        logError(err, "");
      }
    } 
    setTimeout(() => {
      fetchData();
    }, 5000)
     
  }, [location, name]);

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        
        <span>
              <>
              <Card
                sx={{
                  width: "100%",
                  marginTop: "60px",
                  boxShadow: 0,
                  borderRadius: 0,
                }}

              >
                <CardContent className={classes.centerCardContent}>
                  <div className={classes.cateogryIcon}>
                    <Typography variant="button">
                      <img
                        src={security}
                        alt="success"
                        height={100}
                        width={100}
                        color="green"
                      />
                    </Typography>
                  </div>
                </CardContent>
                <CardContent className={classes.centerCardContent}>
                  <Typography component="div" variant="h3">
                    Connected accounts
                  </Typography>
                </CardContent>
                <CardContent className={classes.progressBar}>
                  <CustomizedProgressBars route={location.pathname} />
                </CardContent>
              </Card>
              <div className={classes.gridClass}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  justifyItems="center"
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        minWidth: 275,
                        marginBottom: 1.25,
                        boxShadow: 0,
                        borderRadius: 0,
                      }}
                    >
                      <CardContent>
                        <section className={styles.container}>
                          <Typography component="div" variant="body1">
                            Connecting your checking, savings, and credit cards
                            accounts allows Aver Money to create a more accurate
                            cashflow picture customized for you.
                          </Typography>
                        </section>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="h3"
                      sx={{ mb: 1.5 }}
                      color="text.secondary"
                    >
                      Connected Account's:
                    </Typography>
                    <Card sx={{ minWidth: 275 }}></Card>
                    {accountDetails.map((eachAccount, index) => {
                      return(
                        <div key={index}>
                        <Card
                          sx={{
                            minWidth: 275,
                            marginBottom: 1.25,
                            boxShadow: 0,
                            borderRadius: 0,
                            border: 1,
                            borderColor: "#DDD8D1",
                          }}
                          
                          onClick={() => {
                            deleteUIAccount(eachAccount);
                          }}
                        >
                          <CardContent>
                            {/* <section className={styles.container}>
                                  <div className={styles.typography}> */}
                            <Typography variant="h4">{ eachAccount.plaid_account_official_name || eachAccount.plaid_account_name}</Typography>
                            {/* </div>
                                </section> */}
                            <MinusCircle color="grey" className={classes.icon} />
                          </CardContent>
                        </Card>
                      </div>
                      )
                    })}

                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        {/* <section className={styles.container}>
                                <div className={styles.typography}> */}
                        {/* <Link href="#" variant="body1">
                          Add another account
                        </Link> */}
                        <PlaidLink displayLink="true"/>
                        {/* </div>
                              </section> */}
                      </CardContent>
                    </Card>

                  </Grid>
                </Grid>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  sx={{
                    backgroundColor: "#007AFF",
                    borderRadius: "100px",
                    width: "300px",
                    height: "62px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                  size="large"
                  variant="contained"
                  onClick={() => {
                    // openModal(e);
                    handleContinue()
                  }}
                >
                  Done
                </Button>
              </div>
            </>
        </span>
      )}
    </>
  );
};

export default AccountSummary;
