
import { createSlice } from "@reduxjs/toolkit";
import Editbudget, { EditBudgetAction, SetEditBudget } from "../../types/redux/editbudget";

const initialState: Editbudget = {
  lvl1: "",
  lvl2: "",
  stream_type: '',
  budget: 0
};


export const editbudgetSlice = createSlice({
  name: SetEditBudget,
  initialState: initialState,
  reducers: {
    setEditbudget: (state, action: EditBudgetAction) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.lvl1 = action.payload.lvl1;
      state.lvl2 = action.payload.lvl2;
      state.stream_type = action.payload.stream_type;
      state.budget = action.payload.budget;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setEditbudget } =
editbudgetSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default editbudgetSlice.reducer;
