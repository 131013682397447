// import * as dayjs, {Dayjs } from "dayjs"
import { Box, Card, CardContent, Divider, Grid, LinearProgress, linearProgressClasses, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import { UISubCategoryModel } from "../../interface/category";
import { RootState } from "../../redux-functionality";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customColors } from "../../theme";
import { CustomIcons } from "../../icon";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import UserConfig, {
  CategoryDetails,
  Income_ExpenseDetails,
  IncomeExpense,
  Level1GroupDetails,
  Level2GroupDetails,
  TransactionBreakdown,
} from "../../types/redux/userConfig";
import { Edit } from "react-feather";
import { setEditbudget } from "../../redux-functionality/slices/editbudgetSlice";

const GroupLevel1DetailsD2DX = () => {
  function flatten(arr) {
    return arr.reduce((acc, val) => {
      if (Array.isArray(val.children)) {
        return acc.concat(flatten(val.children));
      }
      return acc.concat(val);
    }, []);
  }

  const useStyles = makeStyles((theme) => ({
    centerCardContent: {
      display: "flex",
      justifyContent: "center",
    },
    secondCardContent: {
      backgroundColor: customColors.secondary.beige,
    },
    floatContainer: {
      // marginBottom: '10px',
      paddingBottom: "10px",
      overflow:"auto"
    },
    floatChild1: {
      width: 'fit-content',
      float: "left",
      // padding: '10px',
    },
    typography: {
      float: 'right',
      // paddingRight: '10px',
      marginTop: '-2rem',
      display: 'flex'
    },
    floatChild2: {
      // width: '50%',
      float: "right",
      // marginLeft: '3px'
      // padding: '10px',
    },
    buttonCard: {
      // width: "80%",
      // textAlign: "left",
      // marginLeft: "18%",
      margin: "0 auto"
    },
    transactionCard: {
      // marginLeft: '17%',
      // marginRight: '17%'
      width: "65%",
      textAlign: "left",
      marginLeft: "18%",
      [theme.breakpoints.down("md")]: {
        width: "95%",
        marginLeft: "3%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        marginLeft: "5%",
      },
    },
    progressBar: {
      marginRight: "10%",
      marginLeft: "10%"
    }
  }));
  const UnderBudgetProgressbar = styled(LinearProgress)(() => ({
    height: 10,
    width: "100%",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "lightgrey"
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#308fe8",
    },
  }));
  const OverBudgetProgressbar = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: "100%",
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: customColors.graphColor.spend
    },
  }));
  const [searchParams, _] = useSearchParams();
  const [flow, setFlow] = useState('');
  const [graphData, setgraphData] = useState<Array<any>>([]);
  const [alwaysShow, ] = useState<boolean>(true);
  const [merchantLevelTrans, setmerchantLevelTrans ] = useState<Array<Income_ExpenseDetails | any>>([
    {
      "level_1_group": "",
      "level_2_group": "",
      "transaction_merchant_name": "",
      "computed_monthly_budget": 0,
      "computed_monthly_spend": 0,
      "user_defined_values": [{
        "user_defined_budget": 0,
        "timestamp": ""
      }],
      "modified_by_user": false,
      "grouping_value": "",
      "frequency": "",
    }
  ]);

  const [level1Trans, setlevel1Trans ] = useState<Array<Income_ExpenseDetails | any>>([
    {
      "level_1_group": "",
      "level_2_group": "",
      "transaction_merchant_name": "",
      "computed_monthly_budget": 0,
      "computed_monthly_spend": 0,
      "user_defined_values": [{
        "user_defined_budget": 0,
        "timestamp": ""
      }],
      "modified_by_user": false,
      "grouping_value": "",
      "frequency": "",
    }
  ]);
  const [agglvl1sum, setagglvl1sum] = useState(0);
  const [level2details, setlevel2details] = useState([
    {"computed_monthly_budget": 0,
    "frequency": "",
    "grouping_value": "",
    "level_2_group": "",
    "level_2_group_merchant_details": [{
      "computed_monthly_budget": 0,
      "frequency": "",
      "grouping_value": "",
      "transaction_merchant_name": ""
    }]}
  ])
   const [transactionDetails, setTransactionDetails] = useState({
    
   });
   const [userDefinedBudget, setuserDefinedBudget] = useState(0);
   const [modifiedbyUser, setmodifiedByUser] = useState(false);
  const [legend, setLegend] = useState<string>("Monthly Average");
  const [chartColors,] = useState<any>([{name: "Total Spend", color: customColors.graphColor.income}, {name: "average", color: 'red'}, {name: "forecast", color: customColors.graphColor.forcast}])
  const groupLevel1: Income_ExpenseDetails = useSelector(
    (state: RootState) => state.groupLevel1
  );
  const userConfig: UserConfig = useSelector(
    (state: RootState) => state.userConfig
  );


  let navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  // const chartColors = [{name: "others", color: "40ee86"}, {name: "meat", color: "#67d6c0"}, {name: "pasta", color: "#127197"}];
  const renderLegend = () => {
    // const { payload } = chartColors;
    return (
      <div style={{marginLeft: '80px'}}>
        {
          chartColors.map((entry, index) => (
            <span style={{marginRight: '10px'}} key={`item-${index}`}><div style={{display:'inline-block',width:'12px',height:'12px',backgroundColor:entry.color, marginRight: '5px'}}></div>{entry.name}</span>
          ))
        }
      </div>
    );
  }

  const Icon = (props) => {
    const { iconName, size, color } = props;
    let object = CustomIcons.Default;
    if (iconName.length > 0) {
      const string = iconName;
      object =
        CustomIcons[string] === undefined
          ? CustomIcons.Default
          : CustomIcons[string];
    }
    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color}}>{icon}</div>;
    // return <embed src={icon} aria-label="category Image" type="image/svg+xml"></embed>;
  };

  const setMainLevelDetails = async (
    type: string, lvl1 = "", lvl2: string = '', budget: number| any = 0
    ) => {
      console.log('Flow', flow);
      if (type === "expense") {
        console.log('lvl1',lvl1, 'lvl2',lvl2);
        dispatch(setEditbudget({
          lvl1, lvl2, stream_type: type, budget
        }));
        navigate({
          pathname: `/editbudget`,
          search: `?exp=d2dx&flow=${flow}`
        });
      } else if(type === 'paycheck'){
        console.log('lvl1',lvl1, 'lvl2',lvl2);
        dispatch(setEditbudget({
          lvl1, lvl2, stream_type: type, budget
        }));
        navigate({
          pathname: `/editbudget`,
          search: `?exp=d2dx&flow=${flow}`
        });
      } else {
        console.log('lvl1',lvl1, 'lvl2',lvl2);
        dispatch(setEditbudget({
          lvl1, lvl2, stream_type: type, budget
        }));
        navigate({
          pathname: `/editbudget`,
          search: `?exp=d2dx&flow=${flow}`
        });
      }
    };
    
  const getDetails = async (subcat: UISubCategoryModel) => {
    navigate({
      pathname: `/transactiondetails`,
      // search: `?q=${queryParamter}&type=${type}`,
    });
  };

  interface responseType {
    "Level 1 - Group": string;
    "Level 2 - Prism Category": string;
    grouping_value: string;
    id: number;
    transaction_account_id: string;
    transaction_amount: string;
    transaction_date: string;
    transaction_merchant_name: string;
    username: string;
    recurr_stream_type: string;
  }
  useEffect(() => {
    async function fetchData() {
      let expense_array : CategoryDetails[]| any;
      const qflow = searchParams.get('flow') || '';
      setFlow(qflow);
      console.log('qflow', qflow);
      console.log('D2DX USERCONFIG', userConfig);
      if(qflow === 'paycheck') {
        expense_array = userConfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome.paycheck?.category_summary;
        });
      } else {
        expense_array = userConfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome.expenses?.category_summary;
        });
      }
      console.log('expense_array', expense_array);
      const expense_flattedArray = expense_array.flat();

      const sum_of_expenses = expense_flattedArray.reduce((acc, obj: any) => {
        return acc + obj?.computed_monthly_budget;
      }, 0);
      console.log('expense_flattedArray', expense_flattedArray);
      console.log('groupLevel1', groupLevel1);
      setagglvl1sum(parseFloat(sum_of_expenses.toFixed(2)))
      const filtered_expenseArray:Array<Level1GroupDetails> = (expense_flattedArray.map(((eachExpense:CategoryDetails| any) => {
        return eachExpense?.level_1_group_details.filter((level1: Level1GroupDetails)  => {
          
          return level1.level_1_group === groupLevel1.level_1_group
        })
      })));
     
      if(filtered_expenseArray[0]?.user_defined_values){
        setuserDefinedBudget(
          filtered_expenseArray[0]?.user_defined_values[
            filtered_expenseArray[0]?.user_defined_values.length - 1
          ].user_defined_budget
        );
      }
      
      setmodifiedByUser(filtered_expenseArray[0].modified_by_user);
      
      console.log('filtered_expenseArray', filtered_expenseArray);
      const level_2_details:Array<Level2GroupDetails| any> = (filtered_expenseArray.map(((eachExpense:Level1GroupDetails) => {
        if(eachExpense[0].level_2_group_details){
          return eachExpense[0].level_2_group_details.map(
            (level2: Level2GroupDetails) => {
              console.log("level2", level2);
              return level2;
            }
          );
        }
      })));
      console.log('level_2_details', level_2_details);
      const fileted_non_null = level_2_details[0].filter((e) => e?.level_2_group);
      
      setlevel2details(fileted_non_null);
      

      const group_level_1 = filtered_expenseArray.filter(
        (value: Level1GroupDetails) => {
         return value.computed_level_1_group_budget !== undefined &&
          value.computed_level_1_group_budget !== 0
        });

      console.log('group_level_1', group_level_1);
      setlevel1Trans(group_level_1);
      const month_summary_graph = filtered_expenseArray[0][0]["month_summary_graph"];
      setgraphData(month_summary_graph);
      const filtered_non_null = filtered_expenseArray.filter((e) => e[0]?.level_1_transactions_breakdown);
      
      const transactionBreakdown = filtered_non_null.map((level1: Level1GroupDetails)=>  level1[0].level_1_transactions_breakdown);
      
      const flattedArray_transactionBreakdown = flatten(transactionBreakdown);
      console.log('flattedArray_transactionBreakdown', flattedArray_transactionBreakdown);
      const filter_transactionBreakdown = flattedArray_transactionBreakdown.filter((eachTrans: TransactionBreakdown) => {
        return eachTrans.level_1_group === groupLevel1.level_1_group && eachTrans.level_2_group === groupLevel1.level_2_group
      });
      console.log('filter_transactionBreakdown', filter_transactionBreakdown);
      const firstFive =  flattedArray_transactionBreakdown;
      console.log('firstFive', firstFive);
      const rearrangedArray_transdetails = firstFive.reduce((acc, curr: TransactionBreakdown) => {
        const date = curr.transaction_date;
        const level_2_group = curr.level_2_group;
        const transaction_merchant_name = curr.transaction_merchant_name;
        const transaction_amount = curr.transaction_amount;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push({level_2_group, transaction_merchant_name, transaction_amount});
        return acc;
      }, {});
      var uniqueTransactionObject = {};
      for (let [key, value] of Object.entries(rearrangedArray_transdetails)) {
        var transactions:any = []
        const tempVal = value as any
        tempVal.filter((item: any) => {
          var i = transactions.findIndex((x: any) => (x.level_2_group == item.level_2_group && x.transaction_merchant_name == item.transaction_merchant_name && x.transaction_amount == item.transaction_amount));
          if(i <= -1){
            transactions.push(item);
          }
          return null;
        });
        uniqueTransactionObject[key] = transactions;
      }
      console.log("uniqueTransactionObject", uniqueTransactionObject);
      console.log('rearrangedArray', rearrangedArray_transdetails);
      console.log('groupLevel1', groupLevel1);
      setTransactionDetails(uniqueTransactionObject);
      setLegend(`Monthly Average for ${groupLevel1.level_1_group}`);
    }
    fetchData();
  }, [groupLevel1]);

  return (
    <>
      <Card
        sx={{
          width: "100%",
          marginTop: "6rem",
          boxShadow: 0,
          borderRadius: 0,
          border: 1,
          borderColor: "#DDD8D1",
        }}
      >
        <CardContent
          sx={{ paddingTop: "40px" }}
          className={classes.centerCardContent}
        >
          <span>
            <Icon
              iconName={groupLevel1.level_1_group}
              color={customColors.base.brightBlue}
            />
          </span>
        </CardContent>
        {(groupLevel1?.computed_monthly_budget) && <>
          <CardContent
          sx={{ paddingBottom: "10px" }}
          className={classes.centerCardContent}
        >
          <Typography component="div" variant="h3">
            {groupLevel1.level_1_group}
          </Typography>
        </CardContent>
        <CardContent
          sx={{ paddingBottom: "40px" }}
          className={classes.progressBar}
        >
          <Typography
            sx={{ display: "flex", justifyContent: "center", paddingBottom: 1 }}
            component="div"
            variant="body1"
          >
            $
            {(
              modifiedbyUser
              ? Math.abs(Math.abs(userDefinedBudget)  - Math.abs(groupLevel1.computed_monthly_spend || 0))
              :  Math.abs(Math.abs(groupLevel1.computed_monthly_budget) -
              Math.abs(groupLevel1.computed_monthly_spend || 0))
            ).toFixed(2)}{" "} {(modifiedbyUser
              ? Math.abs(userDefinedBudget) > Math.abs(groupLevel1.computed_monthly_spend || 0)? "left to spend": "over the budget" 
              : (Math.abs(groupLevel1.computed_monthly_budget) > Math.abs(groupLevel1.computed_monthly_spend || 0))? "left to spend": "over the budget" )}
            
          </Typography>
          {Math.abs(groupLevel1.computed_monthly_spend || 0) >
          (modifiedbyUser
          ? userDefinedBudget
          : groupLevel1.computed_monthly_budget) ? (
            <>
            
            <OverBudgetProgressbar
              style={{ backgroundColor: customColors.secondary.orange }}
              variant="determinate"
              value={
                (modifiedbyUser
                  ? Math.abs(userDefinedBudget)
                  : Math.abs(groupLevel1.computed_monthly_budget)) /
                  Math.abs(groupLevel1.computed_monthly_spend || 0) *
                100
              }
            />
            </>
          ) : (
            <>
            
            {groupLevel1.computed_monthly_spend === 0 ? <>
              <>Under with 0</>
              <UnderBudgetProgressbar
              style={{ color: "lightgrey" }}
              variant="determinate"
              value={
                0
              }
            /></> : <>
            
            <UnderBudgetProgressbar
              style={{ color: "lightgrey" }}
              variant="determinate"
              value={
                Math.abs(
                  ((modifiedbyUser
                  ? Math.abs(userDefinedBudget)
                  : Math.abs(groupLevel1.computed_monthly_budget)) -
                  Math.abs(groupLevel1.computed_monthly_spend || 0)
                ) /
                (modifiedbyUser
                ? Math.abs(userDefinedBudget)
                : Math.abs(groupLevel1.computed_monthly_budget))) *
                100
              }
            />
            </> }

            </>
          )}
          <Card
            sx={{
              width: "100%",
              marginTop: "2rem",
              boxShadow: 0,
              borderRadius: 0,
              border: 1,
              borderColor: "#DDD8D1",
            }}
            onClick={() =>
              setMainLevelDetails(
                flow,
                groupLevel1?.level_1_group?.toString() || '' ,
                "",
                modifiedbyUser
                  ? userDefinedBudget
                  : groupLevel1?.computed_monthly_budget
              )
            }
          >
            <CardContent>
              <Typography
                sx={{ display: "flex", paddingTop: 1 }}
                component="div"
                variant="body1"
              >
                ${Math.abs((groupLevel1.computed_monthly_spend || 0)).toFixed(2)}{" "}
                {groupLevel1.modified_by_user}
                spent of{" "} ${modifiedbyUser ? userDefinedBudget:  
                Math.abs(groupLevel1.computed_monthly_budget).toFixed(2)}
                 {" "}plan
              </Typography>
              <div className={classes.typography}>
                <Edit size={18} />
              </div>
            </CardContent>
          </Card>
        </CardContent>
        </>}

      </Card>
      <Card
        sx={{
          boxShadow: 0,
          borderRadius: 0,
          border: 1,
          borderColor: "#DDD8D1",
        }}
        className={classes.secondCardContent}
      >
        <CardContent sx={{ backgroundColor: "#F9F7F3" }}>
          <Typography
            textAlign="center"
            variant="body1"
            sx={{ marginBottom: 4 }}
            component="div"
          >
            Over the last 6 months, you averaged $
            {groupLevel1.computed_monthly_budget}/month on{" "}
            {groupLevel1.level_1_group}
          </Typography>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            justifyItems="center"
            sx={{ marginTop: "5rem" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3" sx={{ mb: 1.5 }}>
                Your typical expenses
              </Typography>
              <Card
                sx={{
                  marginBottom: 2,
                  boxShadow: 0,
                  borderRadius: 0,
                  border: 1,
                  borderColor: "#DDD8D1",
                }}
              >
                {level2details.map((level2: any, index) => {
                  return (
                    <div key={index} className={classes.buttonCard}>
                      <CardContent>
                        <div className={classes.floatContainer}>
                          <div className={classes.floatChild1}>
                            <Typography variant="h3">
                              {level2?.level_2_group}
                            </Typography>
                          </div>

                          <div className={classes.floatChild2}>
                            <Typography variant="body1">
                              ${level2?.computed_monthly_budget}/Month
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                      <Divider sx={{ marginTop: 0 }} />
                      <CardContent>

                        {level2?.level_2_group_merchant_details.map(
                          (value: any, index) => {
                            return (
                              <span key={index}>
                                {value?.grouping_value ===
                                  "transaction_merchant_name" && (
                                  <>
                                   {index === 0 && (
                                      <>
                                        <Typography
                                          sx={{ marginBottom: 2 }}
                                          component="div"
                                          variant="body1"
                                        >
                                          Frequent merchant:
                                        </Typography>
                                      </>
                                    )}
                                    <div className={classes.floatContainer}>
                                      <div className={classes.floatChild1}>
                                        <Typography variant="h4">
                                          {value?.transaction_merchant_name}
                                        </Typography>
                                      </div>
                                      <div className={classes.floatChild2}>
                                        <Typography variant="body1">
                                          ${value.computed_budget}/
                                          {value.frequency}
                                        </Typography>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </span>
                            );
                          }
                        )}
                      </CardContent>
                    </div>
                  );
                })}
              </Card>
              {level1Trans.map((eachLevel1: Income_ExpenseDetails, index) => {
                return (
                  <>
                  {(eachLevel1.computed_monthly_budget) && <>
                    <Card
                    key={index}
                    sx={{
                      marginBottom: 2,
                      boxShadow: 0,
                      borderRadius: 0,
                      border: 1,
                      borderColor: "#DDD8D1",
                    }}
                  >
                    <CardContent sx={{ marginBottom: 2 }}>
                      <div className={classes.floatContainer}>
                        <div className={classes.floatChild1}>
                          <Typography variant="h3" sx={{ wordWrap: "normal" }}>
                            Other - {eachLevel1.level_1_group}
                          </Typography>
                        </div>
                        <div className={classes.floatChild2}>
                          <Typography variant="body1">
                            ${(eachLevel1?.computed_monthly_budget).toFixed(2)}/
                            Month
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                    </>}
                    </>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h3" sx={{ mb: 1.5 }}>
                Expense history & forecast
              </Typography>
              <Card
                sx={{
                  boxShadow: 0,
                  borderRadius: 0,
                  border: 1,
                  borderColor: "#DDD8D1",
                }}
              >
                <CardContent>
                  <ResponsiveContainer width="99%" aspect={1.2}>
                    <BarChart width={500} height={300} data={graphData}>
                      <ReferenceLine
                      y={800}
                        stroke="red"
                        strokeDasharray="3 3"
                        alwaysShow={alwaysShow}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month_year" />
                      <YAxis />
                      <Tooltip />
                      <Legend content={renderLegend} />
                      <Bar
                        dataKey="monthly_avg_amount"
                        stackId="a"
                        fill={customColors.graphColor.spend}
                      />
                      <Bar
                        dataKey="predicted_budget"
                        stackId="a"
                        fill={customColors.graphColor.forcast}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
              <Card
                sx={{
                  boxShadow: 0,
                  borderRadius: 0,
                  border: 1,
                  borderColor: "#DDD8D1",
                }}
              >
                <CardContent sx={{ minHeight: "50px" }}>
                  <Typography variant="h4">
                    *Based on your linked accounts
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card
        sx={{
          boxShadow: 0,
          borderRadius: 0,
          border: 1,
          borderColor: "#DDD8D1",
        }}
      >
        <div className={classes.transactionCard}>
          <Typography sx={{ marginTop: 2 }} variant="h3">
            Transactions
          </Typography>
          <CardContent sx={{ padding: 2, paddingLeft: 0 }}>
            {Object.keys(transactionDetails).map((key, index) => {
              return (
                <span key={index}>
                  <Box sx={{ backgroundColor: "#F9F7F3", padding: 2 }}>
                    <Typography component="div" variant="body1">
                      Date: {key}
                    </Typography>
                  </Box>

                  {Object.values(transactionDetails[key]).map(
                    (value: any, index) => {
                      return (
                        <span key={index}>
                          <div
                            style={{ paddingTop: "10px" }}
                            className={classes.floatContainer}
                          >
                            <div className={classes.floatChild1}>
                              <Typography
                                component="div"
                                variant="body1"
                                sx={{ paddingLeft: 2 }}
                              >
                                {value?.transaction_merchant_name}
                              </Typography>
                              <Typography
                                component="div"
                                variant="body2"
                                sx={{ paddingLeft: 2 }}
                              >
                                {value.level_2_group}
                              </Typography>
                            </div>
                            <div className={classes.floatChild2}>
                              ${Math.abs(value.transaction_amount)}
                            </div>
                          </div>
                          <Divider sx={{ marginTop: 5 }} />
                        </span>
                      );
                    }
                  )}
                </span>
              );
            })}
          </CardContent>
        </div>
      </Card>
    </>
  );
};

export default GroupLevel1DetailsD2DX;
