interface initialTransactionDetailsReqState {
  mainCategory: string;
  accountId: string;
  mainCategoryAmt: number;
  subcategory_amount: number;
  subcategory_frequency: string;
  subcategory_listItemName: string;
  lvl1_grp: string;
  lvl2_grp: string;
  merchant_name: string;
  grouping_value: string;
  recurr_stream_type: string;
}

const PrepareTransactionDetails: string = "prepareTransactionDetails";

export interface TransactionDetailsReqAction {
  type: String;
  payload: {
    mainCategory: string;
    accountId: string;
    mainCategoryAmt: number;
    subcategory_amount: number;
    subcategory_frequency: string;
    subcategory_listItemName: string;
    lvl1_grp: string;
    lvl2_grp: string;
    merchant_name: string;
    grouping_value: string;
    recurr_stream_type: string;
  };
}

export default initialTransactionDetailsReqState;

export { PrepareTransactionDetails };
