import { createSlice } from "@reduxjs/toolkit";
import initialUserDefinedData, { SetDateAction, UserSetDataAction } from "../../types/redux/userDefinedData";

const initialDate: initialUserDefinedData = {
    ao_day: 0,
    ao_month: 0,
    ao_year: 0,
    as_of_amount: 0,
    sd_day: 0,
    sd_month: 0,
    sd_year: 0,
    starting_amount: 0
};

export const userDefinedDataSlice = createSlice({
    name: UserSetDataAction,
    initialState: initialDate,
    reducers: { 
        setUserDefinedData: (state, action: SetDateAction) => {
        state.ao_day = action.payload.ao_day
        state.ao_month = action.payload.ao_month
        state.ao_year = action.payload.ao_year
        state.as_of_amount = action.payload.as_of_amount
        state.sd_day = action.payload.sd_day
        state.sd_month = action.payload.sd_month
        state.sd_year = action.payload.sd_year
        state.starting_amount = action.payload.starting_amount
    }
},
});

// Action creators are generated for each case reducer function
export const { setUserDefinedData } =
userDefinedDataSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default userDefinedDataSlice.reducer;