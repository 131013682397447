import { useContext } from "react";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";

import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

import PlaidLink from "../Link";
import Context from "../../Context";

import styles from "./index.module.scss";
import Status from "../Status";

import { RootState } from "../../redux-functionality";
import { useSelector } from "react-redux";
// import { getTransactions } from "../../backend/transaction";

const Header = () => {
  const { linkToken, linkSuccess, isItemAccess, backend, linkTokenError } =
    useContext(Context);
  let navigate = useNavigate();
  const name: string = useSelector((state: RootState) => state.user.email);

  return (
    <div className={styles.grid}>
      <Status />
      <h3 className={styles.title}>
        {name} Welcome to your dscout Live Mission!
      </h3>

      {!linkSuccess ? (
        <>
          {/* <h3 className={styles.subtitle}></h3> */}
          <p className={styles.introPar}>
            Progressive Innovation, Co. (“We” or “Us”) is requesting you to link
            your bank account(s) through Plaid so that certain information
            related to your account(s) can be shared with Us.
            <a href="https://plaid.com/"> Plaid </a>will connect to your bank
            using secure credentials to provide account information to us
          </p>
          <ul>
            <p className={styles.pNegativeMargin}>
              Things to know:
              <li>
                When you link your account(s) with Plaid, your credentials are
                not shared with Us so We will not receive your login or
                password.
              </li>
              <li>
                At the end of the three-week study period (“Study Period”), We
                will automatically unlink your bank account without any further
                action required from you. Once unlinked, we will not receive any
                further information regarding your account.
              </li>
              <li>
                You only need to connect your account(s) once and the connection
                will remain open throughout the Study Period.
              </li>
            </p>
          </ul>
          <ul>
            <p className={styles.pNegativeMargin}>
              Terms
              <li>
                While your account(s) remains linked, you agree that We may
                obtain current and historical information about the financial
                account(s) you link through Plaid, now and throughout the Study
                Period, including, without limit, the type of the account(s),
                the available balance, and transaction information. In
                accordance with your Participation Agreement, this information
                may be used for research and development purposes and in product
                build efforts.
              </li>
            </p>
          </ul>
          <p className={styles.pNegativeMargin}>
            By clicking CONNECT BANK WITH PLAID you agree to the Terms stated
            herein to share account data with Progressive Innovation, Co. by
            linking selected financial account(s) through Plaid.
          </p>
          <p className={styles.pNegativeMargin}>
            Carefully read Plaid's Privacy Policy to learn how Plaid uses your
            bank account credentials and other information it collects when you
            link your accounts.
          </p>
          {/* message if backend is not running and there is no link token */}
          {!backend ? (
            <Callout warning>
              Unable to fetch link_token: please make sure your backend server
              is running and that your .env file has been configured with your
              <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
            </Callout>
          ) : /* message if backend is running and there is no link token */
          linkToken == null && backend ? (
            <Callout warning>
              <div>
                Unable to fetch link_token: please make sure your backend server
                is running and that your .env file has been configured
                correctly.
              </div>
              <div>
                Error Code: <code>{linkTokenError.error_code}</code>
              </div>
              <div>
                Error Type: <code>{linkTokenError.error_type}</code>{" "}
              </div>
              <div>Error Message: {linkTokenError.error_message}</div>
            </Callout>
          ) : linkToken === "" ? (
            <div className={styles.linkButton}>
              <Button large disabled>
                Loading...
              </Button>
            </div>
          ) : (
            <span>
              <div className={styles.linkButton}>
                <PlaidLink />
              </div>
              <div>
                <Link
                  onClick={() => {
                    navigate(`/welcome`);
                  }}
                  underline="hover"
                >
                  {" "}
                  Go to Welcome
                </Link>
              </div>
            </span>
          )}
        </>
      ) : (
        <>
          {isItemAccess ? (
            <span>
              <h4>
                Click on button below to link another account or Click Continue
                to see your transaction details
              </h4>
              <PlaidLink />
              <Link
                onClick={() => {
                  navigate(`/transactions`);
                }}
                underline="hover"
              >
                {"Continue to my transaction details"}
              </Link>
            </span>
          ) : (
            <h4 className={styles.subtitle}>
              <Callout warning>
                Unable to create an item. Please check your backend server
              </Callout>
            </h4>
          )}
        </>
      )}
    </div>
  );
};

Header.displayName = "Header";

export default Header;
