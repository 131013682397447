interface PersistentStorage {
    getItem(key: string): string | null
    setItem(key: string, value: any): void
    clearAll(): void
    removeItem(key: string): void
  }
  
  class LocalStorage implements PersistentStorage {
    getItem(key: string) {
      const item = localStorage.getItem(key)
  
      if (item === null) return undefined
  
      if (item === "null") return null
      if (item === "undefined") return undefined
  
      try {
        return JSON.parse(item)
      } catch {}
  
      return item
    }
    setItem(key: string, value: any) {
      if (value === undefined) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(value))
      }
    }
    clearAll() {
        localStorage.clear();
    }
    removeItem(key: string) {
        localStorage.removeItem(key)
    }
  }
  
  class MockStorage implements PersistentStorage {
    getItem() {
      return null
    }
    setItem() {}
    clearAll(): void {}
    removeItem(key: string): void {
        
    }
  }
  
  export const persistentlocalStorage = window?.localStorage
    ? new LocalStorage()
    : new MockStorage()
    