import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { format, subMonths } from "date-fns";
import { makeStyles } from '@material-ui/core/styles';

import Spinner from "../Spinner";
// import * as response from "./output_recurr_csv.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux-functionality";
import { logError } from "../ErrorHandler/logErrorService";
import { persistentlocalStorage } from "../../PersistStorage/persistlocalstorage";
import { CustomIcons } from "../../icon";
import React from "react";
import { Edit } from "react-feather";
import { customColors } from "../../theme";
import UserConfig, { IncomeExpense, Level1GroupDetails } from "../../types/redux/userConfig";
import { setEditbudget } from "../../redux-functionality/slices/editbudgetSlice";
import CustomizedProgressBars from "../CustomerProgress";

const SetBudget = () => {
  interface TransactionHistory {
    expense: number;
    income: number;
    month_year: String;
  }
    const userConfig: UserConfig = useSelector(
    (state: RootState) => state.userConfig
  );
  const useStyles = makeStyles((theme) => ({
    title: {
      paddingTop: '100px',
      paddingLeft: '250px',
      [theme.breakpoints.down("md")]: {
        paddingLeft: "5px",
      }
    },
    progressBar: {
      marginLeft: '17%',
      marginRight: '17%',
      [theme.breakpoints.down("md")]: {
        marginRight: "0",
        marginLeft: "0",
      },
    },
    container: {
      display: 'flex',
      width: '99%',
      // [theme.breakpoints.down("lg")]: {
      //   width: '61%',
      // },
    },
    typography: {
      float: 'right',
      // paddingRight: '10px',
      paddingTop: '5px',
      display: 'flex'
    },
    cateogryIcon: {
      width: '10%',    
      float: 'left',
      marginTop: '2%',
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
      }
    },
    buttonContainer: {
      padding: "24px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center"
    }
  }));
  const [deposit, setDeposit] = useState<Array< IncomeExpense | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const Icon = props => {
    const { iconName, size, color } = props;
    let  object = CustomIcons.Default;
    console.log('IconName', iconName, 'Object', object);
    if(iconName?.length > 0) {
    const string = iconName;
    object = CustomIcons[string] === undefined ? CustomIcons.Default : CustomIcons[string];
    }

    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  };
  const [searchParams, _] = useSearchParams();
  const classes = useStyles();
  let navigate = useNavigate();
  const userDefinedDate: any = useSelector(
    (state: RootState) => state.userDefinedData
  );
  const name: string = useSelector((state: RootState) => state.user.email);

  const [expense, setExpense] = useState<Array< IncomeExpense | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);

  const [income, setIncome] = useState([
    {
      paychecks: [],
      otherDeposits: []
    }
  ]) as any;
  const [month, setMonth] = useState("");
  const [showSpinner, setshowSpinner] = useState(true);
  const [aggPaycheck, setaggPaycheck] = useState(0);

  const [aggDeposit, setaggDeposit] = useState(0);
  const [aggExpense, setaggExpense] = useState(0);
  const [, setsdDate] = useState({ day: 0, month: 0, year: 0 });
  const [transactionHistory, setTransactionHistory] = useState<
    Array<TransactionHistory>
  >([]);
  const dispatch = useDispatch();

  const updateUserConfig  = async () => {
    
  }
  const setMainLevelDetails = async (
  type: string, lvl1: string = '', lvl2: string = '', budget: number = 0
  ) => {
    if (type === "expense") {
      console.log('lvl1',lvl1, 'lvl2',lvl2);
      dispatch(setEditbudget({
        lvl1, lvl2, stream_type: type, budget
      }));
      navigate({
        pathname: `/editbudget`,
      });
    } else if(type === 'paycheck'){
      console.log('lvl1',lvl1, 'lvl2',lvl2);
      dispatch(setEditbudget({
        lvl1, lvl2, stream_type: type, budget
      }));
      navigate({
        pathname: `/editbudget`,
      });
    } else {
      console.log('lvl1',lvl1, 'lvl2',lvl2);
      dispatch(setEditbudget({
        lvl1, lvl2, stream_type: type, budget
      }));
      navigate({
        pathname: `/editbudget`,
      });
    }
  };

  const location = useLocation();
  useEffect(() => {
    async function fetchData() {

      const valueFromStorage_expense_budget =
        persistentlocalStorage.getItem("expenseBudget");
      const currentMonth = format(
        new Date(
          userDefinedDate.ao_year,
          userDefinedDate.ao_month - 1,
          userDefinedDate.ao_day
        ),
        "MMMM"
      );
      setMonth(currentMonth);
      
      const sdDate = subMonths(
        new Date(
          userDefinedDate.ao_year,
          userDefinedDate.ao_month - 1,
          userDefinedDate.ao_day
        ),
        3
      );
      setsdDate({
        day: sdDate.getDate(),
        month: sdDate.getMonth() + 1,
        year: sdDate.getFullYear(),
      });
      try {
        const paycheck_deposits_array = userConfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome?.paycheck?.category_summary.level_1_group_details
        });
        // const other_deposits_array = userConfig['income_expense'].map((eachIncome: IncomeExpense) => {
        //   return eachIncome.paycheck_deposits?.other_deposits?.category_summary.level_1_group_details
        // });
        const paycheck_flattedArray = paycheck_deposits_array?.flat();
        setDeposit(paycheck_flattedArray || []);
        const expense_array = userConfig['income_expense']?.map((eachIncome: IncomeExpense) => {
          return eachIncome.expenses?.category_summary.level_1_group_details;
        });
        const expense_flattedArray = expense_array?.flat();
        console.log('expense_flattedArray', expense_flattedArray);
        setExpense(expense_flattedArray || []);
        // const other_deposits_flattedArray = other_deposits_array.flat();
        // const sum_of_other_deposits = other_deposits_flattedArray.reduce((acc, obj: any) => {
        //   let sum = obj.modified_by_user ? obj.user_defined_values[obj.user_defined_values.length -1].user_defined_budget : obj?.computed_monthly_budget
        //   return acc + sum;
        // }, 0);
        const sum_of_expense = expense_flattedArray?.reduce((acc, obj: any) => {
          return acc + obj?.computed_monthly_budget;
        }, 0);

        const sum_of_paycheck = paycheck_flattedArray?.reduce((acc, obj: any) => {
         let sum = obj.modified_by_user ? obj.user_defined_values[obj.user_defined_values.length -1].user_defined_budget : obj?.computed_monthly_budget
          return acc + sum;
        }, 0)
        setaggPaycheck(sum_of_paycheck || 0);
        // setaggDeposit(parseFloat(sum_of_other_deposits.toFixed(2)));
        // setaggExpense(parseFloat(sum_of_expense?.toFixed(2) || 0);
        setshowSpinner(false);
      } catch (err: any) {
        setshowSpinner(false);
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div id="mainDiv">
      <div>
        {showSpinner ? (
          <Spinner />
        ) : (
          <>
            <Card sx={{ minWidth: 275, boxShadow: 0, borderRadius: 0 }}>
              <Typography className={classes.title} variant="h2">
                {month} plan
              </Typography>
              <CardContent className={classes.progressBar}>
                <CustomizedProgressBars route={location.pathname} />
              </CardContent>
              <CardContent
                className={classes.progressBar}
                sx={{
                  minWidth: 275,
                  background: "#F9F7F3",
                  alignItems: "left",
                }}
              >
              </CardContent>
            </Card>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              justifyItems="center"
              sx={{ marginTop: "2rem" }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Deposits
                </Typography>
                {deposit.map((eachDeposit, index) => {
                  return (
                    <div key={index}>
                      <Card
                       sx={{
                        minWidth: 275,
                        marginBottom: 1.25,
                        boxShadow: 0,
                        borderRadius: 0,
                        border: 1,
                        borderColor: "#DDD8D1",
                      }}
                      onClick={() =>
                        setMainLevelDetails(
                          "paycheck",
                          eachDeposit.level_1_group || "",
                          "",
                          eachDeposit.modified_by_user
                            ? eachDeposit.user_defined_values
                              ? eachDeposit.user_defined_values[
                                eachDeposit.user_defined_values.length - 1
                                ].user_defined_budget
                              : 0
                            : eachDeposit.computed_monthly_budget || 0
                        )
                      }
                      >
                        <CardContent>
                        <div className={classes.container}>
                            <div className={classes.container}>
                              <Typography variant="button">
                                <Icon
                                  iconName={eachDeposit.level_1_group}
                                  color="green"
                                />
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 600, paddingLeft: 1 }}
                                className={classes.typography}
                                component="div"
                                variant="h4"
                              >
                                {eachDeposit.level_1_group}
                              </Typography>
                            </div>
                            <div className={classes.typography}>
                              <Typography
                                component="span"
                                variant="body1"
                                color="text.secondary"
                                sx={{ marginRight: 1 }}
                              >
                                $
                                {eachDeposit.modified_by_user
                                  ? eachDeposit.user_defined_values
                                    ? eachDeposit.user_defined_values[
                                      eachDeposit.user_defined_values.length -
                                          1
                                      ].user_defined_budget
                                    : 0
                                  : eachDeposit.computed_monthly_budget || 0}
                              </Typography>
                              <Edit size={18} />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Expenses
                </Typography>
                  {expense.map((eachExpense: Level1GroupDetails, index) => {
                    return (
                      <div key={index}>
                        <Card
                          sx={{
                            minWidth: 275,
                            marginBottom: 1.25,
                            boxShadow: 0,
                            borderRadius: 0,
                            border: 1,
                            borderColor: "#DDD8D1",
                          }}
                          onClick={() =>
                            setMainLevelDetails(
                              "expense",
                              eachExpense.level_1_group || "",
                              "",
                              eachExpense.modified_by_user
                                ? eachExpense.user_defined_values
                                  ? eachExpense.user_defined_values[
                                      eachExpense.user_defined_values.length - 1
                                    ].user_defined_budget
                                  : 0
                                : eachExpense.computed_monthly_budget || 0
                            )
                          }
                        >
                          <CardContent>
                            <div className={classes.container}>
                              <div className={classes.container}>
                                <Typography variant="button">
                                  <Icon
                                    iconName={eachExpense.level_1_group}
                                    color={customColors.base.brightBlue}
                                  />
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: 600, paddingLeft: 1 }}
                                  className={classes.typography}
                                  component="div"
                                  variant="h4"
                                >
                                  {eachExpense.level_1_group}
                                </Typography>
                              </div>
                              <div className={classes.typography}>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="text.secondary"
                                  sx={{ marginRight: 1 }}
                                >
                                  $
                                  {eachExpense.modified_by_user
                                    ? eachExpense.user_defined_values
                                      ? (eachExpense.user_defined_values[
                                          eachExpense.user_defined_values.length -
                                            1
                                        ].user_defined_budget)?.toFixed(2)
                                      : 0
                                    : (eachExpense.computed_monthly_budget)?.toFixed(2) || 0}
                                </Typography>
                                <Edit size={18} />
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                sx={{
                  backgroundColor: "#007AFF",
                  borderRadius: "100px",
                  width: "300px",
                  height: "62px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
                size="large"
                variant="contained"
                onClick={(e) => {
                  // openModal(e);
                  navigate('/allset')
                }}
              >
                Save my plan
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SetBudget;
