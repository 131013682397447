// import * as dayjs, {Dayjs } from "dayjs"
import { Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Spinner from "../Spinner";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { customColors } from "../../theme";
import { CustomIcons } from "../../icon";
import React from "react";
import { RootState } from "../../redux-functionality";
import UserConfig, {
  HighLevelMerchantDetails,
  Income_ExpenseDetails,
  Level1GroupDetails,
  Level2GroupDetails,
  TransactionBreakdown,
} from "../../types/redux/userConfig";
import { logError } from "../ErrorHandler/logErrorService";

const IncomeDetails = () => {
  const userConfig: UserConfig = useSelector(
    (state: RootState) => state.userConfig
  );

  function flatten(arr) {
    return arr.reduce((acc, val) => {
      if (Array.isArray(val.children)) {
        return acc.concat(flatten(val.children));
      }
      return acc.concat(val);
    }, []);
  }
    const useStyles = makeStyles((theme) => ({
      centerCardContent: {
        display: "flex",
        justifyContent: "center"
      },
      secondCardContent: {
        backgroundColor: customColors.secondary.beige,
      },
      floatContainer: {
        // marginBottom: '10px',
        paddingBottom: "20px",
      },
      floatChild1: {
        // width: '50%',
        float: "left",
        // padding: '10px',
      },
      floatChild2: {
        // width: '50%',
        float: "right",
        // marginLeft: '3px'
        // padding: '10px',
      },
      buttonCard: {
        width: "65%",
        textAlign: "left",
        marginLeft: "18%",
        [theme.breakpoints.down("md")]: {
          width: "95%",
          marginLeft: "3%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "95%",
          marginLeft: "5%",
        },
      },
      container: {
        padding: "24px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
      },
    }));
    const data = [
      {
        name: 'Aug `22',
        pred_amount: 0,
        hist_spend: 2400,
        amt: 2400,
      },
      {
        name: 'Sept `22',
        pred_amount: 0,
        hist_spend: 1398,
        amt: 2210,
      },
      {
        name: 'Oct `22',
        pred_amount: 0,
        hist_spend: 9800,
        amt: 2290,
      },
      {
        name: 'Nov `22',
        pred_amount: 0,
        hist_spend: 3908,
        amt: 2000,
      },
      {
        name: 'Dec `22',
        pred_amount: 0,
        hist_spend: 4800,
        amt: 2181,
      },
      {
        name: 'Jan `22',
        pred_amount: 0,
        hist_spend: 3800,
        amt: 2500,
      },
      {
        name: 'Feb `22',
        pred_amount: 3490,
        hist_spend: 4300,
        amt: 2100,
      },
    ];
  const [showSpinner, setshowSpinner] = useState(true);
  const [displayPaycheck, setdisplayPaycheck] = useState(false);
  const [, setOtherDeposits] = useState(false);
  const [graphData, setgraphData] = useState<Array<any>>([]);
  const [alwaysShow, ] = useState<boolean>(true)
  const [paycheck, setpaycheck] = useState<Array<Income_ExpenseDetails | any>> ([
    {
      "level_1_group": "",
      "level_2_group": "",
      "transaction_merchant_name": "",
      "computed_monthly_budget": 0,
      "computed_monthly_spend": 0,
      "user_defined_values": [{
        "user_defined_budget": 0,
        "timestamp": ""
      }],
      "modified_by_user": false,
      "grouping_value": "",
      "frequency": "",
    }
  ]);
const [transactionDetails, setTransactionDetails] = useState({
 "00/00/00" : [{level_2_group: '', transaction_merchant_name: '', transaction_amount: ''}]
});
const [aggPaycheck, setaggPaycheck] = useState(0);
  const [chartColors,] = useState<any>([{name: "Total Spend", color: customColors.graphColor.income}, {name: "average", color: 'red'}, {name: "forecast", color: customColors.graphColor.forcast}])
  let paycheck_breakdownarray: Array<TransactionBreakdown> = [
    {
      merchant_name: "",
      frequency: "",
      lvl1_grp: "",
      lvl2_grp: "",
      transaction_category: "",
      transaction_date: "",
      transaction_amount: 0,
      transaction_id: "",
    },
  ] as any;


const classes = useStyles();
    const renderLegend = () => {

    return (
      <div style={{marginLeft: '80px'}}>
        {
          chartColors.map((entry, index) => (
            <span style={{marginRight: '10px'}} key={`item-${index}`}><div style={{display:'inline-block',width:'12px',height:'12px',backgroundColor:entry.color, marginRight: '5px'}}></div>{entry.name}</span>
          ))
        }
      </div>
    );
  }

  const Icon = (props) => {
    const { iconName, size, color } = props;
    let object = CustomIcons.Default;
    if (iconName.length > 0) {
      const string = iconName;
      object =
        CustomIcons[string] === undefined
          ? CustomIcons.Default
          : CustomIcons[string];
    }

    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color}}>{icon}</div>;
    // return <embed src={icon} aria-label="category Image" type="image/svg+xml"></embed>;
  };

  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      try {
        const queryParams = new URLSearchParams(location.search);
        const paramValue = queryParams.get("q");
        
        // if (paramValue === "paycheck") {
        //   setdisplayPaycheck(true);
        //   setOtherDeposits(false);
        // } else {
        //   setdisplayPaycheck(false);
        //   setOtherDeposits(true);
        // }
        //   paycheck_breakdownarray.splice(0, paycheck_breakdownarray.length);
          
        //   userConfig["income_expense"].forEach((eachIncome: IncomeExpense) => {
        //     if (
        //       eachIncome !== undefined &&
        //       eachIncome?.paycheck !== undefined
        //     ) {
        //       const paycheck_deposits_array = userConfig['income_expense'].map((eachIncome: IncomeExpense) => {
        //         if(paramValue === "paycheck") {
        //         return eachIncome?.paycheck?.category_summary.level_1_group_details
        //         } 
        //       });
        //       if(paycheck_deposits_array !== undefined){
        //       const flattedArray = paycheck_deposits_array.flat();
        //       console.log('Flatten Income', flattedArray);
        //       const sum_of_paycheck = flattedArray.reduce((acc, obj: any) => {
        //         return acc + obj?.computed_monthly_budget;
        //       }, 0)
        //       setaggPaycheck(sum_of_paycheck);
        //       setpaycheck(flattedArray);
        //       const transactionBreakdown = flatten(userConfig['income_expense'].map((eachIncome: IncomeExpense) => {
        //         if(paramValue === "paycheck") {
        //           return eachIncome?.paycheck?.category_summary.level_1_group_details.map(eachIncome => {
        //             return eachIncome.level_1_transactions_breakdown
        //           })
        //         } 
        //       }));
        //       const flattedArray_transactionBreakdown = flatten(transactionBreakdown);
        //       console.log('flattedArray_transactionBreakdown', flattedArray_transactionBreakdown);
        //       const firstFive =  flattedArray_transactionBreakdown.slice(0, 5);
        //       console.log('firstFive', firstFive);
        //       const rearrangedArray = firstFive.reduce((acc, curr: TransactionBreakdown) => {
        //         const date = curr.transaction_date;
        //         const level_2_group = curr.level_2_group;
        //         const transaction_merchant_name = curr.transaction_merchant_name;
        //         const transaction_amount = curr.transaction_amount;
        //         if (!acc[date]) {
        //           acc[date] = [];
        //         }
        //         acc[date].push({level_2_group, transaction_merchant_name, transaction_amount});
        //         return acc;
        //       }, {});
        //       console.log('rearrangedArray', rearrangedArray);
        //       setTransactionDetails(rearrangedArray);
              
        //       }
        //     }
        //   });
  
        // } else {
        //   console.log('IM IN');
        //   setOtherDeposits(true);
        // }
        setgraphData(graphData);
        setshowSpinner(false);
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    // <div id="mainDiv" className={styles.grid}>
    <div>
      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <div>
            {displayPaycheck ? (
              <Card
                sx={{
                  width: "100%",
                  marginTop: "6rem",
                  boxShadow: 0,
                  borderRadius: 0,
                  border: 1,
                  borderColor: "#DDD8D1",
                }}
              >
                <CardContent
                  sx={{ paddingTop: "40px" }}
                  className={classes.centerCardContent}
                >
                  <span>
                    <Icon
                      iconName="Paycheck"
                      color={customColors.secondary.green}
                    />
                  </span>
                </CardContent>
                <CardContent
                  sx={{ paddingBottom: "40px" }}
                  className={classes.centerCardContent}
                >
                  <Typography component="div" variant="h3">
                    Paycheck
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <Card sx={{ width: "100%", marginTop: "6rem" }}>
                <CardContent
                  sx={{ paddingTop: "40px" }}
                  className={classes.centerCardContent}
                >
                  <Typography variant="button">
                    <img
                      src={CustomIcons.OtherDesposits}
                      alt="dollarImage"
                      height={50}
                      width={50}
                      color="green"
                    />
                  </Typography>
                </CardContent>
                <CardContent
                  sx={{ paddingBottom: "40px" }}
                  className={classes.centerCardContent}
                >
                  <Typography component="div" variant="h3">
                    Other Deposits
                  </Typography>
                </CardContent>
              </Card>
            )}
            {displayPaycheck ? <>
              <Card
              sx={{
                boxShadow: 0,
                borderRadius: 0,
                border: 1,
                borderColor: "#DDD8D1",
              }}
              className={classes.secondCardContent}
            >
              <CardContent sx={{ backgroundColor: "#F9F7F3" }}>
                <Typography textAlign="center" variant="body1" component="div">
                  Over the last 6 months, you averaged ${aggPaycheck}/month on {displayPaycheck ? "Paycheck" : "Other Deposits"}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  justifyItems="center"
                  sx={{ marginTop: "5rem" }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h3" sx={{ mb: 0.5 }}>
                      {displayPaycheck ? "Paycheck" : "Other Deposits"}
                    </Typography>
                    <Card
                      sx={{
                        minWidth: 275,
                        marginBottom: 2,
                        boxShadow: 0,
                        borderRadius: 0,
                        border: 1,
                        borderColor: "#DDD8D1",
                      }}
                    >
                      <CardContent>
                        <div className={classes.floatContainer}>
                          <div className={classes.floatChild1}>
                            <Typography variant="h4">
                              {displayPaycheck ? "Paycheck" : "Other Deposits"}
                            </Typography>
                          </div>

                          <div className={classes.floatChild2}>
                            <Typography variant="body1">
                              {aggPaycheck.toFixed(2)}/Monthly
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                      <Divider sx={{ marginTop: 0.5 }} />
                      {paycheck.map(
                        (eachPaycheck: Level1GroupDetails, index) => {
                          return (
                            <span key={index}>
                              <CardContent>
                                {eachPaycheck.level_2_group_details &&
                                  eachPaycheck.level_2_group_details.map(
                                    (highLevelMerchant) => {
                                      return highLevelMerchant.level_2_group_merchant_details.map(
                                        (eachmerchant, index) => {
                                          
                                          return (
                                            <>
                                            {(eachmerchant && eachmerchant.grouping_value === 'transaction_merchant_name') && ( 
                                            <div
                                              key={index}
                                              className={classes.floatContainer}
                                            >
                                              <div
                                                className={classes.floatChild1}
                                              >
                                                <Typography variant="body1">
                                                  {
                                                    eachmerchant?.transaction_merchant_name
                                                  }
                                                </Typography>
                                              </div>
                                              <div
                                                className={classes.floatChild2}
                                              >
                                                <Typography variant="body1">
                                                  {eachmerchant?.computed_budget?.toFixed(
                                                    2
                                                  )}
                                                  /{eachmerchant?.frequency}
                                                </Typography>
                                              </div>
                                            </div>)}
                                            </>
                                          );
                                        }
                                      );
                                    }
                                  )}
                              </CardContent>
                              <Divider sx={{ marginTop: 2 }} />
                                    {eachPaycheck.computed_level_1_group_budget && <span>
                                      <CardContent>
                                      <div
                                              key={index}
                                              className={classes.floatContainer}
                                            >
                                              <div
                                                className={classes.floatChild1}
                                              >
                                                <Typography variant="body1">
                                                  Misc. {displayPaycheck ? "Paycheck" : "Other Deposits"}
                                                </Typography>
                                              </div>
                                              <div
                                                className={classes.floatChild2}
                                              >
                                                <Typography variant="body1">
                                                  {eachPaycheck?.computed_level_1_group_budget.toFixed(
                                                    2
                                                  )}
                                                  /Monthly
                                                </Typography>
                                              </div>
                                            </div>
                                      </CardContent>
                                    </span>}
                            </span>
                          );
                        }
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h3" sx={{ mb: 1.5 }}>
                      Expense history & forecast
                    </Typography>
                    <Card
                      sx={{
                        marginBottom: 0,
                        boxShadow: 0,
                        borderRadius: 0,
                        border: 1,
                        borderColor: "#DDD8D1",
                      }}
                    >
                      <CardContent sx={{ minWidth: 275 }}>
                        <ResponsiveContainer width="99%" aspect={1}>
                          <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <ReferenceLine
                              y={8000}
                              stroke="red"
                              strokeDasharray="3 3"
                              alwaysShow={alwaysShow}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />

                            <Legend content={renderLegend} />
                            <Bar
                              dataKey="hist_spend"
                              stackId="a"
                              fill={customColors.graphColor.spend}
                            />
                            <Bar
                              dataKey="pred_amount"
                              stackId="a"
                              fill={customColors.graphColor.forcast}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                    <Card
                      sx={{
                        marginBottom: 1.25,
                        boxShadow: 0,
                        borderRadius: 0,
                        border: 1,
                        borderColor: "#DDD8D1",
                      }}
                    >
                      <CardContent sx={{ minHeight: "50px" }}>
                        <Typography variant="h4">
                          *Based on your linked accounts
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              sx={{
                boxShadow: 0,
                borderRadius: 0,
                border: 1,
                borderColor: "#DDD8D1",
              }}
              className={classes.secondCardContent}
            >
              <div className={classes.buttonCard}>
                <Typography sx={{ marginTop: 2 }} variant="h3">
                  Transactions
                </Typography>
                <CardContent sx={{ padding: 2, paddingLeft: 0 }}>
                  {Object.keys(transactionDetails).map((key, index) => {
                    return (
                      <span key={index}>
                        <Box sx={{ backgroundColor: "#F9F7F3", padding: 2 }}>
                          <Typography component="div" variant="body1">
                            Date: {key}
                          </Typography>
                        </Box>

                        {Object.values(transactionDetails[key]).map(
                          (value: any, index) => {
                            return (
                              <span key={index}>
                                <div
                                  style={{ paddingTop: "10px" }}
                                  className={classes.floatContainer}
                                >
                                  <div className={classes.floatChild1}>
                                    <Typography
                                      component="div"
                                      variant="body1"
                                      sx={{ paddingLeft: 2 }}
                                    >
                                      {value.transaction_merchant_name}
                                    </Typography>
                                    <Typography
                                      component="div"
                                      variant="body2"
                                      sx={{ paddingLeft: 2 }}
                                    >
                                      {value.level_2_group}
                                    </Typography>
                                  </div>
                                  <div className={classes.floatChild2}>
                                    ${value.transaction_amount}
                                  </div>
                                </div>
                                <Divider sx={{ marginTop: 5 }} />
                              </span>
                            );
                          }
                        )}
                      </span>
                    );
                  })}
                </CardContent>
              </div>
            </Card></>: <>
            <Card
              sx={{
                boxShadow: 0,
                borderRadius: 0,
                border: 1,
                borderColor: "#DDD8D1",
              }}
              className={classes.secondCardContent}
            >
               <CardContent sx={{ backgroundColor: "#F9F7F3" }}>
              <Typography textAlign="center" variant="body1" component="div">
                  Over the last 6 months, you averaged ${aggPaycheck}/month on {displayPaycheck ? "Paycheck" : "Other Deposits"}
                </Typography>
                </CardContent>
            </Card>
            <Card
              sx={{
                boxShadow: 0,
                borderRadius: 0,
                border: 1,
                borderColor: "#DDD8D1",
              }}
              className={classes.secondCardContent}
            >
              {paycheck.map((eachDeposit: Level1GroupDetails, index) => {
                return (
                  <span key={index}>
                    <CardContent>
                        <div className={classes.floatContainer}>
                          <div className={classes.floatChild1}>
                            <Typography variant="h4">
                              {eachDeposit.level_1_group}
                            </Typography>
                          </div>

                          <div className={classes.floatChild2}>
                            <Typography variant="body1">
                              {eachDeposit?.computed_monthly_budget?.toFixed(2)}/Monthly
                            </Typography>
                          </div>
                        </div>
                        <Divider sx={{ marginTop: 0 }} />
                        {eachDeposit.level_2_group_details && eachDeposit.level_2_group_details.map((eachLevel2: Level2GroupDetails, index) => {
                          return (
                            <span key={index}>
                            {(eachLevel2 &&
                              eachLevel2.level_2_group_merchant_details
                                .length > 0 && !!!eachLevel2.computed_group_level_2_budget) && (
                                <Typography
                                  sx={{ marginBottom: 2 }}
                                  component="div"
                                  variant="body1"
                                >
                                  Frequent merchant: 
                                </Typography>
                              )}
                             {eachLevel2.level_2_group_merchant_details && eachLevel2.level_2_group_merchant_details.map((eachmerchant: HighLevelMerchantDetails, index) => {
                              return (
                                <span key={index}>

                                  {eachmerchant &&
                                    eachmerchant.grouping_value ===
                                      "transaction_merchant_name" && (
                                      <>
                                        <div
                                          key={index}
                                          className={classes.floatContainer}
                                        >
                                          <div className={classes.floatChild1}>
                                            <Typography variant="body1">
                                              {
                                                eachmerchant?.transaction_merchant_name
                                              }
                                            </Typography>
                                          </div>
                                          <div className={classes.floatChild2}>
                                            <Typography variant="body1">
                                              {eachmerchant?.computed_budget?.toFixed(
                                                2
                                              )}
                                              /{eachDeposit?.frequency}
                                            </Typography>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  {eachmerchant &&
                                    eachmerchant.grouping_value ===
                                      "level_2_group" && (
                                      <>
                                        <div
                                          key={index}
                                          className={classes.floatContainer}
                                        >
                                          <div className={classes.floatChild1}>
                                            <Typography variant="body1">
                                              {eachLevel2?.level_2_group}
                                            </Typography>
                                          </div>
                                          <div className={classes.floatChild2}>
                                            <Typography variant="body1">
                                              {eachLevel2?.computed_group_level_2_budget?.toFixed(
                                                2
                                              )}
                                              /{eachDeposit?.frequency}
                                            </Typography>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                </span>
                              );
                             })}
                            </span>
                          );
                            })}

                    </CardContent>
                    <Divider sx={{ marginTop: 0.5 }} />
                  </span>
                );
              })}
            </Card>
            </>}

          </div>
        </>
      )}
    </div>
  );
};

export default IncomeDetails;
