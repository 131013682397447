import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { QuickstartProvider } from "./Context";
import reportWebVitals from "./reportWebVitals";

// This is the extra redux functionality
import store from "./redux-functionality";
import { Provider } from "react-redux";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QuickstartProvider>
        {/* <ErrorHandler> */}

          <App />
        {/* </ErrorHandler> */}
      </QuickstartProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
