import { createSlice } from "@reduxjs/toolkit";
import UserConfig, { UpdateUserConfig, UserConfigAction } from "../../types/redux/userConfig";

const initialUserConfigState: UserConfig ={
    "12_month_transaction_history": [
        {
            income: 0,
            expense: 0,
            month_year: ''
        }

    ],
    "income_expense": [
        {
            timestamp: '',
                "paycheck": {
                    "category_summary": {
                        "level_1_group_details":[{
                            "level_1_group": "Income",
                            "computed_monthly_budget" : 27553.1161904761905,
                            "computed_monthly_spend": 0,
                            "user_defined_values": [{
                                timestamp: "",
                                user_defined_budget: 0
                            }],
                            "6_month_summary_graph": [{
                                "monthly_avg_amount": 0,
                                "month_year": ""
                            }],
                            "6_month_average_graph": 0,
                            "modified_by_user": false,
                            "frequency": "monthly",
                            "grouping_value": "",
                            "level_1_transactions_breakdown":[
                                {
                                    "transaction_merchant_name" : "",
                                    "frequency" : "",
                                    "level_1_group" : "",
                                    "level_2_group" : "",
                                    "transaction_category" : "",
                                    "transaction_date" : "",
                                    "transaction_amount" : 0,
                                    "transaction_id" : ""
                                }],
                                "level_2_group_details": [{
                                    "level_2_group" : "",
                                    "frequency" : "",
                                    "grouping_value" : "",
                                    "computed_monthly_budget" :0,
                                    "level_2_group_merchant_details":[
                                        {
                                            "transaction_merchant_name" : "",
                                            "frequency" : "",
                                            "computed_monthly_budget" : 0,
                                            "grouping_value" : ""
                                        }
                                    ]
                                }]
                                
                    }]
                    },
            },
            "expenses": {
                "category_summary": {
                    "level_1_group_details":[{
                        "level_1_group": "Income",
                        "computed_monthly_budget" : 27553.1161904761905,
                        "computed_monthly_spend": 0,
                        "user_defined_values": [{
                            timestamp: "",
                            user_defined_budget: 0
                        }],
                        "modified_by_user": false,
                        "frequency": "monthly",
                        "grouping_value": "",
                        "level_1_transactions_breakdown":[
                            {
                                "transaction_merchant_name" : "",
                                "frequency" : "",
                                "level_1_group" : "",
                                "level_2_group" : "",
                                "transaction_category" : "",
                                "transaction_date" : "",
                                "transaction_amount" : 0,
                                "transaction_id" : ""
                            }],
                            "level_2_group_details": [{
                                "level_2_group" : "",
                                "frequency" : "",
                                "grouping_value" : "",
                                "computed_monthly_budget" :0,
                                "level_2_group_merchant_details":[
                                    {
                                        "transaction_merchant_name" : "",
                                        "frequency" : "",
                                        "computed_monthly_budget" : 0,
                                        "grouping_value" : ""
                                    }
                                ]
                            }]
                            
                }]
                }
            },
            "other_transactions":[{
                "level_1_group": '',
                "level_2_group": '',
                "computed_monthly_budget": 0,
                "computed_monthly_spend": 0,
                "user_defined_values":[{
                    timestamp: '',
                    "user_defined_budget": 0
                }],
                "modified_by_user": false,
                "expenses_breakdown": [{
                    "transaction_merchant_name": '', 
                    "frequency": '',
                    "level_1_group": '',
                    "level_2_group": '',
                    "transaction_category": '',
                    "transaction_date": '',
                    "transaction_amount": 0,
                    "transaction_id": ''
                }]
            }],
            "cashflow": [{
                "time_stamp": '',
                "left_over_balance": 0,
                "30_days_cashflow_predictions": [{
                    "month_year": '',
                    "user_defined_amount": 0,
                    "predicted_amount": 0 
                }]
            }]
        }
    ]
}

export const userConfigSlice = createSlice({
    name: UpdateUserConfig,
    initialState: initialUserConfigState,
    reducers: {
        updateUserConfig:(state, action: UserConfigAction) => {
            state["12_month_transaction_history"] = action.payload["12_month_transaction_history"];
            state.income_expense = action.payload.income_expense;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateUserConfig } = userConfigSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default userConfigSlice.reducer;