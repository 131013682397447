import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { logError } from "../ErrorHandler/logErrorService";
import { customColors } from "../../theme";

const useStyles = makeStyles(() => ({
  root: {
      "& .MuiLinearProgress-colorPrimary": {
          backgroundColor: `grey`,
      },
      "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: `${customColors.base.yellow}`
      },
  },
}));

const CustomizedProgressBars = (props) => {
  const classes = useStyles();
  const [percentage, setpercentage] = useState(0)
  const routesarray = ['/gettingstarted', '/accountsummary', '/reviewbudget', '/settingupbudget', '/setbudget', '/viewyourforecast', '/cashflow']
  useEffect(() => {
    async function fetchData() {
      try {
        const { route } = props;
        const position = (routesarray.findIndex(value => value === route)) + 1;
        const percent = position/routesarray.length * 100;
        setpercentage(percent);
        console.log('routesarray', routesarray, 'percent', percent, 'route', route, 'position', position)
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className={classes.root}>
        <LinearProgress
          sx={{height: 6, borderRadius: 5}}
          variant="determinate"
          value={percentage}
        />
      </div>
    </Box>
  );
};

export default CustomizedProgressBars;
