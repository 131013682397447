import { useState, useContext, useEffect } from "react";

import { AccountContext } from "../Account";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./login.css";

// import { RootState } from "../../redux-functionality";
import { useDispatch } from "react-redux";
import { setUserEmailAndAuth } from "../../redux-functionality/slices/userSlice";
import { Alert, Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";
import { customColors } from "../../theme";
const Login = () => {
  const useStyles = makeStyles((theme) => ({
    gridContainer: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    root: {
      minWidth: 200,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    progressBar: {
      marginLeft: '15%',
      marginRight: '15%',
      [theme.breakpoints.down("md")]: {
        marginRight: "0",
        marginLeft: "0",
      },
    },
    centerCardContent: {
      display: "flex",
      justifyContent: "center",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    cateogryIcon: {
      width: "10%",
      // float: "left",
      marginTop: "2%",
      [theme.breakpoints.down("md")]: {
        width: "0%",
        float: "left",
        display: "flex",
        justifyContent: "center",
      },
    },
    icon: {
      top: "-18px",
      position: "relative",
      float: "right",
    },
    backgroundColor: {
      backgroundColor: customColors.secondary.beige,
    },
    paddingTop: {
      paddingTop: "10rem",
    },
    gridClass: {
      marginTop: "4rem",
      [theme.breakpoints.down("md")]: {
        marginTop: "2rem"
      }
    },
    buttonContainer: {
      padding: "24px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
    },
    buttonStyle: {
      "MuiButton-root": {
        backgroundColor: "red",
      },
    },
    link: {
      display: 'flex',
      justifyContent: "center",
      marginTop: "1rem"
    }
  }));
  // const userEmail: string = useSelector((state: RootState) => state.user.email);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [, setPassword] = useState("");
  // const [status, setStatus] = useState(false);
  const [awsError, setawsError] = useState("");
  const [, setSpinner] = useState(false);
  const [, setAllFieldsRequiredError] = useState(false);
  const { authenticate } = useContext(AccountContext);
  const [usernameError, setusernameError] = useState(false);
  const [passwordError, setpasswordError] = useState(false);
  const theme = createTheme();
  // const classes = useStyles();
  let navigate = useNavigate();
  useEffect(() => {
    // if (email !== '' && password !== '') {
    //     getSession().then((session: any) => {
    //         console.log("Session ", session);
    //         console.log("Status ", status);
    //         setStatus(true);
    //     })
    // }
  });

  const onSubmit =  async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setawsError("");
    setSpinner(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    if(data.get('email') === ""){
      setusernameError(true);
    } else{
      setusernameError(false);
    }
    if(data.get('password') === "") {
      setpasswordError(true);
    } else{
      setpasswordError(false);
    }
    if (data.get('email') === "" || data.get('password') === "") {
      // setAllFieldsRequiredError(true);
      // setSpinner(false);
    } else {
      const emailAddress = data.get('email')?.toString() || "";
      const userPassword = data.get('password')?.toString() || "";
      setAllFieldsRequiredError(false);
      setEmail(emailAddress);
      setPassword(userPassword);
      console.log("Submitting to AWS", email);
      authenticate(data.get('email'), data.get('password'))
        .then(async (data: any) => {
          dispatch(setUserEmailAndAuth({ email: emailAddress, isAuthenticated: true }));
          // console.log("Logged In! ", data);
        })
        .catch((err: Error) => {
          setSpinner(false);
          setawsError(err.message);
          console.error("Error! ", err);
        });
    }
  };
  return (
    <>
        <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box
          sx={{
            marginTop: 13,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{fontFamily: "Poppins"}} component="div" variant="h3">
            Congratulations!
          </Typography>
          <Typography sx={{fontFamily: "Poppins", marginTop: 2 }} component="div" variant="h4">
            You've been selected to participate in the Aver Money beta program.
          </Typography>
          <Typography
            sx={{fontFamily: "Poppins", marginTop: 2 }}
            component="div"
            variant="h4"
            color={customColors.graphColor.forcast}
          >
            Are you in?
          </Typography>

          <Typography sx={{fontFamily: "Poppins", fontSize: 14, marginTop: 2 }} component="div" variant="body1">
            Create your account to get started.
          </Typography>
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          {awsError?.length > 0 && (
            <Alert severity="error">Login information doesn't match our records. Please try again.</Alert>
              )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              variant="filled"
              FormHelperTextProps={{
                style: {fontFamily: "Poppins", fontSize: "1.2rem", color: "red"}
              }}
              inputProps={{style: {fontFamily: "Poppins", fontSize: 14}}} // font size of input text
              InputLabelProps={{style: {fontFamily: "Poppins", fontSize: 14}}} // font size of input label
              helperText={usernameError ? "Please enter a valid username" : ""}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="filled"
              autoComplete="current-password"
              FormHelperTextProps={{
                style: {fontFamily: "Poppins", fontSize: "1.2rem", color: "red"}
              }}
              inputProps={{style: {fontFamily: "Poppins", fontSize: 14}}} // font size of input text
              InputLabelProps={{style: {fontFamily: "Poppins", fontSize: 14}}} // font size of input label
              helperText={passwordError ? "Please enter a valid password" : ""}
            />
            <Button
              sx={{
                backgroundColor: "#007AFF",
                borderRadius: "100px",
                // width: "300px",
                height: "62px",
                fontSize: "14px",
                fontFamily: "Poppins",
                marginTop: 2
              }}
              size="large"
              variant="contained"
              type="submit"
              fullWidth
            >
               Sign In
            </Button>

            <Grid sx={{marginTop: 3}} container>
              <Grid item xs>
                <Link sx={{fontFamily: "Poppins", fontSize: 14}} component={RouterLink} to="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
               
                <Link sx={{fontFamily: "Poppins", fontSize: 14}} component={RouterLink} to="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      </ThemeProvider>
    </>
  );
};

export default Login;
