import { createSlice } from "@reduxjs/toolkit";
import initialTransactionDetailsReqState, { PrepareTransactionDetails, TransactionDetailsReqAction } from "../../types/redux/transactionDetails";

const initialTransactionDetailsReq: initialTransactionDetailsReqState = {
  mainCategory: '',
  accountId: '',
  mainCategoryAmt: 0,
  subcategory_amount: 0,
  subcategory_frequency: '',
  subcategory_listItemName: '',
  lvl1_grp: '',
  lvl2_grp: '',
  merchant_name: '',
  grouping_value: '',
  recurr_stream_type: ''
};


export const trnasactionDetailsReqSlice = createSlice({
  name: PrepareTransactionDetails,
  initialState: initialTransactionDetailsReq,
  reducers: {
    setUpTransantionDetailsReuest: (state, action: TransactionDetailsReqAction) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.mainCategory = action.payload.mainCategory;
      state.accountId = action.payload.accountId;
      state.mainCategoryAmt = action.payload.mainCategoryAmt;
      state.subcategory_amount = action.payload.subcategory_amount;
      state.subcategory_frequency = action.payload.subcategory_frequency;
      state.subcategory_listItemName = action.payload.subcategory_listItemName;
      state.lvl1_grp = action.payload.lvl1_grp;
      state.lvl2_grp = action.payload.lvl2_grp;
      state.merchant_name = action.payload.merchant_name;
      state.grouping_value = action.payload.grouping_value;
      state.recurr_stream_type = action.payload.recurr_stream_type;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUpTransantionDetailsReuest } =
trnasactionDetailsReqSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default trnasactionDetailsReqSlice.reducer;
