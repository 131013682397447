import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Spinner from "../Spinner";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// import * as response from "./output_recurr_csv.json";
import { useDispatch, useSelector } from "react-redux";
import { logError } from "../ErrorHandler/logErrorService";
import { groupLeve1Details } from "../../redux-functionality/slices/groupLevel1Slice";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { customColors } from "../../theme";
import styles from "./index.module.scss";
import React from "react";
import { CustomIcons } from "../../icon";
import { IncomeExpense, Income_ExpenseDetails, TransactionHistory } from "../../types/redux/userConfig";
import CustomizedProgressBars from "../CustomerProgress";
import { updateUserConfig } from "../../redux-functionality/slices/userConfigSlice";
import { refreshTransactions, getItemId } from "../../backend/transaction";
import InitialState from "../../types/redux/user";
import { RootState } from "../../redux-functionality";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  root: {
    minWidth: 200,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cateogryIcon: {
    width: '10%',    
    float: 'left',
    marginTop: '2%',
    [theme.breakpoints.down("md")]: {
      marginRight: "10px",
    },
  },
  icon: {
    top: "-25px",
    position: "relative",
    float: "right"
  },
  backgroundColor: {
    backgroundColor: customColors.secondary.beige,
  },
  paddingTop: {
    paddingTop: "10rem",
  },
  buttonContainer: { 
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  buttonStyle: {
    "MuiButton-root": {
      backgroundColor: "red",
    },
  },
}));


const ReviewBudget = () => {

  let navigate = useNavigate();
  const classes = useStyles();
  const userInfo: InitialState = useSelector(
    (state: RootState) => state.user
  );
  const Icon = props => {
    const { iconName, size, color } = props;
    let  object = CustomIcons.Default;
    if(iconName.length > 0) {
    const string = iconName;
    object = CustomIcons[string] === undefined ? CustomIcons.Default : CustomIcons[string];
    }
    const icon = React.createElement(object);
    return <div style={{ fontSize: size, color: color }}>{icon}</div>;
  };
  const [deposit, setDeposit] = useState<Array< IncomeExpense | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const [expense, setExpense] = useState<Array< IncomeExpense | any>>([
    {
      computed_monthly_budget: 0,
      computed_monthly_spend: 0,
      frequency: "",
      grouping_value: "",
      level_1_group: "",
      level_2_group: "",
      modified_by_user: false,
      transaction_merchant_name: "",
      user_defined_values: [
        {
          user_defined_budget: 0,
          timestamp: "",
        },
      ],
    },
  ]);
  const [showSpinner, setshowSpinner] = useState(true);
  const [aggPaycheck, setaggPaycheck] = useState(0);
  const [aggDeposit, setaggDeposit] = useState(0);
  const [aggExpense, setaggExpense] = useState(0);
  const location = useLocation()
  const [, setsdDate] = useState({ day: 0, month: 0, year: 0 });
  const [transactionHistory, setTransactionHistory] = useState<Array<TransactionHistory>>([{month_year: "", income: 0, expense: 0}])
  const dispatch = useDispatch();

  const getMainLevelDetails = async (mainCat: Income_ExpenseDetails, flow: string) => {
    dispatch(
      groupLeve1Details({
        level_1_group: mainCat?.level_1_group || "",
        level_2_group: mainCat?.level_2_group || "",
        transaction_merchant_name: mainCat.transaction_merchant_name,
        computed_monthly_budget: mainCat.computed_monthly_budget || 0,
        computed_monthly_spend: mainCat.computed_monthly_spend || 0,
        user_defined_values: mainCat.user_defined_values || [{
          timestamp: "",
          user_defined_budget: 0,
        }],
        modified_by_user: mainCat.modified_by_user,
        grouping_value: mainCat.grouping_value || "",
        frequency: mainCat.frequency,
        flow
      })
    );
    navigate({
      pathname: `/grouplevel1Details`,
    });
  };


  useEffect(() => {
    async function fetchData() {
      try {
        const item_id = await getItemId(userInfo.email);
        const response: any = await refreshTransactions(item_id.plaid_item_id);
        
        // const responsedata = response.body;
        // // const responsedata = await response.text();
        // debugger;
        // console.log('responsedata', responsedata)
        // const jsonResponsedata = JSON.parse(responsedata);
        const userConfig = response.body;
        const transHist = userConfig["12_month_transaction_history"]
        // const transHist = userConfig["12_month_transaction_history"].map((eachExpense: TransactionHistory) => {
        //   let newExpense = 0;
        //   if(eachExpense.expense < 0) {newExpense =  eachExpense.expense * -1}
        //   return {
        //     expense: newExpense,
        //     income: eachExpense.income,
        //     month_year: eachExpense.month_year
        //   };
        // });
        
        const paycheck_deposits_array = userConfig['income_expense'].map((eachIncome: IncomeExpense) => {
          return eachIncome?.paycheck?.category_summary.level_1_group_details
        });
        // const other_deposits_array = userConfig['income_expense'].map((eachIncome: IncomeExpense) => {
        //   return eachIncome..other_deposits?.category_summary.level_1_group_details
        // });
        const expense_array = userConfig['income_expense'].map((eachIncome: IncomeExpense) => {
          return eachIncome.expenses?.category_summary.level_1_group_details;
        });
        const expense_flattedArray = expense_array.flat();
        console.log('expense_flattedArray', expense_flattedArray);
        console.log('paycheck_deposits_array', paycheck_deposits_array);
        setExpense(expense_flattedArray);
        
        // const other_deposits_flattedArray = other_deposits_array.flat();
        // const sum_of_other_deposits = other_deposits_flattedArray.reduce((acc, obj: any) => {
        //   return acc + obj?.computed_monthly_budget;
        // }, 0);
        const sum_of_expense = expense_flattedArray.reduce((acc, obj: any) => {
          return acc + obj?.computed_monthly_budget;
        }, 0);
        const paycheck_flattedArray = paycheck_deposits_array.flat();
        
        const sum_of_paycheck = paycheck_flattedArray.reduce((acc, obj: any) => {
          return acc + obj?.computed_monthly_budget;
        }, 0)
        console.log('paycheck_flattedArray', paycheck_flattedArray);
        setDeposit(paycheck_flattedArray);
        setaggPaycheck(sum_of_paycheck);
        // setaggDeposit(parseFloat(sum_of_other_deposits.toFixed(2)))
        setaggExpense(parseFloat(sum_of_expense.toFixed(2)));
      // persistentsessionStorage.setItem('userconfig', userConfig);
        setTransactionHistory(transHist)
        // debugger;
        setshowSpinner(false);
        dispatch(updateUserConfig(
          response.body
        ));
      } catch (err: any) {
        logError(err, "");
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <>
        {showSpinner ? (
          <Spinner />
        ) : (
          <>
           <Grid item xs={12} sm={6} md={4}>
            <div className={`${classes.paddingTop}`}>
            <Typography className={classes.title} sx={{marginTop: 1.5, marginBottom: 1.5}} variant="h2">
            Your 6-month history
          </Typography>
              <CustomizedProgressBars route={location.pathname} />
            <Typography component="div" sx={{marginTop: 1.5, marginBottom: 1.5}} variant="body1">
              Please review your deposit and expense trends below. You can expand any category for more details.
            </Typography>
            </div>
            <div className={`${classes.paddingTop} ${classes.backgroundColor}`}>
              <ResponsiveContainer width="99%" aspect={3}>
                <BarChart
                  width={1500}
                  height={300}
                  data={transactionHistory}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month_year"
                    tick={{fontSize: "1rem"}}
                  />
                  <YAxis />
                  <Legend />
                  <Bar dataKey="income" fill={customColors.graphColor.income} />
                  <Bar dataKey="expense" fill={customColors.graphColor.spend} />
                </BarChart>
              </ResponsiveContainer>
            </div>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              justifyItems="center"
              sx={{ marginTop: "5rem" }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Deposits
                </Typography>
                <Card sx={{ minWidth: 275, marginBottom: 1.25, boxShadow: 0, borderRadius: 0}}>
                  <CardContent
                    sx={{
                      background: "#F9F7F3",
                      alignItems: "left"
                    }}
                  >
                    <Typography variant="body1">
                      Over the last 12 months, you averaged ${(aggPaycheck).toFixed(2)}/month in total
                      deposits.
                    </Typography>
                  </CardContent>
                </Card>
                {deposit.map((eachDeposit, index) => {
                  return (
                    <div key={index}>
                      <Card
                        sx={{ minWidth: 275, marginBottom: 1.25, boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1'}}
                        onClick={() => {
                          getMainLevelDetails(eachDeposit, "inflow");
                        }}
                      >
                        <CardContent>
                          <section className={styles.container}>
                            <div className={classes.cateogryIcon}>
                              <Typography variant="button">
                                <Icon
                                  iconName={eachDeposit["level_1_group"] || ""}
                                  color="green"
                                />
                              </Typography>
                            </div>
                            <div className={styles.typography}>
                              <Typography variant="h4">
                                {eachDeposit["level_1_group"]}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                Avg.$
                                {(eachDeposit["computed_monthly_budget"]|| 0).toFixed(2)}
                                /month
                              </Typography>
                            </div>
                          </section>
                          <ArrowForwardIosIcon className={classes.icon} />
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                >
                  Expenses
                </Typography>
                <Card sx={{ minWidth: 275, marginBottom: 1.25, boxShadow: 0, borderRadius: 0}}>
                  <CardContent
                    sx={{
                      background: "#F9F7F3",
                      alignItems: "left"
                    }}
                  >
                    <Typography variant="body1">
                      Over the last 12 months, you averaged ${aggExpense}/month in total
                      deposits.
                    </Typography>
                  </CardContent>
                </Card>
                {expense.map((eachExpense, index) => {
                  return (
                    <div key={index}>
                      <Card
                        sx={{ minWidth: 275, marginBottom: 1.25, boxShadow: 0, borderRadius: 0, border: 1, borderColor: '#DDD8D1'}}
                        onClick={() => {
                          getMainLevelDetails(eachExpense, "outflow");
                        }}
                      >
                        <CardContent>
                          <section className={styles.container}>
                            <div className={classes.cateogryIcon}>
                              <Typography variant="button">
                                <Icon
                                  iconName={eachExpense["level_1_group"] || ""}
                                  color={customColors.base.brightBlue}
                                />
                              </Typography>
                            </div>
                            <div className={styles.typography}>
                              <Typography variant="h4">
                                {eachExpense["level_1_group"]}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                Avg.$
                                {(eachExpense["computed_monthly_budget"]|| 0).toFixed(2)}
                                /month
                              </Typography>
                            </div>
                          </section>
                          <ArrowForwardIosIcon className={classes.icon} />
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                sx={{
                  backgroundColor: "#007AFF",
                  borderRadius: "100px",
                  width: "300px",
                  height: "62px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
                size="large"
                variant="contained"
                onClick={(e) => {
                  // openModal(e);
                  navigate(`/settingupbudget`);
                }}
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default ReviewBudget;
