interface Editbudget {
    lvl1: string | null;
    lvl2: string;
    stream_type: string;
    budget: number;
  }
  const SetEditBudget: string = "seteditbudget";
  
  export interface EditBudgetAction {
    type: String;
    payload: {
        lvl1: string | null;
        lvl2: string;
        stream_type: string;
        budget: number;
    };
  }
  export default Editbudget;
  
  export { SetEditBudget };
  