import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

// import * as response from "./output_recurr_csv.json";
import { logError } from "../ErrorHandler/logErrorService";
import { Alert, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux-functionality";
import InitialState from "../../types/redux/user";
import { setUserEmailAndAuth } from "../../redux-functionality/slices/userSlice";
import { getAccountDetails } from "../../backend/transaction";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingLeft: "50px",
    paddingBottom: "20px"
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  secondCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  cateogryIcon: {
  },
  
}));


const Agreement = () => {
const classes = useStyles();
const location = useLocation();
const navigate = useNavigate();
const dispatch = useDispatch();
const [checked, setChecked] = useState(false);
const [error, setError] = useState(false);
const userprofile: InitialState = useSelector(
  (state: RootState) => state.user
);

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('event.target.checked', event.target.checked)
  setChecked(event.target.checked);
  if(event.target.checked) {
    console.log('Agreed to user agreement!!');
  } else {
    console.log('Not Agreed to user agreement!!');
  }
};
const buttonCLick =() => {
    console.log('checked', checked)
    console.log('userprofile', userprofile);
    if(checked) {
        setError(false);
        dispatch(setUserEmailAndAuth(Object.assign({}, userprofile, {terms_conditions_accepted: true})));
        getAccountDetails(userprofile.email).then((response) => {
          console.log('Navigating from Account', response);
          if(response.length  > 0) {
              navigate("/accountsummary")
          } else {
          navigate("/gettingstarted");
          }
      }).catch((err) => {
          console.error(`Error in authenticate ${err}`);
          navigate("/error");
      });
    }else {
        setError(true)
    }
}
  useEffect(() => {
    
    async function fetchData() {
      try {

      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: "100%",
          marginTop: "10rem",
          minWidth: 275,
          boxShadow: 0,
          borderStyle: 0,
          borderRadius: 0,
        }}
      >
        <CardContent className={classes.centerCardContent}>
          <Typography component="div" variant="h3">
            Beta user agreement
          </Typography>
        </CardContent>
        <CardContent>
          <Typography component="div" variant="body1">
            This Beta Agreement (“Agreement) is made by and between (“you” or
            “your”) and Progressive Innovation Co. (hereafter “Company”, “we”,
            “us”, or “our”).{" "}
          </Typography>
          <Typography sx={{ marginTop: 2 }} component="div" variant="body1">
            WHEREAS, we desire to have you participate as a member of our Beta
            Testing Group (“User Group”) as it relates to our Aver Money product
            offering and help us identify user needs, potential issues, and
            opportunities for improvement; and{" "}
          </Typography>
          <Typography sx={{ marginTop: 2 }} component="div" variant="body1">
            WHEREAS, you desire to participate in our User Group.{" "}
          </Typography>
          <Typography sx={{ marginTop: 2 }} component="div" variant="body1">
            {" "}
            NOW THEREFORE, the parties agree as follows:
          </Typography>
          <Typography sx={{ marginTop: 2 }} component="div" variant="body1">
            {" "}
            1. Scope of Engagement. This User Group is designed to provide
            meaningful insight, feedback, and ideas for Aver Money product
            development, including, without limitation, (a) reviewing and
            providing feedback on general product characteristics, enhancements,
            and improvements; (b) interacting with artifacts and prototypes; (c)
            providing open and honest feedback on the Aver Money product
            offering (collectively, the “Services”).
          </Typography>
          <Typography sx={{ marginTop: 2 }} component="div" variant="body1">
            {" "}
            As a participant in this User Group, you may be asked to link your
            checking, savings and credit card accounts through Plaid, Inc. to
            feed transaction data to PIC. Any link with Plaid, Inc. shall remain
            open until you revoke our access token by unlinking your account.
            You further agree to reasonably cooperate with PIC or its Affiliates
            as it regards...
          </Typography>
        </CardContent>
        <Divider sx={{ borderBottomWidth: 2 }} />
       
        <FormGroup>
        {error ? <Alert sx={{backgroundColor: "rgb(253, 237, 237)", color: "rgb(95, 33, 32)"}} severity="error">Please agree to user agreement by selecting the checkbox.</Alert> : <></>}
      <FormControlLabel control={
        <Checkbox
          checked={checked}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }}}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />} label="I have read and accept the Beta Agreement." />
        </FormGroup>
        <div className={classes.buttonContainer}>
        <Button
              sx={{
                backgroundColor: "#007AFF",
                borderRadius: "100px",
                width: "300px",
                height: "62px",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
              size="large"
              variant="contained"
              onClick={
                // openModal(e);
                buttonCLick
              }
            >
              Continue
            </Button>
            </div>
      </Card>
    </div>
  );
};

export default Agreement;

