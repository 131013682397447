import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Hotjar from "@hotjar/browser";

import Select, { SelectChangeEvent } from "@mui/material/Select";

// import * as dayjs, {Dayjs } from "dayjs"
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch } from "react-redux";
import { setUserEmailAndAuth } from "../../redux-functionality/slices/userSlice";
import { setUserDefinedData } from "../../redux-functionality/slices/userDataSlice";
import { persistentlocalStorage } from "../../PersistStorage/persistlocalstorage";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";

import userData from "../../userConfig.json";
import { updateUserConfig } from "../../redux-functionality/slices/userConfigSlice";

const SelectUser = () => {
  const dispatch = useDispatch();

  const [user, setUser] = useState("");
  const [allUser, setallUser] = useState([]);
  const [account, setAccount] = useState([""]);
  const [sdamount, setsdamount] = useState<number | null>(6420.45);
  const [asOfAmount, setasOfAmount] = useState<number | null>(8231.65);
  const [sdDate, setsdDate] = useState<Date>(new Date("02/01/2022"));
  const [aoDate, setaoDate] = useState<Date>(new Date("02/28/2022"));
  const [displayAccountId] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [label] = useState("Enter the starting amount");
  let navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    if (event) {
      setUser(event.target.value as string);
      dispatch(
        setUserEmailAndAuth({
          email: event.target.value,
          isAuthenticated: true,
        })
      );

      if (allUser) {
        const selectedUser: any = allUser.filter((user: any) => {
          return user.username === event.target.value;
        });
        setAccount(selectedUser.account_id);
      }
    }
  };

  const continueButton = () => {
    const tempaoDate = aoDate["$d"] || aoDate;
    const tempsdDate = sdDate["$d"] || sdDate;
    dispatch(
      setUserDefinedData({
        ao_day: tempaoDate?.getDate(),
        ao_month: tempaoDate.getMonth() + 1,
        ao_year: tempaoDate.getFullYear(),
        as_of_amount: asOfAmount,
        sd_day: tempsdDate?.getDate(),
        sd_month: tempsdDate?.getMonth() + 1,
        sd_year: tempsdDate?.getFullYear(),
        starting_amount: sdamount,
      })
    );
    Hotjar.identify(user, {
      first_name: user,
      color: "blue",
    });
    if (checked) {
      navigate(`/burndown`);
    } else {
      // setStartDate(event);
      navigate(`/reviewbudget`);
    }
  };

  const onBlurSetAmount = (event: any) => {
    // console.log('OnBlur', event.target.value)
    setsdamount(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onBlurAsOfAmount = (event: any) => {
    setasOfAmount(event.target.value);
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    async function fetchData() {
      dispatch(updateUserConfig(
        userData
      ));
      persistentlocalStorage.clearAll();
      console.log("GETTING USERS!!!!");
      // const response = await getUsers();
      // setallUser(response);
    }
    fetchData();
  }, []);

  return (
    <div className={styles.grid}>
      <div>
        <h3 className={styles.title}>
          Select the user from the following list
        </h3>
        <Box sx={{ minWidth: 120 }}>
          {/* <FormControl fullWidth> */}
          <InputLabel className={styles.fontsize} id="demo-simple-select-label">
            Select a user
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={user}
            label="select a user"
            onChange={handleChange}
            className={styles.boxminWidth}
          >
            {allUser.map((eachUser: any, index) => {
              return (
                <MenuItem key={index} value={eachUser.username}>
                  {eachUser.username}
                </MenuItem>
              );
            })}
          </Select>
          {/* </FormControl> */}
          <div className={styles.paddingTop}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="As of date"
                value={aoDate}
                onChange={(newValue: any) => {
                  setaoDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className={styles.paddingText}>
            <CurrencyTextField
              label="Enter the as of amount"
              variant="standard"
              value={asOfAmount}
              digitGroupSeparator=""
              currencySymbol="$"
              outputFormat="number"
              onBlur={(event: any) => onBlurAsOfAmount(event)}
            />
          </div>
          <div className={styles.paddingTop}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Starting date"
                value={sdDate}
                onChange={(newValue: any) => {
                  setsdDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className={styles.paddingText}>
            <CurrencyTextField
              label={label}
              value={sdamount}
              currencySymbol="$"
              digitGroupSeparator=""
              outputFormat="number"
              onBlur={(event: any) => onBlurSetAmount(event)}
            />
          </div>
        </Box>
        <div>
          {displayAccountId ? (
            <div>
              getting {user} data till {aoDate}
              {account?.map((eachAccount: any, index) => {
                return (
                  <div key={index}>
                    <Typography variant="h5" component="div">
                      {eachAccount}
                    </Typography>
                  </div>
                );
              })}
            </div>
          ) : (
            <span></span>
          )}
        </div>
        <div className={styles.paddingTextCheckbox}>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleCheckbox} />}
            label={
              <p className={styles.paddingTextCheckbox}>
                Check to run the burndown&nbsp;
                <Link
                  onClick={(e) => {
                    setChecked(true);
                    setOpen(true);
                  }}
                >
                  Click here for terms and conditions
                </Link>
              </p>
            }
          />
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Terms and Conditions"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By checking the checkbox you agree to our terms and condition.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.paddingTop}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              continueButton();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectUser;
