import { UserDefinedBudget } from "./userConfig";


interface initialGroupLeve1DetailsState {
  "level_1_group": string;
  "level_2_group": string;
  "transaction_merchant_name": string;
  "computed_monthly_budget": number;
  "computed_monthly_spend": number;
  "user_defined_values": Array<UserDefinedBudget>;
  "modified_by_user": boolean;
  "grouping_value": string;
  "frequency": string;
  "flow": string;
}

const UpdateGroupLevel1Details: string = "updateGrouplevel1Details";

export interface GroupLevel1Action {
  type: String;
  payload: {
    "level_1_group": string;
    "level_2_group": string;
    "transaction_merchant_name": string;
    "computed_monthly_budget": number;
    "computed_monthly_spend": number;
    "user_defined_values": Array<UserDefinedBudget>;
    "modified_by_user": boolean;
    "grouping_value": string;
    "frequency": string;
    "flow": string;
  };
}
export default initialGroupLeve1DetailsState;

export { UpdateGroupLevel1Details };
