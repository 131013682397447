import { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

// import * as response from "./output_recurr_csv.json";
import { logError } from "../ErrorHandler/logErrorService";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { CreditCard, Edit } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedProgressBars from "../CustomerProgress";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "100px",
    paddingLeft: "50px",
    paddingBottom: "20px"
  },
  buttonContainer: {
    padding: "24px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center"
  },
    progressBar: {
    marginLeft: '15%',
    marginRight: '15%',
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
      marginLeft: "0",
    },
  },
  centerCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  secondCardContent: {
    display: "flex",
    justifyContent: "center",
  },
  cateogryIcon: {
    // width: '8%',    
    // float: 'left',
    // [theme.breakpoints.down("md")]: {
    //   marginRight: "10px",
    // },
  },
  
}));


const ViewYourForecast = () => {
const location = useLocation();
const classes = useStyles();
const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
      } catch (err: any) {
        logError(err, "");
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: "100%",
          marginTop: "10rem",
          minWidth: 275,
          boxShadow: 0,
          borderStyle: 0,
          borderRadius: 0,
        }}
      >
        <CardContent className={classes.centerCardContent}>
          <Typography sx={{ marginBottom: 2 }} component="div" variant="h2">
            View your forecast
          </Typography>
        </CardContent>
        <CardContent className={classes.progressBar}>
          <CustomizedProgressBars route={location.pathname} />
        </CardContent>
      </Card>
      <div className={classes.secondCardContent}>
        <Card
          sx={{ minWidth: 275, boxShadow: 0, borderStyle: 0, borderRadius: 0 }}
        >
          <CardContent
            sx={{
              // padding: "50px",
              // paddingTop: "20px",
              paddingBottom: "20px",
              alignItems: "left",
            }}
          >
<Typography variant="body1" sx={{ marginBottom: 2, marginTop: -1 }}>
              Your cashflow forecast depicts how your account balance might look
              over a specified time period based on your plan and your regular
              spending patterns.
            </Typography>
            <Typography variant="h3" sx={{ marginBottom: 4, marginTop: 1 }}>
              What to know:
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <Edit color="grey" style={{ marginRight: "10px" }} />
              Editing your plan will impact your cashflow forecast.
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              <CreditCard color="grey" style={{ marginRight: "10px" }} />
              Spending more or less than your plan will also impact your
              forecast.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          sx={{
            backgroundColor: "#007AFF",
            borderRadius: "100px",
            width: "300px",
            height: "62px",
            fontSize: "14px",
            fontFamily: "Poppins",
          }}
          size="large"
          variant="contained"
          onClick={(e) => {
            // openModal(e);
            navigate(`/cashflow`);
          }}
        >
          View your cashflow
        </Button>
      </div>
    </div>
  );
};

export default ViewYourForecast;
