import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


const AppFooter = () => {
  let navigate = useNavigate();
  return (
    <div style={{ position: "relative" }}>
      <Box
        sx={{
          minHeight: "100px",
          backgroundColor: "#F9F7F3",
          color: "white",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <div>
          <Typography
            component="div"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "22.15px",
              color: "#007AFF",
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
            }}
          >
            Aver Money
          </Typography>
          <Typography
            component="div"
            variant="body1"
            sx={{
              color: "black",
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: "24px",
            }}
          >
            Aver Money aims to provide its users with insights about their
            finances and makes no representation as to the accuracy or
            completeness. The insights and predictions provided are based, in
            part, and dependent upon the accuracy of the information you
            provide, and no specific outcome is guaranteed. The information
            provided should not be considered financial, tax, estate planning,
            or legal advice and Aver is not a certified financial planner or
            investment advisor. This service is not intended to engage in
            solicitation or recommendation for the purchase or sale of any
            security or investment strategy. You should consult a professional
            advisor to address your particular circumstances. Aver Money is a
            beta product and may therefore contain defects. A primary purpose of
            this agreement is to obtain feedback on software performance and the
            identification of defects. You are not to rely in any way on the
            correct functioning or performance of the product.
          </Typography>
        </div>
        <div style={{ position: "absolute", top: "0px", right: "0px", padding: '24px' }}>
          <Button
            type="submit"
            color="primary"
            sx={{ borderRadius: 28, backgroundColor: "#F9F7F3", color: "black"}}
            onClick={() => {
              navigate(`/help`);
            }}
          >
            <Typography variant="h5" >
              Get Help
            </Typography>
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default AppFooter;