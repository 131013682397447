export interface TransactionHistory {
    income: number;
    expense: number;
    month_year: String;
}

export interface MonthlyAverage {
    "monthly_avg_amount": number;
    "month_year": string;
}

export interface UserDefinedBudget {
    user_defined_budget: number;
    timestamp: string;
}

export interface GroupLevel1Budget {
    "level_1_group": string | null;
    "computed_monthly_budget": number | null;
    "computed_monthly_spend": number | null;
    "user_defined_values": Array<UserDefinedBudget> | null;
}

export interface TransactionBreakdown {
    "transaction_merchant_name": string; 
    "frequency"?: string | null;
    "level_1_group": string | null;
    "level_2_group": string;
    "transaction_category": string;
    "transaction_date": string;
    "transaction_amount": number;
    "transaction_id": string;
}

export interface Income_ExpenseDetails {
    "level_1_group": string | null;
    "level_2_group": string | null;
    "transaction_merchant_name": string;
    "computed_monthly_budget": number | null;
    "computed_monthly_spend": number | null;
    "user_defined_values": Array<UserDefinedBudget> | null;
    "modified_by_user": boolean;
    "grouping_value": string | null;
    "frequency": string | any;
    "flow"? : string| any;
}

export interface Prediction {
    "month_year": string;
    "user_defined_amount": number;
    "predicted_amount": number;
}

/*
Level1GroupDetails
    level_1_group : Name of the Level 1 group
    Computed monthly_budget: Can either be 
        a) Ouptut of the recurrence script if grouping_value is "level_1_group" else
        b) Would be a sum of multiplication  of frequency based i.e. daily *30, weekly*4 ,bi_weekly*2,monthly*1 and computed_level_1_group_budget
    computed monthly_spend:  Sum of all the transactions matching level_1_group 
    user_defined_values: values set by user 
    frequency: Should be monthly if returned otherwise by the recurrence script 
    grouping_value: Returned from recurrence script
    computed_level_1_group_budget: Returned from the recurrence script if grouping_value === 'level_1_group'
    level_1_transactions_breakdown: Details of transactions matching level_1_group
    level_2_group_details: Array of all the level_2_group transaction under matching level_1_group.  
 */
export interface Level1GroupDetails {
    "level_1_group": string | null;
    "computed_monthly_budget": number | null;
    "computed_monthly_spend": number | null;
    "6_month_summary_graph"?: Array<MonthlyAverage>;
    "6_month_average_graph"?: number;
    "computed_level_1_group_budget"?: number | null;
    "user_defined_values": Array<UserDefinedBudget> | null;
    "modified_by_user": boolean;
    "frequency"?: string | null;
    "grouping_value"?: string | null;
    "level_2_group_details": Array<Level2GroupDetails> | any;
    "level_1_transactions_breakdown": Array<TransactionBreakdown> | any;
}
/*
Level2GroupDetails
    level_2_group : Name of the Level 2 group
    Computed monthly_budget: Can either be 
        a) Ouptut of the recurrence script if grouping_value is "level_2_group" else
        b) Would be a sum of multiplication  of frequency based i.e. daily *30, weekly*4 ,bi_weekly*2,monthly*1 
    computed_group_level2_budget: Returned from the recurrence script if grouping_value === 'level_2_group' and computed_group_level_2_budget
    frequency: Should be monthly if returned otherwise by the recurrence script 
    grouping_value: Returned from recurrence script
    level_2_group_merchant_details: Array of all the merchant_name transaction under matching level_2_group
*/
export interface Level2GroupDetails {
    "level_2_group": string;
    "computed_monthly_budget": number;
    "computed_group_level_2_budget"?: number;
    "frequency": string | null;
    "grouping_value": string | null;
    "level_2_group_merchant_details": Array<HighLevelMerchantDetails>;
}
/*
HighLevelMerchantDetailsHighLevelMerchantDetails
    transaction_merchant_name: Returned from recurrence script
    computed_monthly_budget: Returned from recurrence script
    frequency: Returned from recurrence script
    grouping_value: Returned from recurrence script
*/
export interface HighLevelMerchantDetails {
    "transaction_merchant_name": string;
    "computed_monthly_budget"?: number;
    "computed_budget"?: number;
    "frequency": string | null;
    "grouping_value": string | null;
}


export interface CashFlow {
    "time_stamp": string;
    "left_over_balance": number;
    "30_days_cashflow_predictions": Array<Prediction>
}

export interface CategoryDetails {
    "level_1_group_details": Array<Level1GroupDetails>;
}

export interface IncomeExpense {
  timestamp: string;
  paycheck?: {
    category_summary: CategoryDetails;
  };
  expenses: {
    category_summary: CategoryDetails;
  };
  other_transactions?: Array<{
    level_1_group: string;
    level_2_group: string;
    computed_monthly_budget: number | null;
    computed_monthly_spend: number | null;
    user_defined_values?: Array<UserDefinedBudget>;
    modified_by_user: boolean;
    expenses_breakdown: Array<TransactionBreakdown>;
  }>;
  cashflow?: Array<CashFlow>;
  recurrence_output?: Array<any>;
}

export interface UserConfig {
    "12_month_transaction_history"?: Array<TransactionHistory>;
    "income_expense"?: Array<IncomeExpense>;
    "recurrence_output"?: any
}

const UpdateUserConfig: string = "updateUserConfig";

export interface UserConfigAction {
    type: String;
    payload : UserConfig
}

export default UserConfig;

export { UpdateUserConfig };