import { useContext, useEffect, useReducer, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import UserPool from "../../UserPool";


import Swal from "sweetalert2";
import { AccountContext } from "../Account";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";

const Forgot = () => {
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [secondPassword, setConfirmPassword] = useState("");
    const [verificationCode, setverificationCode] = useState("");
    const [codeConfirmation, setcodeConfirmation] = useState(false);
    const [allFieldsRequiredError, setAllFieldsRequiredError] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [match, setMatch] = useState(true);
    const [passwordError, setPasswordError] = useState(false);
    const [awsError, setawsError] = useState('')
    const [spinner, setSpinner] = useState(false);

    const { forgotPassword, resetPassword } = useContext(AccountContext);
    const theme = createTheme();

    const linkStyle = {
        backgroundColor: "white",
        color: "#3498db",
        padding: "0rem",
        width: "auto",
        fontSize: "14px"
    }

    const hasBlankFields = (formData) => {
        if (formData.get('email') === '' || formData.get('password') === '' || formData.get('comfirm-password') === '' || formData.get('verificationcode') === '') {
            setAllFieldsRequiredError(true);
            forceUpdate();
            return true;
        } else {
            setAllFieldsRequiredError(false);
            forceUpdate();
            return false;
        }
    }

    const resendLink = () => {
        setPasswordError(false);
        setMatch(true);
        setawsError('');
        setcodeConfirmation(false);
        setConfirmPassword('');
        setverificationCode('');
        setPassword('');
    }
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        setSpinner(true);
        
        if (!codeConfirmation) {
            try{
            if (formData.get('email') === '') {
                setAllFieldsRequiredError(true);
                setSpinner(false);
            } else {
                setAllFieldsRequiredError(false);
                forgotPassword(formData.get('email')).then((data: any) => {
                        Swal.fire(`If the email is registered. You will receive a verification code to ${formData.get('email')}. Please check the spam folder as well!`).then(() => {
                            console.log('Sending.... ');
                        }).catch((err: Error) => {
                            console.error('Error in Swal.... ', err);
                        });
                    setcodeConfirmation(true);
                    setSpinner(false);
                    console.log('Sending.... ', data);
                }).catch((err: any) => {
                    setcodeConfirmation(true);
                    setSpinner(false);
                    setawsError(err.Message);
                    forceUpdate();
                    Swal.fire(`Error! ${err}. Please try again`).then(() => {
                        console.log('Sending.... ');
                    }).catch((err: Error) => {
                        console.error('Error in Swal.... ', err);
                    });
                });
                console.log('Sending ');
            }
        } catch(e) {
                console.log(e);
        }

        } else {
            hasBlankFields(formData);
            setcodeConfirmation(true);
            callToAws(formData);
            forceUpdate();
            setSpinner(false);
            console.log(`allFieldsRequiredError ${allFieldsRequiredError}`);
        }
    };

    const callToAws = (formData) => {
        // hasBlankFields();
        forceUpdate();
        console.log('SEND TO AWS', hasBlankFields(formData));
        if (!hasBlankFields(formData)) {
            resetPassword(formData.get('email'), formData.get('verificationcode'), formData.get('password')).then((data: any) => {
                console.log(`Successful data ${data}`);
                navigate(`/login`);
                setSpinner(false);
            }).catch((err: Error) => {
                setawsError(err.message);
                setSpinner(false);
                console.log(`Error in resetPassword: ${err}`)
            })
        } else {
            setSpinner(false);
        }
    }

    useEffect(() => {
    }, [codeConfirmation, setAllFieldsRequiredError, forceUpdate, allFieldsRequiredError])
    return (
      <>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            {/* <CssBaseline /> */}
            <Box
              sx={{
                marginTop: 13,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontFamily: "Poppins", marginTop: 2 }}
                component="div"
                variant="h4"
              >
                Forgot Password
              </Typography>
              <Box
                component="form"
                onSubmit={onSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {allFieldsRequiredError && (
                  <div className="alert alert-danger" role="alert">
                    {" "}
                    All Fields are required
                  </div>
                )}
                {!match && (
                  <div className="alert alert-danger" role="alert">
                    Password and confirm password must match
                  </div>
                )}
                {passwordError && (
                  <div className="alert alert-danger" role="alert">
                    Password cannot be blank.
                    <ul>
                      <li>Password length must be atleast 8 characters</li>
                      <li>
                        Password must contain atleast one special characters,
                        one uppercase letter and one lowercase letter
                      </li>
                    </ul>
                  </div>
                )}
                {awsError?.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    {awsError}
                  </div>
                )}
                {/* {!validEmail && (
                  <Alert severity="error">
                    Please enter a valid email address
                  </Alert>
                )}
                {!validpassword && (
                  <Alert severity="error">
                    <ul>
                      <li>Password length must be atleast 8 characters</li>
                      <li>
                        Password must contain atleast one special characters, one
                        uppercase letter and one lowercase letter
                      </li>
                    </ul>
                  </Alert>
                )} */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={
                    codeConfirmation ? "Email" : "Enter email to receive a code"
                  }
                  name="email"
                  autoComplete="email"
                  autoFocus
                  variant="filled"
                  FormHelperTextProps={{
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "1.2rem",
                      color: "red",
                    },
                  }}
                  inputProps={{
                    style: { fontFamily: "Poppins", fontSize: 14 },
                  }} // font size of input text
                  InputLabelProps={{
                    style: { fontFamily: "Poppins", fontSize: 14 },
                  }} // font size of input label
                  // helperText={usernameError ? "Please enter a valid username" : ""}
                />
                {codeConfirmation && (
                <Link
                  sx={{ fontFamily: "Poppins", fontSize: 14 }}
                  component={RouterLink}
                  to="/forgot"
                  onClick={() => {resendLink()}}
                  variant="body2"
                >
                  Resend Link
                </Link>
                )}
                {codeConfirmation && (
                  <>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="verificationcode"
                      label="Verification code"
                      type="text"
                      id="verificationcode"
                      variant="filled"
                      autoComplete="verificationcode"
                      FormHelperTextProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "1.2rem",
                          color: "red",
                        },
                      }}
                      inputProps={{
                        style: { fontFamily: "Poppins", fontSize: 14 },
                      }} // font size of input text
                      InputLabelProps={{
                        style: { fontFamily: "Poppins", fontSize: 14 },
                      }} // font size of input label
                      helperText={
                        passwordError ? "Please enter a valid password" : ""
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      variant="filled"
                      autoComplete="current-password"
                      FormHelperTextProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "1.2rem",
                          color: "red",
                        },
                      }}
                      inputProps={{
                        style: { fontFamily: "Poppins", fontSize: 14 },
                      }} // font size of input text
                      InputLabelProps={{
                        style: { fontFamily: "Poppins", fontSize: 14 },
                      }} // font size of input label
                      helperText={
                        passwordError ? "Please enter a valid password" : ""
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="comfirm-password"
                      label="Confrim Password"
                      type="password"
                      id="confirm-password"
                      variant="filled"
                      autoComplete="comfirm-passwor"
                      FormHelperTextProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "1.2rem",
                          color: "red",
                        },
                      }}
                      inputProps={{
                        style: { fontFamily: "Poppins", fontSize: 14 },
                      }} // font size of input text
                      InputLabelProps={{
                        style: { fontFamily: "Poppins", fontSize: 14 },
                      }} // font size of input label
                      helperText={
                        passwordError ? "Please enter a valid password" : ""
                      }
                    />
                  </>
                )}
                <Button
                  sx={{
                    backgroundColor: "#007AFF",
                    borderRadius: "100px",
                    // width: "300px",
                    height: "62px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    marginTop: 2,
                  }}
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  {codeConfirmation ? "Submit" : "Send Code"}
                </Button>

                <Grid sx={{ marginTop: 3 }} container>
                  <Grid item xs>
                    <Link
                      sx={{ fontFamily: "Poppins", fontSize: 14 }}
                      component={RouterLink}
                      to="/login"
                      variant="body2"
                    >
                      Return to login
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      sx={{ fontFamily: "Poppins", fontSize: 14 }}
                      component={RouterLink}
                      to="/register"
                      variant="body2"
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </>
    );
};

export default Forgot;